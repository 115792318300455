import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import * as React from "react";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";

import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { TokenGroupText } from "../../types/tokenGroup";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (
    tokenText: string,
    isExactMatchOnly: boolean,
    isIgnoreWhiteSpace: boolean,
    label: string
  ) => void;
  tokenTextValidatorWithMessage?: (inputValue: string) => string | undefined;
  defaultValue?: TokenGroupText;
  hideLabel?: boolean;
}

const TokenGroupTextModal = React.memo((props: Props) => {
  const {
    defaultValue,
    onCancel,
    tokenTextValidatorWithMessage,
    onSubmit,
    isOpen,
    hideLabel = false,
  } = props;

  const isEdit = defaultValue !== undefined;

  const [tokenText, setTokenText] = useState<string>("");
  const [label, setLabel] = useState<string>("");
  const [isExactMatchOnly, setIsExactMatchOnly] = useState<boolean>(false);
  const [isIgnoreWhiteSpace, setIsIgnoreWhiteSpace] = useState<boolean>(false);
  const [tokenTextErrorMessageId, setTokenTextErrorMessageId] = useState<
    string | undefined
  >();
  const { hasPermissionToEditResource } = useTeamPermission();
  const { localized } = useLocale();

  const onTokenTextChange = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      if (value === undefined) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      setTokenTextErrorMessageId(undefined);
      setTokenText(value);
    },
    []
  );

  const onLabelChange = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      if (value === undefined) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      setLabel(value);
    },
    []
  );

  const onExactMatchChange = useCallback(
    (event?: FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean) => {
      if (event === undefined || checked === undefined) {
        return;
      }
      event.stopPropagation();
      setIsExactMatchOnly(checked);
    },
    []
  );

  const onIgnoreWhiteSpaceChange = useCallback(
    (event?: FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean) => {
      if (event === undefined || checked === undefined) {
        return;
      }
      event.stopPropagation();
      setIsIgnoreWhiteSpace(checked);
    },
    []
  );

  useEffect(() => {
    if (defaultValue) {
      setTokenText(defaultValue.value);
      setIsExactMatchOnly(defaultValue.isExactMatchOnly);
      setIsIgnoreWhiteSpace(defaultValue.isIgnoreWhiteSpace);
      setLabel(defaultValue.label || "");
    }
  }, [defaultValue]);

  const _onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      const _tokenText = tokenText.trim();
      const _label = label.trim();
      e.preventDefault();
      e.stopPropagation();

      if (tokenTextValidatorWithMessage) {
        const errorId = tokenTextValidatorWithMessage(_tokenText);
        if (errorId !== undefined) {
          setTokenTextErrorMessageId(errorId);
          return;
        }
      }

      onSubmit(_tokenText, isExactMatchOnly, isIgnoreWhiteSpace, _label);

      return;
    },
    [
      tokenTextValidatorWithMessage,
      tokenText,
      isIgnoreWhiteSpace,
      isExactMatchOnly,
      onSubmit,
      label,
    ]
  );

  const onDismissed = useCallback(() => {
    setTokenTextErrorMessageId(undefined);
    setTokenText("");
    setLabel("");
    setIsExactMatchOnly(false);
    setIsIgnoreWhiteSpace(false);
  }, []);

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
      className: styles["token-group-text-modal"],
    }),
    [onDismissed]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        isEdit ? "edit.text.token.title" : "add.text.token.title"
      ),
    }),
    [localized, isEdit]
  );

  return (
    <Dialog
      minWidth={400}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <form onSubmit={_onSubmit}>
        <TextField
          labelId="label.text_token"
          onChange={onTokenTextChange}
          value={tokenText}
          errorMessage={
            tokenTextErrorMessageId && localized(tokenTextErrorMessageId)
          }
          disabled={!hasPermissionToEditResource}
        />
        {!hideLabel && (
          <TextField
            labelId="label.text_token.label"
            onChange={onLabelChange}
            value={label}
            disabled={!hasPermissionToEditResource}
          />
        )}
        <Checkbox
          className={styles["match-option-checkbox"]}
          checked={isExactMatchOnly}
          label={localized("label.exact_match")}
          onChange={onExactMatchChange}
          disabled={!hasPermissionToEditResource}
        />
        <Checkbox
          className={styles["match-option-checkbox"]}
          checked={isIgnoreWhiteSpace}
          label={localized("label.remove_whitespaces")}
          onChange={onIgnoreWhiteSpaceChange}
          disabled={!hasPermissionToEditResource}
        />
        <DialogFooter>
          <DefaultButton
            onClick={onCancel}
            text={localized(
              hasPermissionToEditResource ? "common.cancel" : "common.close"
            )}
          />
          {hasPermissionToEditResource && (
            <PrimaryButton
              type="submit"
              text={localized("token.modal.confirm.button")}
            />
          )}
        </DialogFooter>
      </form>
    </Dialog>
  );
});
export default TokenGroupTextModal;
