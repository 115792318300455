@import "../../styles/variables.scss";

.main-content-container {
  min-height: 100%;
  display: flex;
}

.navbar-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
}

.navbar-main {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.extractor-button {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  width: fit-content;
  height: fit-content;
  flex: 0 1;
  margin-left: 20px;

  border-radius: 4px;
  border: 1px solid #e7e7e7;
}

.extractor-button-label {
  color: #27ae60;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

.extractor-button-name {
  color: var(--type-colors-secondary, #605e5c);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  white-space: pre;
}

.container {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  .navbar-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: top;
    margin: 6px 0px;
    flex-shrink: 0;
  }

  .navbar-bottom {
    position: relative;
    bottom: -2px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    flex-shrink: 0;

    margin-top: -2px;
    height: 44px;
  }

  > hr {
    flex-shrink: 0;
    height: 2px;
    background-color: #edebe9;
    border: 0;
    margin: 0 12px;
  }

  .content {
    flex: 1;
    position: relative;
  }
}

.right-panel-container {
  min-width: 550px;
  border-left: 1px solid #edebe9;
  transition: all 0.3s;
  overflow-x: hidden;
  position: relative;
}

.right-panel-container--hidden {
  display: none;
}

.right-panel {
  display: flex;
  position: absolute;
  top: 0;
  width: 550px;
  bottom: 0;
}
