import { Values } from "@oursky/react-messageformat";
import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { CONFIRM_MODAL_FADE_OUT_DELAY } from "../constants";
import errors, { FOCRError } from "../errors";
import { useAppDispatch } from "../hooks/redux";
import { RootState } from "../redux/types";
import { ConfirmModalType, ConfirmationTask } from "../types/confirmation";

export const ShowConfirmModal = createAction(
  "app/showConfirmModal",
  (task: ConfirmationTask) => ({ payload: { task } })
);

export const HideConfirmModal = createAction(
  "app/hideConfirmModal",
  (cleanUpTimerId: number) => ({ payload: { cleanUpTimerId } })
);

export const CleanUpConfirmModal = createAction("app/cleanUpConfirmModal");

export type ConfirmModalAction =
  | ReturnType<typeof ShowConfirmModal>
  | ReturnType<typeof HideConfirmModal>
  | ReturnType<typeof CleanUpConfirmModal>;

export function useConfirmModalActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();

  const hideConfirmModal = useCallback(() => {
    const { comfirmModalCleanUpTimerId } = getState().confirmModal;

    if (comfirmModalCleanUpTimerId !== undefined) {
      clearTimeout(comfirmModalCleanUpTimerId);
    }

    const cleanUpTimerId = window.setTimeout(() => {
      dispatch(CleanUpConfirmModal());
    }, CONFIRM_MODAL_FADE_OUT_DELAY);

    dispatch(HideConfirmModal(cleanUpTimerId));
  }, [dispatch, getState]);

  const requestUserConfirmation = useCallback(
    (
      options: {
        titleId: string;
        titleValues?: Values;
        messageId: string;
        messageValues?: Values;
        actionId: string;
        type: ConfirmModalType;
      },
      shouldThrow: boolean = true
    ) => {
      const { titleId, titleValues, messageId, actionId, messageValues, type } =
        options;
      return new Promise<boolean>(async (resolve, reject) => {
        dispatch(
          ShowConfirmModal({
            modalType: type,
            titleId,
            titleValues,
            messageId,
            messageValues,
            actionId,
            onCancel: () => {
              hideConfirmModal();
              if (shouldThrow) {
                reject(errors.ConfirmationRejected);
              } else {
                resolve(false);
              }
            },
            onConfirm: async () => {
              hideConfirmModal();
              resolve(true);
            },
          })
        );
      });
    },
    [dispatch, hideConfirmModal]
  );

  const handleConflict = useCallback(
    async <T>(
      fn: () => Promise<T>,
      fnForOverride: () => Promise<T>,
      options: {
        titleId: string;
        messageId: string;
        actionId: string;
        messageValues?: Values;
      }
    ): Promise<T> => {
      const { titleId, messageId, actionId, messageValues } = options;

      return new Promise(async (resolve, reject) => {
        try {
          const result = await fn();
          resolve(result);
        } catch (e) {
          if (e instanceof FOCRError && e === errors.ConflictFound) {
            const { comfirmModalCleanUpTimerId } = getState().confirmModal;
            if (comfirmModalCleanUpTimerId !== undefined) {
              clearTimeout(comfirmModalCleanUpTimerId);
            }

            dispatch(
              ShowConfirmModal({
                modalType: ConfirmModalType.Save,
                titleId,
                messageId,
                actionId,
                messageValues,
                onCancel: () => {
                  hideConfirmModal();
                  reject(e);
                },
                onConfirm: async () => {
                  try {
                    const result = await fnForOverride();
                    resolve(result);
                  } catch (e) {
                    reject(e);
                  } finally {
                    hideConfirmModal();
                  }
                },
              })
            );
          } else {
            reject(e);
          }
        }
      });
    },
    [dispatch, getState, hideConfirmModal]
  );

  return useMemo(
    () => ({
      hideConfirmModal,
      requestUserConfirmation,
      handleConflict,
    }),
    [hideConfirmModal, requestUserConfirmation, handleConflict]
  );
}
