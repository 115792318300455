import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  IDialogContentProps,
  IModalProps,
  IconButton,
  PrimaryButton,
  Text,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import { produce } from "immer";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

import { useLocale } from "../../contexts/locale";
import { MatchMode, TokenGroup } from "../../types/tokenGroup";
import ScrollableModal from "../ScrollableModal";
import ErrorText from "../WrappedMSComponents/ErrorText";
import styles from "./styles.module.scss";

interface NoteProps {
  notes: string[];
}

export const Note: React.FC<NoteProps> = ({ notes }) => {
  const values: { [key in string]: any } = {
    Mc: <span style={{ color: "#6888fa" }}>Mc</span>,
    McDonald: <span style={{ color: "#25d0b1" }}>McDonald</span>,
    MacDonald: <span style={{ color: "#25d0b1" }}>MacDonald</span>,
    fieldNameExample: <b>{'{"Field Name" : "Mc"}'}</b>,
  };

  return (
    <div className={styles["note"]}>
      {notes.map((note, index) => (
        <Text key={index} variant="small">
          <FormattedMessage id={note} values={values} />
        </Text>
      ))}
    </div>
  );
};

interface Props {
  onCancel: () => void;
  onSubmit: (tokenGroup: TokenGroup) => void;
  inputTokenGroup: TokenGroup | undefined;
}

interface MatchModeOptions extends IChoiceGroupOption {
  key: MatchMode;
}

const useTextTokenModalState = (props: Props) => {
  const { localized } = useLocale();

  const { inputTokenGroup, onSubmit } = props;

  const [editingTokenGroup, setEditingTokenGroup] = useState<
    TokenGroup | undefined
  >(inputTokenGroup);

  const [errors, setErrors] = useState<Map<number, string>>(
    new Map<number, string>()
  );

  const validate = useCallback((editingTokenGroup: TokenGroup) => {
    const freq = new Map<string, number>();

    const { texts } = (editingTokenGroup as TokenGroup) || {
      texts: [],
      matchMode: "all",
    };

    texts.forEach(text => {
      const { value } = text;
      if (value === undefined || (value as string).trim().length === 0) return;
      freq.set(value, freq.has(value) ? (freq.get(value) as number) + 1 : 1);
    });

    const draftErrors = new Map<number, string>();

    texts.forEach((text, index) => {
      const { value } = text;
      if ((freq.get(value) as number) > 1) {
        draftErrors.set(index, "error.text_token_modal.duplicated_value");
      } else if (
        value === undefined ||
        value === "" ||
        (value && value.trim() === "")
      ) {
        draftErrors.set(index, "error.text_token_modal.empty_value");
      } else {
        draftErrors.delete(index);
      }
    });
    return draftErrors;
  }, []);

  const { matchMode, tokens, isOpen } = useMemo(() => {
    const { texts, matchMode } = (editingTokenGroup as TokenGroup) || {
      texts: [],
      matchMode: "all",
    };
    const isOpen = editingTokenGroup !== undefined;

    const tokens = texts.map((text, index) => {
      const error = errors.has(index) ? (errors.get(index) as string) : "";
      return { ...text, error };
    });

    return { matchMode, tokens, isOpen };
  }, [editingTokenGroup, errors]);

  const matchModeOptions = useMemo(
    (): MatchModeOptions[] => [
      { key: "all", text: localized("label.match_all") },
      { key: "best", text: localized("label.match_one_only") },
    ],
    [localized]
  );

  const onMatchModeChange = useCallback(
    (
      event?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
      option?: IChoiceGroupOption | undefined
    ) => {
      if (event === undefined || option === undefined) {
        return;
      }
      event.stopPropagation();
      setEditingTokenGroup(
        produce(editingTokenGroup as TokenGroup, (draft: TokenGroup) => {
          draft.matchMode = (option as MatchModeOptions).key;
        })
      );
    },
    [editingTokenGroup, setEditingTokenGroup]
  );

  useEffect(() => {
    if (inputTokenGroup) {
      setEditingTokenGroup({ ...inputTokenGroup });
    } else {
      setEditingTokenGroup(undefined);
    }
  }, [inputTokenGroup]);

  const _onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const draftErrors = validate(editingTokenGroup as TokenGroup);
      setErrors(draftErrors);

      if (draftErrors.size === 0) {
        onSubmit(editingTokenGroup as TokenGroup);
      }
    },
    [editingTokenGroup, onSubmit, validate]
  );

  const onDismissed = useCallback(() => {
    setEditingTokenGroup(undefined);
  }, [setEditingTokenGroup]);

  const modalProps: IModalProps = useMemo(
    () => ({
      isBlocking: true,
      onDismissed,
    }),
    [onDismissed]
  );

  const formProps = useMemo(
    () => ({
      onSubmit: _onSubmit,
    }),
    [_onSubmit]
  );

  const onExactMatchChange = useCallback(
    (index: number) => (_event?: React.FormEvent<any>, value?: boolean) => {
      if (value === undefined) {
        return;
      }
      setEditingTokenGroup(
        produce(editingTokenGroup as TokenGroup, (draft: TokenGroup) => {
          draft.texts[index].isExactMatchOnly = value;
        })
      );
    },
    [editingTokenGroup, setEditingTokenGroup]
  );

  const onIsIgnoreWhiteSpaceChange = useCallback(
    (index: number) => (_event?: React.FormEvent<any>, value?: boolean) => {
      if (value === undefined) {
        return;
      }
      setEditingTokenGroup(
        produce(editingTokenGroup as TokenGroup, (draft: TokenGroup) => {
          draft.texts[index].isIgnoreWhiteSpace = value;
        })
      );
    },
    [editingTokenGroup, setEditingTokenGroup]
  );

  const onTextTokenLabelChange = useCallback(
    (index: number) =>
      (
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        if (newValue === undefined) {
          return;
        }
        setEditingTokenGroup(
          produce(editingTokenGroup as TokenGroup, (draft: TokenGroup) => {
            draft.texts[index].label = newValue;
          })
        );
      },
    [editingTokenGroup, setEditingTokenGroup]
  );

  const onTextTokenValueChange = useCallback(
    (index: number) =>
      (
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        if (newValue === undefined) {
          return;
        }
        setEditingTokenGroup(
          produce(editingTokenGroup as TokenGroup, (draft: TokenGroup) => {
            draft.texts[index].value = newValue;
            const newErrors = validate(draft);
            const draftErrors = new Map<number, string>(newErrors);

            newErrors.forEach((value, key) => {
              if (errors.get(key) === value) return;

              // Prevent to insert new empty value error while typing
              if (value === "error.text_token_modal.empty_value")
                draftErrors.delete(key);
            });
            setErrors(draftErrors);
          })
        );
      },
    [editingTokenGroup, setEditingTokenGroup, errors, validate]
  );

  const onRemoveClick = useCallback(
    (index: number) => () => {
      setEditingTokenGroup(
        produce(editingTokenGroup as TokenGroup, (draft: TokenGroup) => {
          draft.texts.splice(index, 1);
        })
      );
    },
    [setEditingTokenGroup, editingTokenGroup]
  );

  const onAddNewToken = useCallback(() => {
    setEditingTokenGroup(
      produce(editingTokenGroup as TokenGroup, (draft: TokenGroup) => {
        draft.texts.push({
          id: uuid(),
          isExactMatchOnly: true,
          label: "",
          value: "",
          isIgnoreWhiteSpace: false,
        });
      })
    );
  }, [editingTokenGroup, setEditingTokenGroup]);

  return {
    editingTokenGroup,
    setEditingTokenGroup,
    tokens,
    modalProps,
    formProps,
    onDismissed,
    matchMode,
    matchModeOptions,
    onMatchModeChange,
    onRemoveClick,
    onAddNewToken,
    onExactMatchChange,
    onIsIgnoreWhiteSpaceChange,
    onTextTokenLabelChange,
    onTextTokenValueChange,
    isOpen,
  };
};

const TextTokenModal = React.memo((props: Props) => {
  const { onCancel } = props;

  const { localized } = useLocale();

  const {
    tokens,
    modalProps,
    formProps,
    matchMode,
    matchModeOptions,
    onMatchModeChange,
    onRemoveClick,
    onAddNewToken,
    onExactMatchChange,
    onIsIgnoreWhiteSpaceChange,
    onTextTokenLabelChange,
    onTextTokenValueChange,
    isOpen,
  } = useTextTokenModalState(props);

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("text_token.model.title"),
    }),
    [localized]
  );

  return (
    <ScrollableModal
      minWidth={710}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      formProps={formProps}
      dialogContentProps={dialogContentProps}
      className={styles["text-token-modal"]}
      footerItem={
        <DialogFooter>
          <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
          <PrimaryButton type="submit" text={localized("common.ok")} />
        </DialogFooter>
      }
    >
      <React.Fragment>
        <Note
          notes={[
            "text_token.modal.description_1",
            "text_token.modal.description_2",
          ]}
        />

        <ChoiceGroup
          className={styles["match-mode-choice-group"]}
          selectedKey={matchMode}
          options={matchModeOptions}
          label={localized("label.match_mode")}
          onChange={onMatchModeChange}
        />

        <div className={styles["separator"]} />

        <div className={styles["add-tokens-session"]}>
          <Text variant="large" className={styles["add-tokens-title"]}>
            {localized("text_token.modal.add_tokens")}
          </Text>

          <PrimaryButton
            text={localized("text_token.modal.add_token_button")}
            onClick={onAddNewToken}
          />
        </div>

        <div className={styles["separator"]} />

        <table>
          <thead>
            <tr>
              <td>
                <Text>
                  <FormattedMessage id="text_token.modal.header_1" />
                </Text>
              </td>
              <td>
                <Text>
                  <FormattedMessage id="text_token.modal.header_2" />
                </Text>
              </td>
              <td>
                <Text>
                  <FormattedMessage id="text_token.modal.header_3" />
                </Text>
              </td>
              <td>
                <Text>
                  <FormattedMessage id="text_token.modal.header_4" />
                </Text>
              </td>
              <td>
                <Text>
                  <FormattedMessage id="text_token.modal.header_5" />
                </Text>
              </td>
            </tr>
          </thead>
          <tbody>
            {tokens.map((token, i) => (
              <React.Fragment key={i}>
                <tr>
                  <td>
                    <TextField
                      value={token.value}
                      onChange={onTextTokenValueChange(i)}
                      className={styles["text-token-field"]}
                    />
                  </td>
                  <td>
                    <TextField
                      value={token.label}
                      onChange={onTextTokenLabelChange(i)}
                      className={styles["field-value-field"]}
                    />
                  </td>
                  <td>
                    <Checkbox
                      checked={token.isExactMatchOnly}
                      onChange={onExactMatchChange(i)}
                    />
                  </td>
                  <td>
                    <Checkbox
                      checked={token.isIgnoreWhiteSpace}
                      onChange={onIsIgnoreWhiteSpaceChange(i)}
                    />
                  </td>
                  <td>
                    <IconButton
                      styles={{
                        icon: { color: "#201F1E" },
                      }}
                      iconProps={{ iconName: "Delete" }}
                      onClick={onRemoveClick(i)}
                    />
                  </td>
                </tr>
                {token.error !== "" && (
                  <tr>
                    <td colSpan={2} className={styles["error-td"]}>
                      <ErrorText>
                        <FormattedMessage id={token.error} />
                      </ErrorText>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    </ScrollableModal>
  );
});
export default TextTokenModal;
