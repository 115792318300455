import * as React from "react";

import { AppConfig } from "../config";

export function useN8N() {
  const isN8NEnabled = React.useMemo(() => {
    return AppConfig.n8n?.endpoint != null;
  }, []);

  const submitManageFieldSupportRequest = React.useCallback(
    async (
      userId: string,
      userName: string,
      userEmail: string,
      extractorId: string,
      message: string
    ) => {
      if (!isN8NEnabled) {
        return;
      }
      const eventType = "instant_model_schema_support_request";
      const payload = {
        userId,
        userName,
        userEmail,
        extractorId,
        message,
      };
      try {
        await fetch(`${AppConfig.n8n?.endpoint}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            event_type: eventType,
            environment: AppConfig.environment,
            region: AppConfig.region,
            payload,
          }),
        });
      } catch (e) {
        console.error(`n8n:${eventType}: ${e}`);
      }
    },
    [isN8NEnabled]
  );

  return {
    isN8NEnabled,
    submitManageFieldSupportRequest,
  };
}
