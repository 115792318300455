import { FormattedMessage } from "@oursky/react-messageformat";
import React, { memo, useCallback, useMemo, useState } from "react";

import { UserFeatureFlag } from "../../constants";
import { useAppSelector } from "../../hooks/redux";
import { useSharePointIntegrationReturnValues } from "../../hooks/workspace_integration";
import { Workspace } from "../../types/workspace";
import {
  FilePickerSharePointFile,
  WorkspaceIntegrationConfiguration,
  WorkspaceIntegrationOptionType,
  allWorkspaceIntegrationExportOptionType,
  allWorkspaceIntegrationImportOptionType,
  initialWorkspaceIntegrationConfiguration,
} from "../../types/workspaceIntegration";
import { SharePointConfigurationCard } from "../WorkspaceIntegrationConfigurationCard/SharePointConfigurationCard";
import { WebhookConfigurationCard } from "../WorkspaceIntegrationConfigurationCard/WebhookConfigurationCard";
import { WorkspaceIntegrationOption } from "../WorkspaceIntegrationOption";
import styles from "./styles.module.scss";

interface OptionListProps {
  selectedImportOptions: WorkspaceIntegrationOptionType[];
  selectedExportOptions: WorkspaceIntegrationOptionType[];
  onImportOptionClick: (op: WorkspaceIntegrationOptionType) => void;
  onExportOptionClick: (op: WorkspaceIntegrationOptionType) => void;
}

function OptionList(props: OptionListProps) {
  const {
    selectedImportOptions,
    selectedExportOptions,
    onImportOptionClick,
    onExportOptionClick,
  } = props;

  const { isSharePointIntegrationEnabled, isWebhookExportIntegrationEnabled } =
    useAppSelector(state => ({
      isSharePointIntegrationEnabled: state.resourceOwner.isFeatureEnabled()(
        UserFeatureFlag.SharePointIntegration
      ),
      isWebhookExportIntegrationEnabled: state.resourceOwner.isFeatureEnabled()(
        UserFeatureFlag.WebhookExportIntegration
      ),
    }));

  const selectedImportOptionSet = useMemo(() => {
    return new Set(selectedImportOptions);
  }, [selectedImportOptions]);

  const selectedExportOptionSet = useMemo(() => {
    return new Set(selectedExportOptions);
  }, [selectedExportOptions]);

  const enabledOptions = useMemo(() => {
    const options = new Set<WorkspaceIntegrationOptionType>();
    if (isSharePointIntegrationEnabled) {
      options.add("import_sharepoint");
    }
    if (isWebhookExportIntegrationEnabled) {
      options.add("export_webhook");
    }

    return options;
  }, [isSharePointIntegrationEnabled, isWebhookExportIntegrationEnabled]);

  return (
    <div className={styles["right-sidebar"]}>
      <section className={styles["option-list-section"]}>
        <h5 className={styles["heading"]}>
          <FormattedMessage id="workspace.integrations.option_list.import.title" />
        </h5>
        <ol className={styles["option-list"]}>
          {allWorkspaceIntegrationImportOptionType.map(op => (
            <li key={op}>
              <WorkspaceIntegrationOption
                optionType={op}
                isAdded={selectedImportOptionSet.has(op)}
                isCommingSoon={!enabledOptions.has(op)}
                onOptionClick={onImportOptionClick}
              />
            </li>
          ))}
        </ol>
      </section>
      <section className={styles["option-list-section"]}>
        <h5 className={styles["heading"]}>
          <FormattedMessage id="workspace.integrations.option_list.export.title" />
        </h5>
        <ol className={styles["option-list"]}>
          {allWorkspaceIntegrationExportOptionType.map(op => (
            <li key={op}>
              <WorkspaceIntegrationOption
                optionType={op}
                isAdded={selectedExportOptionSet.has(op)}
                isCommingSoon={!enabledOptions.has(op)}
                onOptionClick={onExportOptionClick}
              />
            </li>
          ))}
        </ol>
      </section>
    </div>
  );
}

interface WorkspaceIntegrationSectionProps {
  workspace: Workspace;
  sharePointIntegrationProps: useSharePointIntegrationReturnValues;
}

function WorkspaceIntegrationSectionImpl(
  props: WorkspaceIntegrationSectionProps
) {
  const { workspace, sharePointIntegrationProps } = props;

  const {
    onSharePointSubscriptionSave,
    onSharePointSubscriptionDisconnect,
    onSharePointIntegrationRemove,
    onLoginSharePoint,
    isLoggedInToSharePoint,
    isLoggingInToSharePoint,
    integrationConfigs,
  } = sharePointIntegrationProps;

  // TODO: Set initial state by retrieved workspace object
  const [selectedImportOptions, setSelectedImportOptions] = useState<
    WorkspaceIntegrationConfiguration[]
  >([]);
  const [selectedExportOptions, setSelectedExportOptions] = useState<
    WorkspaceIntegrationConfiguration[]
  >([]);

  const selectedImportOptionTypes = useMemo(() => {
    return selectedImportOptions.map(op => op.optionType);
  }, [selectedImportOptions]);
  const selectedExportOptionTypes = useMemo(() => {
    return selectedExportOptions.map(op => op.optionType);
  }, [selectedExportOptions]);

  const onImportOptionClick = useCallback(
    (selectedOptionType: WorkspaceIntegrationOptionType) => {
      setSelectedImportOptions(prev => {
        if (prev.findIndex(op => op.optionType === selectedOptionType) >= 0) {
          return prev;
        }
        const newOption =
          initialWorkspaceIntegrationConfiguration[selectedOptionType];
        return [...prev, newOption];
      });
    },
    []
  );

  const onExportOptionClick = useCallback(
    (selectedOptionType: WorkspaceIntegrationOptionType) => {
      setSelectedExportOptions(prev => {
        if (prev.findIndex(op => op.optionType === selectedOptionType) >= 0) {
          return prev;
        }
        const newOption =
          initialWorkspaceIntegrationConfiguration[selectedOptionType];
        return [...prev, newOption];
      });
    },
    []
  );

  const onImportConfigurationRemoved = useCallback(
    (_optionToBeRemoved: WorkspaceIntegrationConfiguration) => {
      onSharePointIntegrationRemove();
      // TODO - remove integration from selectedImportOptions and disconnect from sharepoint
    },
    [onSharePointIntegrationRemove]
  );

  const onExportConfigurationRemoved = useCallback(
    (optionToBeRemoved: WorkspaceIntegrationConfiguration) => {
      setSelectedExportOptions(prev =>
        prev.filter(op => op.optionType !== optionToBeRemoved.optionType)
      );
    },
    []
  );

  const [sharePointFolder, setSharePointFolder] =
    useState<FilePickerSharePointFile | null>(null);

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <section className={styles["section"]}>
          <h4 className={styles["section-header"]}>
            <FormattedMessage id="workspace.integrations.option_setting.import.title" />
          </h4>
          {selectedImportOptions.length > 0 ? (
            selectedImportOptions.map(op => {
              switch (op.optionType) {
                case "import_sharepoint":
                  return (
                    <SharePointConfigurationCard
                      key={op.optionType}
                      workspaceId={workspace.id}
                      configuration={integrationConfigs}
                      onConfigurationRemoved={onImportConfigurationRemoved}
                      onSave={onSharePointSubscriptionSave}
                      onDisconnect={onSharePointSubscriptionDisconnect}
                      onLogin={onLoginSharePoint}
                      onFolderSelect={setSharePointFolder}
                      selectedFolder={sharePointFolder}
                      isLoggedIn={isLoggedInToSharePoint}
                      isLoggingIn={isLoggingInToSharePoint}
                    />
                  );
                default:
                  return null;
              }
            })
          ) : (
            <div className={styles["section-empty-state-container"]}>
              <FormattedMessage id="workspace.integrations.option_setting.import.empty_state" />
            </div>
          )}
        </section>
        <section className={styles["section"]}>
          <h4 className={styles["section-header"]}>
            <FormattedMessage id="workspace.integrations.option_setting.export.title" />
          </h4>
          {selectedExportOptions.length > 0 ? (
            selectedExportOptions.map(op => {
              switch (op.optionType) {
                case "export_webhook":
                  return (
                    <WebhookConfigurationCard
                      key={op.optionType}
                      workspaceId={workspace.id}
                      configuration={op}
                      onConfigurationRemoved={onExportConfigurationRemoved}
                    />
                  );

                default:
                  return null;
              }
            })
          ) : (
            <div className={styles["section-empty-state-container"]}>
              <FormattedMessage id="workspace.integrations.option_setting.export.empty_state" />
            </div>
          )}
        </section>
      </div>
      <OptionList
        selectedImportOptions={selectedImportOptionTypes}
        selectedExportOptions={selectedExportOptionTypes}
        onImportOptionClick={onImportOptionClick}
        onExportOptionClick={onExportOptionClick}
      />
    </div>
  );
}

export const WorkspaceIntegrationSection = memo(
  WorkspaceIntegrationSectionImpl
);
