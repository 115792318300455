import {
  IContextualMenuItem,
  ITooltipHostStyles,
  Persona,
  PersonaSize,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { StarFilled } from "@fluentui/react-icons";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import { ConfigSnapshot } from "../../types/configSnapshot";
import { formatDate } from "../../utils/datetime";
import { IconButton } from "../IconButton";
import { dateTimePattern } from "./config";

interface Props {
  item: ConfigSnapshot;
  isSelected: boolean;
  onPressed: (configSnapshot: ConfigSnapshot) => void;
  onDeletePressed: (configSnapshot: ConfigSnapshot) => void;
  onEditInfoPressed: (configSnapshot: ConfigSnapshot) => void;
  onBookmarkPressed: (configSnapshot: ConfigSnapshot) => void;
}
const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block", marginLeft: 8 },
};

const SnapshotVersionItem = React.memo((props: Props) => {
  const {
    item,
    isSelected,
    onDeletePressed,
    onEditInfoPressed,
    onBookmarkPressed,
    onPressed,
  } = props;

  const { localized } = useLocale();

  const { name, note } = item.info;
  const { restoreSnapshot, createdAt, bookmarkedBy } = item;
  const titleDisplay: React.ReactNode = React.useMemo(() => {
    if (name && bookmarkedBy) {
      return (
        <div className="flex flex-row items-center">
          <p className="text-[#323130] font-semibold mr-1 py-1 text-base">
            {name}
          </p>
          <TooltipHost
            content={
              <Text className="text-xs">
                <FormattedMessage
                  id="snapshot_version_list.tooltip.bookmarked_by"
                  values={{
                    bookmarked_by: bookmarkedBy,
                  }}
                />
              </Text>
            }
            calloutProps={calloutProps}
            styles={hostStyles}
          >
            <StarFilled color="#FFD732" />
          </TooltipHost>
        </div>
      );
    }
    return (
      <p className="text-[#323130] font-semibold mr-1 py-1 text-base">
        {formatDate(createdAt, dateTimePattern)}
      </p>
    );
  }, [name, createdAt, bookmarkedBy]);

  const restoreInfo = React.useMemo(() => {
    if (!restoreSnapshot) {
      return null;
    }
    const {
      info: { name },
      createdAt,
    } = restoreSnapshot;

    return (
      <p className="pl-6 mt-2 text-[#605E5C] text-sm font-semibold">
        {localized("snapshot_version_list.item.description.restore_from", {
          restored_from: name ?? formatDate(createdAt, dateTimePattern),
        })}
      </p>
    );
  }, [restoreSnapshot, localized]);
  const timestamp = React.useMemo(() => {
    if (name) {
      return (
        <p className="text-xs leading-5 font-semibold text-[#A19F9D]">
          {formatDate(createdAt, dateTimePattern)}
        </p>
      );
    }
    return null;
  }, [name, createdAt]);

  const menus = React.useMemo(() => {
    let menuItems: IContextualMenuItem[] = [];
    if (item.bookmarkedAt) {
      menuItems = [
        {
          key: "edit-info",
          text: localized("snapshot_version_list.item.action.edit_info"),
        },
      ];
    } else {
      menuItems = [
        {
          key: "bookmark",
          text: localized("snapshot_version_list.item.action.bookmark"),
        },
      ];
    }
    return menuItems.concat({
      key: "delete",
      text: localized("snapshot_version_list.item.action.delete"),
    });
  }, [item, localized]);

  const onMenuItemPressed = React.useCallback(
    (
      _ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
      menuItem?: IContextualMenuItem
    ) => {
      if (menuItem) {
        if (menuItem.key === "delete") {
          onDeletePressed(item);
        }
        if (menuItem.key === "bookmark") {
          onBookmarkPressed(item);
        }
        if (menuItem.key === "edit-info") {
          onEditInfoPressed(item);
        }
      }
    },
    [onDeletePressed, onBookmarkPressed, onEditInfoPressed, item]
  );

  const creatorName = item.createdBy ?? "Unknown";
  const imageInitials = React.useMemo(() => {
    return creatorName[0];
  }, [creatorName]);

  const _onClick = React.useCallback(() => {
    onPressed(item);
  }, [onPressed, item]);

  return (
    <div
      className={`group flex flex-1 flex-col px-6 pt-2 pb-4 hover:bg-[#f9f7f4] ${
        isSelected && "bg-[#EDEBE9]"
      }`}
      onClick={_onClick}
    >
      <div className="flex flex-1 flex-row items-center">
        <div className="flex-1">{titleDisplay}</div>
        <IconButton
          className="h-6 hidden group-hover:block"
          iconProps={{
            iconName: "More",
          }}
          onRenderMenuIcon={() => <div />}
          menuProps={{
            items: menus,
            onItemClick: onMenuItemPressed,
          }}
        />
      </div>
      {note && <p className="text-[#605E5C] text-xs leading-5">{note}</p>}
      <Persona
        className="mt-2"
        imageInitials={imageInitials}
        text={creatorName}
        styles={{
          primaryText: {
            font: "14px",
            fontWeight: 600,
            color: "#323130",
          },
          details: {
            padding: 8,
          },
        }}
        size={PersonaSize.size16}
      />
      {timestamp}
      {restoreInfo}
    </div>
  );
});

export default SnapshotVersionItem;
