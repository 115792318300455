import { FormattedMessage } from "@oursky/react-messageformat";
import cntl from "cntl";
import React, { ReactElement } from "react";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
}

const BetaTag = (props: Props): ReactElement => {
  const { className } = props;

  const classes = cntl`
  ${styles["beta-tag"]}
  ${className}
  bg-[#ffd633]
  `;

  return (
    <div className={classes}>
      <FormattedMessage id="beta_tag.name" />
    </div>
  );
};

export default BetaTag;
