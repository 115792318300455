%action-btn {
  font-size: 12px;
}
.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;

  .nav-bar {
    padding: 8px 12px;
    border-bottom: 1px solid #edebe9;
  }
  .container {
    display: flex;
    flex: 1;
    overflow-y: scroll;
    .nav-menu {
      flex: 1;
      border-right: 1px solid #edebe9;
      display: none;
      &--expanded {
        display: block;
        height: 100%;
      }
    }

    .content-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      position: relative;

      .header {
        border-bottom: 1px solid #edebe9;
        display: flex;
        align-items: center;

        .nav-menu-btn {
          color: #a19f9d;
          &--toggled {
            color: #25d0b1;
          }
        }
        .ordering-btn {
          @extend %action-btn;
          margin-left: 12px;
        }
        .command-group {
          padding: 0 8px;
          align-items: center;
          .divider {
            height: 24px;
            width: 1px;
            background: #edebe9;
            margin-right: 16px;
          }
          display: flex;
          flex: 1;
          justify-content: flex-end;
          button {
            @extend %action-btn;
            margin-right: 8px;
          }
        }
        .csv-file-input {
          display: none;
        }
      }

      .content {
        display: flex;
        flex: 1;
        background-color: #faf9f8;
        padding-bottom: 48px;
      }

      .footer {
        background-color: white;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 10px 20px;
        border-top: 1px solid #edebe9;
        justify-content: space-between;
        align-items: center;
        display: flex;
        flex: 1;

        .count-label {
          color: #605e5c;
          font-size: 12px;
        }
        .link {
          color: #0078d4;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
        }
      }
    }
  }
}
