import * as yup from "yup";

export type LLMCompletion = yup.InferType<typeof llmCompletionSchema>;

export const llmCompletionSchema = yup.object({
  prompt: yup.string().defined(),
  provider: yup.string().optional(),
  parameters: yup
    .object({
      should_output_prompt: yup.boolean().optional(),
      should_preserve_horizontal_whitespace: yup.boolean().optional(),
      should_preserve_vertial_whitespace: yup.boolean().optional(),
      should_split_prompt: yup.boolean().optional(),
      should_separate_prompt_by_fields: yup.boolean().optional(),
      top_p: yup.number().optional(),
      presence_penalty: yup.number().optional(),
      frequency_penalty: yup.number().optional(),
      model: yup.string().optional(),
    })
    .optional(),
});
