import React, { useCallback, useState } from "react";

import { ExtractAPIV2Document } from "../../models";
import { PreferenceKey, getPreference } from "../../utils/preference";
import { ActionButton } from "../WrappedMSComponents/Buttons";
import { ResultAssetViewer } from "./ResultAssetViewer";
import styles from "./styles.module.scss";

interface ResultAssetSectionProps {
  resultAsset: ExtractAPIV2Document;
  assetUrl?: string;
  onDownloadClick: () => void;
  onClickReExtract: () => void;
  canReExtract: boolean;
}
export function ResultAssetSection(props: ResultAssetSectionProps) {
  const {
    resultAsset,
    onDownloadClick,
    assetUrl,
    onClickReExtract,
    canReExtract,
  } = props;

  const [isViewingRawJson, setIsViewingRawJson] = useState<boolean>(false);

  const toggleViewMode = useCallback(() => {
    setIsViewingRawJson(prev => !prev);
  }, []);

  return (
    <>
      <div className={styles.viewOptionButtonGroup}>
        <ActionButton
          iconName={isViewingRawJson ? "Table" : "Code"}
          textId={
            isViewingRawJson
              ? "workspace.document_detail.view_in_key_value_option_button.label"
              : "workspace.document_detail.view_in_json"
          }
          onClick={toggleViewMode}
        />
        <ActionButton
          iconName="Download"
          textId="workspace.document_detail.download_button.label"
          onClick={onDownloadClick}
        />
        {canReExtract &&
        getPreference(PreferenceKey.allowReExtractSuccessResult) === "true" ? (
          <ActionButton
            iconName="Refresh"
            textId="workspace.document_detail.retry_extraction"
            onClick={onClickReExtract}
          />
        ) : null}
      </div>

      <ResultAssetViewer
        resultAsset={resultAsset}
        isViewingRawJson={isViewingRawJson}
        assetUrl={assetUrl}
      />
    </>
  );
}
