import React from "react";

import { JSON_INDENTATION } from "../../constants/format";
import { ExtractAPIV2Document } from "../../models";
import { ExtractAPIV2ResponseViewer } from "../ExtractAPIV2ResponseViewer";
import styles from "./styles.module.scss";

interface ResultAssetViewerProps {
  resultAsset: ExtractAPIV2Document;
  assetUrl?: string;
  isViewingRawJson?: boolean;
}
export function ResultAssetViewer(props: ResultAssetViewerProps) {
  const { resultAsset, isViewingRawJson = true, assetUrl } = props;

  if (isViewingRawJson) {
    return (
      <div className={styles.resultAssetJsonContainer}>
        <pre className={styles.resultAssetJson}>
          {JSON.stringify(resultAsset, null, JSON_INDENTATION)}
        </pre>
      </div>
    );
  }

  return (
    <div className={styles.resultAssetViewerContainer}>
      {isViewingRawJson ? (
        <div className={styles.resultAssetJsonViewerContainer}>
          <pre className={styles.resultAssetJson}>
            {JSON.stringify(resultAsset, null, JSON_INDENTATION)}
          </pre>
        </div>
      ) : (
        <ExtractAPIV2ResponseViewer
          response={resultAsset as ExtractAPIV2Document}
          sourceImage={assetUrl}
        />
      )}
    </div>
  );
}
