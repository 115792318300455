import { Icon, Spinner } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { SUPPORTED_EXTRACT_MIME } from "../../constants";
import { EXTRACTION_PAGE_SIZE } from "../../constants/layout";
import { useLocale } from "../../contexts/locale";
import { FOCRError } from "../../errors";
import { useDragAndDropFiles } from "../../hooks/drag_and_drop";
import { useToast } from "../../hooks/toast";
import { UploadQueueEntry } from "../../reducers/extraction";
import { Sort } from "../../types/api";
import {
  ExtractionFilterableField,
  ExtractionResult,
  PaginatedExtractions,
} from "../../types/extraction";
import { Workspace } from "../../types/workspace";
import { chooseFile } from "../../utils/file";
import { DangerButton } from "../DangerButton";
import WorkspaceDocumentBottomBar from "../WorkspaceDocumentBottomBar";
import WorkspaceDocumentTable, {
  WorkspaceDocumentTableItem,
  useWorkspaceDocumentTableItems,
} from "../WorkspaceDocumentTable";
import WorkspaceDocumentTopBar from "../WorkspaceDocumentTopBar";
import WorkspaceDocumentUploadWidget from "../WorkspaceDocumentUploadWidget";
import styles from "./styles.module.scss";

interface WorkspaceDocumentSectionProps {
  workspace: Workspace;
  extractions: PaginatedExtractions | null;
  processingExtractionResults: ExtractionResult[];
  currentPage: number;
  fileNameSearchText: string;
  onFileNameSearch: (searchText: string) => void;
  sort: Sort<ExtractionFilterableField>;
  onColumnSort: (args: Sort<ExtractionFilterableField>) => void;
  onPageChange: (newPage: number) => void;
  onExportSelected: (
    resultIndexesByExtractionId: Record<string, number[]>
  ) => Promise<void>;
  onExportAll: () => Promise<void>;
  isPreparingExport: boolean;
  isLoading: boolean;
  error: FOCRError | null;
  onSelectFiles: (files: File[]) => void;
  uploadQueue: UploadQueueEntry[];
  isUploadingForOtherWorkspaces: boolean;
  onCleanupUploadQueue: () => void;
  isOutOfQuota: boolean;
  onDeleteExtractionsOrResults: (
    resultKeys: {
      extractionId: string;
      extractionResult: ExtractionResult | null;
    }[]
  ) => void;
  onDeleteAllExtractions: () => void;
  processingCount: number;
  isUploadWidgetCollapsed: boolean;
  toggleIsUploadWidgetCollapsed: () => void;
  onRetry: (entry: UploadQueueEntry) => void;
  onRetryAllFailed: () => void;
  onReExtractExtraction: (extractionResult: ExtractionResult) => Promise<void>;
  shouldShowEmptyPlaceholder: boolean;
}

export function useWorkspaceDocumentSection(
  args: WorkspaceDocumentSectionProps
) {
  const {
    workspace,
    extractions,
    processingExtractionResults,
    currentPage,
    fileNameSearchText,
    onFileNameSearch,
    sort,
    onColumnSort,
    onPageChange,
    onExportSelected,
    onExportAll,
    isPreparingExport,
    isLoading,
    error,
    onSelectFiles,
    uploadQueue,
    isUploadingForOtherWorkspaces,
    onCleanupUploadQueue,
    isOutOfQuota,
    onDeleteExtractionsOrResults,
    onDeleteAllExtractions,
    processingCount,
    isUploadWidgetCollapsed,
    toggleIsUploadWidgetCollapsed,
    onRetry,
    onRetryAllFailed,
    onReExtractExtraction,
    shouldShowEmptyPlaceholder,
  } = args;

  const [isFileOver, setIsFileOver] = useState(false);

  const onDrop = useCallback((ev: React.DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsFileOver(false);
  }, []);

  const onDragOver = useCallback((ev: React.DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsFileOver(true);
  }, []);

  const onDragLeave = useCallback((ev: React.DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    // Fix state flickering on chrome
    // Ref: https://stackoverflow.com/questions/12945307/jquery-drag-and-drop-flickering-on-hover-webkit-only
    if (ev.pageX !== 0 || ev.pageY !== 0) {
      return;
    }
    setIsFileOver(false);
  }, []);

  const toast = useToast();
  const onUnsupportedFileDrop = useCallback(
    (fileType: string) => {
      toast.error("error.workspace.document_upload_unsupported", undefined, {
        fileType,
      });
    },
    [toast]
  );

  useDragAndDropFiles(
    (files?: File[]) => onSelectFiles(files ?? []),
    SUPPORTED_EXTRACT_MIME,
    undefined,
    {
      onDrop,
      onDragOver,
      onDragLeave,
      onUnsupportedFileError: onUnsupportedFileDrop,
    }
  );

  const onClickUpload = useCallback(
    (ev: React.MouseEvent<HTMLParagraphElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      chooseFile(SUPPORTED_EXTRACT_MIME.join(","), true)
        .then((files?: File[]) => {
          onSelectFiles(files ?? []);
        })
        .catch(console.error);
    },
    [onSelectFiles]
  );

  useEffect(() => {
    if (error != null) {
      toast.error(error.messageId, undefined, error.detail);
    }
  }, [error, toast]);

  const items = useWorkspaceDocumentTableItems(
    extractions?.extractions ?? [],
    processingExtractionResults
  );

  const totalPage = Math.max(
    1,
    Math.ceil((extractions?.pageInfo.totalCount ?? 0) / EXTRACTION_PAGE_SIZE)
  );

  const [selection, setSelection] = useState<WorkspaceDocumentTableItem[]>([]);

  const onClickPrevPage = useCallback(() => {
    if (extractions == null) {
      return;
    }
    onPageChange(Math.max(1, currentPage - 1));
  }, [currentPage, extractions, onPageChange]);
  const onClickNextPage = useCallback(() => {
    if (extractions == null) {
      return;
    }
    onPageChange(Math.min(totalPage, currentPage + 1));
  }, [currentPage, extractions, onPageChange, totalPage]);

  const onClickExport = useCallback(() => {
    if (selection.length <= 0) {
      onExportAll();
      return;
    }

    const resultIndexesByExtractionId = selection.reduce(
      (results, item) => ({
        ...results,
        [item.extractionId]: [
          ...(results[item.extractionId] ?? []),
          item.resultIndex - 1,
        ],
      }),
      {} as Record<string, number[]>
    );
    onExportSelected(resultIndexesByExtractionId);
  }, [selection, onExportSelected, onExportAll]);

  const onSelection = useCallback((items: WorkspaceDocumentTableItem[]) => {
    setSelection(items);
  }, []);

  const onExportSingle = useCallback(
    (item: WorkspaceDocumentTableItem) => {
      onExportSelected({
        [item.extractionId]: [item.resultIndex - 1],
      });
    },
    [onExportSelected]
  );

  const onDeleteSingle = useCallback(
    (item: WorkspaceDocumentTableItem) => {
      onDeleteExtractionsOrResults([
        {
          extractionId: item.extractionId,
          extractionResult: item.extractionResult ?? null,
        },
      ]);
    },
    [onDeleteExtractionsOrResults]
  );

  const onDeleteSelected = useCallback(() => {
    onDeleteExtractionsOrResults(
      selection.map(item => ({
        extractionId: item.extractionId,
        extractionResult: item.extractionResult ?? null,
      }))
    );
  }, [onDeleteExtractionsOrResults, selection]);

  const onDeleteAll = useCallback(() => {
    onDeleteAllExtractions();
  }, [onDeleteAllExtractions]);

  const showUploadWidgetDismissButton = useMemo(() => {
    return uploadQueue.every(entry => entry.state !== "uploading");
  }, [uploadQueue]);

  const onDismissUploadWidget = useCallback(() => {
    onCleanupUploadQueue();
  }, [onCleanupUploadQueue]);

  return React.useMemo(
    () => ({
      fileNameSearchText,
      onFileNameSearch,
      sort,
      onColumnSort,
      workspace,
      onClickPrevPage,
      onClickNextPage,
      onClickExport,
      selection,
      onSelection,
      onExportSingle,
      isPreparingExport,
      isLoading,
      items,
      error,
      currentPage,
      totalPage,
      onSelectFiles,
      isFileOver,
      uploadQueue,
      isUploadingForOtherWorkspaces,
      showUploadWidgetDismissButton,
      onDismissUploadWidget,
      isOutOfQuota,
      hasExtractingDocuments: processingCount > 0,
      onDeleteSingle,
      onDeleteAll,
      onDeleteSelected,
      processingCount,
      onClickUpload,
      isUploadWidgetCollapsed,
      toggleIsUploadWidgetCollapsed,
      onRetry,
      onRetryAllFailed,
      onReExtractExtraction,
      shouldShowEmptyPlaceholder,
    }),
    [
      fileNameSearchText,
      onFileNameSearch,
      sort,
      onColumnSort,
      workspace,
      onClickPrevPage,
      onClickNextPage,
      onClickExport,
      selection,
      onSelection,
      onExportSingle,
      isPreparingExport,
      isLoading,
      items,
      error,
      currentPage,
      totalPage,
      onSelectFiles,
      isFileOver,
      uploadQueue,
      isUploadingForOtherWorkspaces,
      showUploadWidgetDismissButton,
      onDismissUploadWidget,
      isOutOfQuota,
      processingCount,
      onDeleteSingle,
      onDeleteAll,
      onDeleteSelected,
      onClickUpload,
      isUploadWidgetCollapsed,
      toggleIsUploadWidgetCollapsed,
      onRetry,
      onRetryAllFailed,
      onReExtractExtraction,
      shouldShowEmptyPlaceholder,
    ]
  );
}

export function WorkspaceDocumentSectionImpl(
  props: ReturnType<typeof useWorkspaceDocumentSection>
) {
  const { localized } = useLocale();
  return (
    <div className={styles.container}>
      <WorkspaceDocumentTopBar
        className={styles.header}
        fileNameSearchText={props.fileNameSearchText}
        onFileNameSearch={props.onFileNameSearch}
        isOutOfQuota={props.isOutOfQuota}
        onSelectFiles={props.onSelectFiles}
        showDeleteButtons={!props.isLoading && props.items.length > 0}
        selectedCount={props.selection.length}
        onDeleteAll={props.onDeleteAll}
        onDeleteSelected={props.onDeleteSelected}
        onClickExport={props.onClickExport}
        isPreparingExport={props.isPreparingExport}
        hasExtractingDocuments={props.hasExtractingDocuments}
        hasDocuments={props.items.length > 0}
      />
      <div className={styles.tableContainer}>
        <WorkspaceDocumentTable
          className={styles.table}
          items={props.items}
          onSelection={props.onSelection}
          onExportSingle={props.onExportSingle}
          isPreparingExport={props.isPreparingExport}
          onDelete={props.onDeleteSingle}
          sort={props.sort}
          onColumnSort={props.onColumnSort}
          disableSelection={props.isPreparingExport}
          onReExtractExtraction={props.onReExtractExtraction}
        />
        {props.isLoading ? (
          <Spinner
            className={styles.loadingSpinner}
            label={localized("common.loading")}
          />
        ) : null}
        {!props.isLoading &&
        props.shouldShowEmptyPlaceholder &&
        props.items.length <= 0 &&
        props.fileNameSearchText.length > 0 ? (
          <p className={styles.notFoundMessage}>
            <FormattedMessage id={"workspace.document.not_found_message"} />
          </p>
        ) : null}
        {!props.isLoading &&
        props.shouldShowEmptyPlaceholder &&
        props.items.length <= 0 &&
        props.fileNameSearchText.length <= 0 ? (
          <div className={styles.uploadOverlay}>
            <Icon
              styles={{ root: { width: 100, height: 100 } }}
              iconName="IconWorkspaceUpload"
            />
            <p className={styles.emptyTitle}>
              <FormattedMessage id="workspace.document.empty.title" />
            </p>
            <p className={styles.emptyMessage}>
              <FormattedMessage id="workspace.document.empty.message" />
            </p>
            <DangerButton
              disabled={props.isOutOfQuota}
              onClick={props.onClickUpload}
              textId="workspace.document.empty.button"
            />
          </div>
        ) : null}
        {props.isFileOver ? (
          <div className={styles.uploadOverlay}>
            <Icon
              styles={{ root: { width: 100, height: 100 } }}
              iconName="IconWorkspaceUpload"
            />
            <p className={styles.dragDropMessage}>
              <FormattedMessage id="workspace.document.drag_drop_message" />
            </p>
          </div>
        ) : null}
        {props.uploadQueue.length > 0 ? (
          <WorkspaceDocumentUploadWidget
            className={styles.uploadWidget}
            uploadQueue={props.uploadQueue}
            showDismissButton={props.showUploadWidgetDismissButton}
            isUploadingForOtherWorkspaces={props.isUploadingForOtherWorkspaces}
            onDismiss={props.onDismissUploadWidget}
            isCollapsed={props.isUploadWidgetCollapsed}
            onToggleIsCollapsed={props.toggleIsUploadWidgetCollapsed}
            onRetry={props.onRetry}
            onRetryAllFailed={props.onRetryAllFailed}
          />
        ) : null}
      </div>

      <WorkspaceDocumentBottomBar
        currentPage={props.currentPage}
        totalPage={props.totalPage}
        onClickPrevPage={props.onClickPrevPage}
        onClickNextPage={props.onClickNextPage}
        processingCount={props.processingCount}
      />
    </div>
  );
}

export function WorkspaceDocumentSection(args: WorkspaceDocumentSectionProps) {
  const props = useWorkspaceDocumentSection(args);
  return <WorkspaceDocumentSectionImpl {...props} />;
}
