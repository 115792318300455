@import "../../styles/variables.scss";

.container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.block {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #edebe9;
}

.block-title {
  color: #323130;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
}

.block-content {
  display: flex;
  flex-direction: column;
  max-width: 485px;
}

.workspace-setups__content {
  gap: 20px;
}
.workspace-setups__header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.workspace-setups__header-title {
  display: flex;
  gap: 4px;
  align-items: flex-start;
}
.workspace-setups__header-description {
  color: #605e5c;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.connected_workspace__container {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.connected_workspace__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.connected_workspace__list--hidden {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.connected_workspace__list-title {
  color: #201f1e;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.workspace-item__container {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px 0;
  border-top: 1px solid #edebe9;
}
.workspace-item__name {
  flex: 1;
  color: #201f1e;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.general-settings__content {
  gap: 10px;
}
.general-settings__fields {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.general-settings__field-description {
  color: #605e5c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.advanced-settings__content {
  gap: 10px;
}
.advanced-settings__fields {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.general-settings__extractor-type {
  :global(.ms-TextField-fieldGroup) {
    border: none;
  }
  :global(.ms-TextField-field) {
    border-radius: 2px;
    border-color: #faf9f8;
    background-color: #faf9f8;
  }
}

.contact-us__content {
  gap: 10px;
}
.contact-us__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;

  a {
    color: #53b2a1;
  }
}

.footer__container {
  position: sticky;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 10;
  padding: 0 20px 30px;
}
.footer__separator {
  height: 1px;
  background-color: $separator-color-2;
  margin-bottom: 11px;
}
.footer__buttons {
  display: flex;
  justify-content: flex-end;
}

.hide {
  display: none;
}

.image-frame {
  width: 223px;
  height: 100px;
  margin-bottom: 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.upload-button-wrapper {
  display: flex;
}

.remove-image-button {
  margin-left: 5px;
  color: #a4262c;
  background: rgba(164, 38, 44, 0.05);
}

.import-buttons {
  display: flex;
  flex: row;
  gap: 8px;
  align-items: center;
}

.document-processing-mode-label__container {
  padding-left: 28px;
  margin-top: 2px;
}

.document-processing-mode-label__text {
  color: #201f1e;
}

.document-processing-mode-label__description {
  color: #a19f9d;
}

.document-processing-mode-label__disabled {
  .document-processing-mode-label__text {
    color: #a19f9d;
  }
}

.advanced-settings__field-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
}

.subtitle {
  color: #605e5c;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}
