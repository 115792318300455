import { parse } from "@fast-csv/parse";
import {
  ActionButton,
  DefaultButton,
  IContextualMenuItem,
  IContextualMenuProps,
  ISearchBox,
  Label,
  SearchBox,
  isMac,
} from "@fluentui/react";
import { Item } from "@glideapps/glide-data-grid";
import "@glideapps/glide-data-grid/dist/index.css";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import JSZip from "jszip";
import { DateTime } from "luxon";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useAdvanceTokenSetupEditor } from "../../contexts/advanceTokenSetupEditor";
import { useLocale } from "../../contexts/locale";
import errors, { FOCRError } from "../../errors";
import { useToast } from "../../hooks/toast";
import { MerchantPatternMatching } from "../../types/advancedPatternMatching";
import {
  CellData,
  CellDataType,
  LocalizedHeader,
  MenuItem,
  RowData,
  SheetData,
  SheetDataAccessor,
  Header as TableHeader,
  TagAutoCompleteCellData,
  TagCellData,
  TagKey,
  getDefaultCellValue,
  getRowDataTagCellAt,
  hasSheetTag,
  isEmpty,
  removeRowDataAt,
} from "../../types/advancedTokenSetup/table";
import { PaginatedConfigSnapshot } from "../../types/configSnapshot";
import { Form } from "../../types/form";
import { MerchantPatternMatchingMapper } from "../../types/mappers/advancedPatternMatching/merchant";
import { MerchantCSVWriter } from "../../types/mappers/advancedPatternMatching/merchantCSVWriter";
import { deepClone } from "../../utils/deepClone";
import { triggerFileSaveFromBlob } from "../../utils/file";
import { matchEventKey } from "../../utils/key";
import { getTimestamp } from "../../utils/time";
import { AdvancedTokenSetupTableValidator } from "../../validators/advancedTokenSetupTable";
import AdvanceTokenSetupMergeEditor, {
  Props as MergeEditorProps,
} from "../AdvanceTokenSetupMergeEditor";
import AdvanceTokenSetupNavBar from "../AdvanceTokenSetupNavBar";
import AdvanceTokenSetupNavMenu from "../AdvanceTokenSetupNavMenu";
import AdvanceTokenSetupTable, {
  AdvanceTokenSetupHint,
  Ordering,
} from "../AdvanceTokenSetupTable";
import {
  AdvanceTokenSetupVersionHistoryModalResponse,
  AdvanceTokenSetupVersionHistoryModalResponseType,
} from "../AdvanceTokenSetupVersionHistoryModal";
import BookmarkSnapshotModal, {
  BookmarkResponseType,
  useBookmarkSnapshotModalHandle,
} from "../BookmarkSnapshotModal";
import DataNotSavedPrompt from "../DataNotSavedPrompt";
import LoadingModal from "../LoadingModal";
import Link from "../WrappedMSComponents/Link";
import styles from "./styles.module.scss";

const CSV_TEMPLATE_EXPORT_FILENAME = "Advanced-pattern-matching-templates.zip";

enum ImportCSVOption {
  replace,
  append,
  downloadTemplate,
}

export enum ImportCSVStrategy {
  replace,
  append,
}

export function getOrderingTextId(ordering: Ordering): string {
  switch (ordering) {
    case Ordering.priorityAsc:
      return "advance_token_setup.searchbar.ordering.priority.asc";
    case Ordering.priorityDesc:
      return "advance_token_setup.searchbar.ordering.priority.desc";
    case Ordering.lastModifiedAsc:
      return "advance_token_setup.searchbar.ordering.last_modified.asc";
    case Ordering.lastModifiedDesc:
      return "advance_token_setup.searchbar.ordering.last_modified.desc";
    default:
      throw Error("unkown ordering " + ordering);
  }
}

function getImportingCSVTextId(menu: ImportCSVOption): string {
  switch (menu) {
    case ImportCSVOption.append:
      return "advance_token_setup.searchbar.importing_csv.append";
    case ImportCSVOption.replace:
      return "advance_token_setup.searchbar.importing_csv.replace";
    case ImportCSVOption.downloadTemplate:
      return "advance_token_setup.searchbar.importing_csv.download_template";
    default:
      throw Error("unkown ordering " + menu);
  }
}

function getTableExplanation(menu: MenuItem): AdvanceTokenSetupHint {
  let key = "";
  switch (menu) {
    case MenuItem.TagMall:
    case MenuItem.TagMerchant:
      key = "tag_groups";
      break;
    case MenuItem.ExactMatchRule:
      key = "exact_match_rule";
      break;
    case MenuItem.ClosestMatchRuleMall:
    case MenuItem.ClosestMatchRuleMerchant:
      key = "closest_match_rule";
      break;
    case MenuItem.FieldReplacementMall:
    case MenuItem.FieldReplacementMerchant:
      key = "field_replacement";
      break;
  }
  return {
    title: {
      id: `advance_token_setup.nav_menu.group.${key}.title`,
    },
    description: {
      id: `advance_token_setup.${key}.table.hint.description`,
    },
  };
}

type HeaderHandlerProps = ReturnType<typeof useHeaderHandle>;
interface HeaderProps extends HeaderHandlerProps {
  className?: string;
  isMenuOpened: boolean;
  onToggleNavMenu: () => void;
  ordering: Ordering;
  onSetOrdering: (ordering: Ordering) => void;
  onSearch: (keyword?: string) => void;
  onImportCsvFile: (menu: ImportCSVStrategy, file: File) => void;
  onExportCsvTemplateFile: () => void;
  onExportCsv: () => void;
  selectedMenu: MenuItem;
}

const useHeaderHandle = () => {
  const searchInputRef = useRef<ISearchBox>(null);

  const focusSearchInput = useCallback(() => {
    searchInputRef.current?.focus();
  }, []);
  return useMemo(
    () => ({
      triggerProps: {
        componentRef: searchInputRef,
      },
      focusSearchInput,
    }),
    [focusSearchInput]
  );
};

function clearTag(data: RowData, tagKey: TagKey, tag: string) {
  return {
    ...data,
    data: data.data.map(d => {
      if (
        d.type === CellDataType.TagAutoCompleteCellData &&
        d.tagKey === tagKey &&
        d.tag === tag
      ) {
        return {
          ...d,
          tag: "",
        };
      }
      return d;
    }),
  };
}
function hasNoTag(tagKey: TagKey, tag: string) {
  return (data: RowData) => {
    return (
      data.data.findIndex(
        d =>
          (d.type === CellDataType.TagCellData ||
            d.type === CellDataType.TagAutoCompleteCellData) &&
          d.tag === tag &&
          d.tagKey === tagKey
      ) === -1
    );
  };
}

export function mergeTable(
  mergeMode: ImportCSVStrategy,
  existingTable: [LocalizedHeader[], RowData[]],
  importingTable: [LocalizedHeader[], RowData[]],
  getDefaulCell: (type: CellDataType, item: Item) => CellData
): [LocalizedHeader[], RowData[]] {
  const [h1, d1] = deepClone(existingTable);
  const [h2, d2] = importingTable;

  if (mergeMode === ImportCSVStrategy.replace) {
    return importingTable;
  }

  const [headers1, headers2] = h1.length > h2.length ? [h1, h2] : [h2, h1];
  const [data1, data2] = h1.length > h2.length ? [d1, d2] : [d2, d1];

  let index1 = 0;
  let index2 = 0;

  let alignedData: RowData[] = data2;
  while (index1 < headers1.length) {
    const header1 = headers1[index1];
    const header2 = headers2[index2];

    if (header2 && header1.labelId === header2.labelId) {
      index1 += 1;
      index2 += 1;
    } else {
      index1 += 1;
      alignedData = alignedData.map((d, i) => {
        d.data.splice(index2, 0, getDefaulCell(header1.type, [index2, i]));
        return {
          ...d,
          data: d.data,
        };
      });
    }
  }
  const [alignedExistingData, alignedImportedData] =
    h1.length > h2.length ? [data1, alignedData] : [alignedData, data1];
  const baseAppendedRowOrder = alignedExistingData.length;

  return [
    headers1,
    alignedExistingData.concat(
      alignedImportedData.map((d, i) => ({
        ...d,
        order: baseAppendedRowOrder + i + 1,
      }))
    ),
  ];
}

export function validateSheetData(sheetData: SheetData) {
  const clone = deepClone(sheetData);

  const appendIfNotExist = (menuItem: MenuItem, id: string) => {
    if (
      clone[menuItem].findIndex(d => (d.data[0] as TagCellData).tag === id) ===
      -1
    ) {
      clone[menuItem].push({
        order: clone[menuItem].length + 1,
        modifiedAt: getTimestamp(),
        data: [
          {
            type: CellDataType.TagCellData,
            tag: id,
            tagKey: menuItem === MenuItem.TagMall ? "mall_id" : "merchant_id",
          },
        ],
      });
    }
  };

  clone[MenuItem.ExactMatchRule].forEach(rowData => {
    const merchantId = (rowData.data[0] as TagAutoCompleteCellData).tag ?? "";
    const mallId = (rowData.data[1] as TagAutoCompleteCellData).tag ?? "";
    appendIfNotExist(MenuItem.TagMerchant, merchantId);
    appendIfNotExist(MenuItem.TagMall, mallId);
  });

  clone[MenuItem.ClosestMatchRuleMall].forEach(rowData => {
    const mallId = (rowData.data[0] as TagAutoCompleteCellData).tag ?? "";
    appendIfNotExist(MenuItem.TagMall, mallId);
  });

  clone[MenuItem.ClosestMatchRuleMerchant].forEach(rowData => {
    const merchantId = (rowData.data[0] as TagAutoCompleteCellData).tag ?? "";
    appendIfNotExist(MenuItem.TagMerchant, merchantId);
  });

  return clone;
}

const Header = React.memo<HeaderProps>(props => {
  const {
    className,
    isMenuOpened,
    onToggleNavMenu,
    ordering,
    onSetOrdering,
    onSearch,
    triggerProps,
    onExportCsvTemplateFile: onDownloadCsvTemplateFile,
    onExportCsv,
    onImportCsvFile,
    selectedMenu,
  } = props;
  const csvFileInput = useRef<HTMLInputElement>(null);
  const [importCSVStrategy, setImportCSVStrategy] = useState<ImportCSVStrategy>(
    ImportCSVStrategy.append
  );
  const { localized } = useLocale();
  const placeholder = useMemo(() => {
    return isMac()
      ? localized("advance_token_setup.searchbar.placeholder.mac")
      : localized("advance_token_setup.searchbar.placeholder");
  }, [localized]);
  const onClickOrderingMenuItem = useCallback(
    (_: any, item?: IContextualMenuItem) => {
      if (item) {
        onSetOrdering(parseInt(item.key));
      }
    },
    [onSetOrdering]
  );

  const orderingMenu: IContextualMenuProps = useMemo(() => {
    return {
      items: [
        Ordering.priorityAsc,
        Ordering.priorityDesc,
        Ordering.lastModifiedAsc,
        Ordering.lastModifiedDesc,
      ].map(o => ({
        key: o.toString(),
        text: localized(getOrderingTextId(o)),
        onClick: onClickOrderingMenuItem,
      })),
    };
  }, [localized, onClickOrderingMenuItem]);

  const onClickImportingCSVMenuItem = useCallback(
    (_: any, item?: IContextualMenuItem) => {
      if (item) {
        const menu = parseInt(item.key) as ImportCSVOption;
        switch (menu) {
          case ImportCSVOption.downloadTemplate:
            onDownloadCsvTemplateFile();
            break;
          case ImportCSVOption.append:
          case ImportCSVOption.replace:
            setImportCSVStrategy(
              menu === ImportCSVOption.append
                ? ImportCSVStrategy.append
                : ImportCSVStrategy.replace
            );
            csvFileInput.current?.click();
            break;
        }
      }
    },
    [onDownloadCsvTemplateFile]
  );

  const importingCSVMenu: IContextualMenuProps = useMemo(() => {
    return {
      items: [
        ImportCSVOption.replace,
        ImportCSVOption.append,
        ImportCSVOption.downloadTemplate,
      ].map(o => ({
        key: o.toString(),
        text: localized(getImportingCSVTextId(o)),
        onClick: onClickImportingCSVMenuItem,
      })),
    };
  }, [localized, onClickImportingCSVMenuItem]);

  const onClearSearch = useCallback(() => {
    onSearch(undefined);
  }, [onSearch]);

  const onTextChange = useCallback(
    (_, newValue?: string) => {
      onSearch(newValue);
    },
    [onSearch]
  );

  const onFilesChange = useCallback(
    (e?: ChangeEvent<HTMLInputElement>) => {
      if (e?.target) {
        const { files } = e.target;
        if (files && files[0]) {
          onImportCsvFile(importCSVStrategy, files[0]);
        }
      }
    },
    [importCSVStrategy, onImportCsvFile]
  );

  const importExportAllowed = ![
    MenuItem.FieldReplacementMall,
    MenuItem.FieldReplacementMerchant,
  ].includes(selectedMenu);

  return (
    <div className={className}>
      <DefaultButton
        className={classNames(styles["nav-menu-btn"], {
          [styles["nav-menu-btn--toggled"]]: isMenuOpened,
        })}
        styles={{
          root: {
            border: 0,
            padding: 0,
            minWidth: 46,
            height: 46,
          },
        }}
        iconProps={{ iconName: "BulletedList" }}
        onClick={onToggleNavMenu}
      />
      <SearchBox
        {...triggerProps}
        placeholder={placeholder}
        styles={{
          root: {
            flex: 1,
            maxWidth: 350,
            height: "100%",
            border: 0,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 10,
            paddingRight: 10,
            background: "#FAF9F8",
          },
        }}
        onClear={onClearSearch}
        onSearch={onSearch}
        onChange={onTextChange}
      />
      <ActionButton
        className={styles["ordering-btn"]}
        iconProps={{ iconName: "SwitcherStartEnd" }}
        styles={{
          root: {
            border: 0,
          },
        }}
        text={localized(getOrderingTextId(ordering))}
        menuProps={orderingMenu}
      />
      {importExportAllowed && (
        <div className={styles["command-group"]}>
          <div className={styles["divider"]} />
          <ActionButton
            styles={{
              root: {
                border: 0,
              },
            }}
            iconProps={{ iconName: "Upload" }}
            text={localized("advance_token_setup.searchbar.btn.import_csv")}
            menuProps={importingCSVMenu}
          />
          <ActionButton
            styles={{
              root: {
                border: 0,
              },
            }}
            iconProps={{ iconName: "Download" }}
            text={localized("advance_token_setup.searchbar.btn.export_csv")}
            onClick={onExportCsv}
          />
          <input
            className={styles["csv-file-input"]}
            accept=".csv"
            ref={csvFileInput}
            type="file"
            onChange={onFilesChange}
          />
        </div>
      )}
    </div>
  );
});

interface FooterProps {
  className?: string;
  rowCount: number;
}

const Footer = React.memo((props: FooterProps) => {
  const { className, rowCount } = props;
  return (
    <div className={className}>
      <Label className={styles["count-label"]}>
        <FormattedMessage
          id="advance_token_setup.footer.num_of_rows"
          values={{ count: rowCount }}
        />
      </Label>
      <Link
        className={styles["link"]}
        to="https://www.formx.ai/talk-with-us?utm_campaign=portal_payment"
        target="_blank"
        styles={{
          root: {
            color: "#0078D4",
          },
        }}
      >
        <FormattedMessage
          id="advance_token_setup.footer.get_help"
          values={{ count: 1 }}
        />
      </Link>
    </div>
  );
});
export function useEditor(props: Props) {
  const { previewConfig, onOpenVersionHistory, isReadOnly } = props;
  const {
    merchantPatternMatching,
    save,
    isSavingForm,
    askForTagRemovalConfirmation,
    extractorBreadcrumbLink,
    isSnapshotVersionEnabled,
    lastPaginatedConfigSnapshots,
    isDataChanged,
    setIsDataChanged,
    fetchInitialConfigSnapshots,
  } = useAdvanceTokenSetupEditor();
  const [isMergeMode, setIsMergeMode] = useState(false);
  const [mergeEditorProps, setMergeEditorProps] = useState<MergeEditorProps>();
  const [jumpToErrorCell, setJumpToErrorCell] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<MenuItem>(
    MenuItem.TagMerchant
  );
  const onMenuItemClick = React.useCallback((item: MenuItem) => {
    setJumpToErrorCell(false);
    setSelectedMenu(item);
  }, []);
  const [isMenuOpened, setIsMenuOpened] = useState(true);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [ordering, setOrdering] = useState(Ordering.priorityDesc);
  const [query, setQuery] = useState<string>();
  const headerHandler = useHeaderHandle();

  const onToggleNavMenu = useCallback(() => {
    setIsMenuOpened(p => !p);
  }, []);

  const { localized } = useLocale();

  const [defaultHeaders, defaultTables] = useMemo(
    () => MerchantPatternMatchingMapper.fromStorage(merchantPatternMatching),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [_sheetData, setSheetData] =
    useState<Record<MenuItem, RowData[]>>(defaultTables);
  const [_headers, _setHeaders] =
    useState<Record<MenuItem, LocalizedHeader[]>>(defaultHeaders);
  const [sheetHeader, sheetData] = React.useMemo(() => {
    if (previewConfig) {
      return MerchantPatternMatchingMapper.fromStorage(previewConfig);
    }
    return [_headers, _sheetData];
  }, [_sheetData, _headers, previewConfig]);

  const headers = useMemo((): TableHeader[] => {
    return sheetHeader[selectedMenu].map(item => ({
      ...item,
      label: localized(item.labelId),
    }));
  }, [selectedMenu, localized, sheetHeader]);

  const freezeColumns = selectedMenu === MenuItem.ExactMatchRule ? 3 : 1;

  const tags = useMemo<Record<TagKey, string[]>>(() => {
    return {
      merchant_id: sheetData.TagMerchant.map(
        d => (d.data[0] as TagCellData).tag
      ).filter(t => t.length > 0),
      mall_id: sheetData.TagMall.map(
        d => (d.data[0] as TagCellData).tag
      ).filter(t => t.length > 0),
    };
  }, [sheetData]);

  const onCreateTag = useCallback(
    (tagKey: TagKey, newTag: string) => {
      setIsDataChanged(true);
      setSheetData(prev =>
        new SheetDataAccessor(prev).createTag(tagKey, newTag)
      );
    },
    [setIsDataChanged]
  );

  const onDeleteTag = useCallback(
    (tagKey: TagKey, tag: string) => {
      setIsDataChanged(true);
      setSheetData(prev => {
        const hasNoTagFilter = hasNoTag(tagKey, tag);
        return {
          ...prev,
          TagMerchant: prev.TagMerchant.filter(hasNoTagFilter),
          TagMall: prev.TagMall.filter(hasNoTagFilter),
          ExactMatchRule: prev.ExactMatchRule.map(d =>
            clearTag(d, tagKey, tag)
          ),
          ClosestMatchRuleMall:
            prev.ClosestMatchRuleMall.filter(hasNoTagFilter),
          ClosestMatchRuleMerchant:
            prev.ClosestMatchRuleMerchant.filter(hasNoTagFilter),
          FieldReplacementMerchant:
            prev.FieldReplacementMerchant.filter(hasNoTagFilter),
          FieldReplacementMall:
            prev.FieldReplacementMall.filter(hasNoTagFilter),
        };
      });
    },
    [setIsDataChanged]
  );

  const onRenameTag = useCallback(
    (tagKey: TagKey, row: number, _oldName: string, newName: string) => {
      setIsDataChanged(true);
      setSheetData(prev => {
        return new SheetDataAccessor(prev).renameTagAtRow(tagKey, row, newName);
      });
    },
    [setIsDataChanged]
  );

  const showRowNumberCell =
    ordering !== Ordering.lastModifiedAsc &&
    ordering !== Ordering.lastModifiedDesc &&
    selectedMenu === MenuItem.ExactMatchRule;

  const [error, setError] = useState<any>();

  const [isSaveError, showIsSaveError] = useState(false);
  const [isSaveSuccessfully, showIsSaveSuccessfully] = useState(false);
  const { open: openBookmarkModal, triggerProps: bookmarkModalProps } =
    useBookmarkSnapshotModalHandle();

  const toast = useToast();
  useEffect(() => {
    if (isSaveError) {
      toast.error(
        error && error instanceof FOCRError
          ? error.messageId
          : typeof error === "string"
          ? error
          : "error.advance_token_setup.fail_to_save"
      );
      showIsSaveError(false);
    }
  }, [toast, isSaveError, error]);
  useEffect(() => {
    if (isSaveSuccessfully) {
      toast.success("advance_token_setup.editor.toast.changes_saved");
      showIsSaveSuccessfully(false);
    }
  }, [toast, isSaveSuccessfully]);

  const navMenuErrorState = useMemo(() => {
    return (Object.keys(sheetData) as MenuItem[]).filter(
      d =>
        sheetData[d].findIndex(
          dd => dd.data.findIndex(field => field.error !== undefined) !== -1
        ) !== -1
    );
  }, [sheetData]);

  const data = sheetData[selectedMenu];

  const setData: React.Dispatch<React.SetStateAction<RowData[]>> = useCallback(
    dataHandler => {
      setIsDataChanged(true);
      if (typeof dataHandler === "function") {
        setSheetData(prev => {
          prev[selectedMenu] = dataHandler(prev[selectedMenu]);
          return { ...prev };
        });
      } else {
        setSheetData(prev => {
          prev[selectedMenu] = dataHandler;
          return { ...prev };
        });
      }
    },
    [selectedMenu, setIsDataChanged]
  );

  const onDelete = useCallback(
    (item: Item) => {
      if (!data[item[1]]) {
        // Ignore to remove empty row
        return;
      }
      const row = item[1];
      const tagCell = getRowDataTagCellAt(data, row);

      if (
        (selectedMenu === MenuItem.TagMall ||
          selectedMenu === MenuItem.TagMerchant) &&
        hasSheetTag(sheetData, tagCell?.tagKey ?? "", tagCell?.tag ?? "")
      ) {
        askForTagRemovalConfirmation()
          .then(() => {
            const { newData } = removeRowDataAt(data, row);
            setData(newData);
            const { tag, tagKey } = tagCell as TagCellData;
            onDeleteTag(tagKey, tag);
          })
          .catch(() => {});
      } else {
        setData(removeRowDataAt(data, row).newData);
      }
    },
    [
      data,
      setData,
      onDeleteTag,
      selectedMenu,
      askForTagRemovalConfirmation,
      sheetData,
    ]
  );

  const defaultCellValue = useCallback(
    (cellType: CellDataType, item: Item): CellData => {
      const [col] = item;
      const header = _headers[selectedMenu][col];
      return getDefaultCellValue(header, cellType);
    },
    [selectedMenu, _headers]
  );

  const autoAppendColumn = useCallback(
    (selectedMenu: MenuItem, labelId: string) => {
      const header = _headers[selectedMenu];
      const data = sheetData[selectedMenu];
      const lastIndex =
        header.length -
        deepClone(header)
          .reverse()
          .findIndex(h => h.labelId === labelId) -
        1;
      if (lastIndex >= 0 && data.find(d => !isEmpty(d.data[lastIndex]))) {
        const newHeader: LocalizedHeader = deepClone(header[lastIndex]);
        const insertedColumn = lastIndex + 1;
        header.splice(insertedColumn, 0, newHeader);
        const newData = sheetData[selectedMenu].map((d, i) => {
          d.data.splice(
            insertedColumn,
            0,
            defaultCellValue(newHeader.type, [insertedColumn, i])
          );
          return {
            ...d,
            data: [...d.data],
          };
        });
        _setHeaders({
          ..._headers,
        });
        setSheetData({
          ...sheetData,
          [selectedMenu]: newData,
        });
        setIsDataChanged(true);
      }
    },
    [sheetData, _headers, defaultCellValue, setIsDataChanged]
  );
  useEffect(() => {
    if (isReadOnly) {
      return;
    }
    if (selectedMenu === MenuItem.ExactMatchRule) {
      autoAppendColumn(
        selectedMenu,
        "advance_token_setup_table.header.positive_tokens"
      );
      autoAppendColumn(
        selectedMenu,
        "advance_token_setup_table.header.negative_tokens"
      );
    }
  }, [autoAppendColumn, selectedMenu, isReadOnly]);

  const validate = useCallback(
    (data: Record<MenuItem, RowData[]>) => {
      let isPass = true;
      const dataWithValidation: Record<MenuItem, RowData[]> = {
        [MenuItem.TagMerchant]: [],
        [MenuItem.TagMall]: [],
        [MenuItem.ExactMatchRule]: [],
        [MenuItem.FieldReplacementMerchant]: [],
        [MenuItem.FieldReplacementMall]: [],
        [MenuItem.ClosestMatchRuleMerchant]: [],
        [MenuItem.ClosestMatchRuleMall]: [],
      };
      for (const key in data) {
        const tableType = key as MenuItem;
        const [_isPass, result] = AdvancedTokenSetupTableValidator.validate(
          tableType,
          data[tableType]
        );
        dataWithValidation[tableType] = result.map(r => ({
          ...r,
          data: r.data.map(dd => ({
            ...dd,
            error: dd.error ? localized(dd.error) : undefined,
          })),
        }));
        isPass = isPass && _isPass;
      }
      setSheetData(dataWithValidation);
      setIsDataChanged(true);
      if (!isPass) {
        const firstErrorMenu = Object.keys(dataWithValidation).find(key =>
          dataWithValidation[key as MenuItem].find(
            field => field.data.filter(f => f.error !== undefined).length > 0
          )
        );
        if (firstErrorMenu) {
          setSelectedMenu(firstErrorMenu as MenuItem);
          setJumpToErrorCell(true);
        }
      }
      if (isPass) {
        return MerchantPatternMatchingMapper.toStorage(data);
      }
      return undefined;
    },
    [localized, setIsDataChanged]
  );

  const exportCsvTemplate = useCallback(() => {
    const files = [
      MenuItem.TagMall,
      MenuItem.TagMerchant,
      MenuItem.ExactMatchRule,
      MenuItem.ClosestMatchRuleMall,
      MenuItem.ClosestMatchRuleMerchant,
    ].map(menu => {
      const [header, demo, filename] = MerchantCSVWriter.getDefaultTemplate(
        menu as MenuItem
      );

      const csv = `${header}\n${demo}`;

      return {
        header,
        csv,
        filename,
      };
    });

    const zip = new JSZip();

    files.forEach(file => {
      zip.file(file.filename, file.csv);
    });

    zip.generateAsync({ type: "blob" }).then(blob => {
      triggerFileSaveFromBlob(blob, CSV_TEMPLATE_EXPORT_FILENAME);
    });
  }, []);

  const exportCsv = useCallback(() => {
    const records = {} as Record<MenuItem, string[][]>;
    [
      MenuItem.TagMall,
      MenuItem.TagMerchant,
      MenuItem.ExactMatchRule,
      MenuItem.ClosestMatchRuleMall,
      MenuItem.ClosestMatchRuleMerchant,
    ].map(menu => {
      const data = sheetData[menu];
      const rows = MerchantPatternMatchingMapper.toCSV(menu, data);
      records[menu] = rows;
    });

    MerchantCSVWriter.generateZip(records).then(blob => {
      const extractorName = (props.form?.name || "")
        .replace(/[\/<>:"\\\|\?\*]+/g, "-")
        .replace(/ +/g, "-")
        .replace(/-+/g, "-")
        .toLowerCase();
      const timestamp = DateTime.fromJSDate(new Date()).toISODate();
      const filename = `Advanced-pattern-matching-templates-${extractorName}-${timestamp}.zip`;
      triggerFileSaveFromBlob(blob, filename);
    });
  }, [sheetData, props.form]);

  const onMergeCancel = useCallback(() => {
    setIsMergeMode(false);
  }, []);

  const onMergeDone = useCallback(
    (mergedHeader: LocalizedHeader[], mergedData: RowData[]) => {
      _setHeaders(prev => ({
        ...prev,
        [selectedMenu]: mergedHeader,
      }));
      setSheetData(prev =>
        validateSheetData({
          ...prev,
          [selectedMenu]: mergedData,
        })
      );
      setMergeEditorProps(undefined);
      setIsMergeMode(false);
    },
    [selectedMenu]
  );

  const onImportCSVFile = useCallback(
    async (menu: ImportCSVStrategy, file: File) => {
      try {
        setIsUploadingFile(true);
        const content = await file.text();
        const csvData: string[][] = await new Promise((resolve, reject) => {
          const stream = parse({ headers: false });
          const rows = [] as string[][];
          stream.on("error", error => reject(error));
          stream.on("data", row => rows.push(row));
          stream.on("end", () => resolve(rows));
          stream.write(content);
          stream.end();
        });
        const [importedHeaders, importedData] =
          MerchantPatternMatchingMapper.fromCSV(selectedMenu, csvData);
        const [mergedHeaders, mergedData] = mergeTable(
          menu,
          [
            _headers[selectedMenu],
            sheetData[selectedMenu].map(d => ({
              ...d,
              data: d.data.map(dd => ({ ...dd, error: undefined })),
            })),
          ],
          [importedHeaders, importedData],
          defaultCellValue
        );
        setMergeEditorProps({
          sheetData,
          mergedHeaders,
          mergedData,
          menu: selectedMenu,
          onCancel: onMergeCancel,
          onMergedDone: onMergeDone,
          freezeColumns,
          tags,
        });
        setIsMergeMode(true);
        setIsUploadingFile(false);
      } catch (e) {
        toast.error("error.failed_to_import_csv");
        setIsUploadingFile(false);
      }
    },
    [
      selectedMenu,
      sheetData,
      _headers,
      onMergeCancel,
      onMergeDone,
      tags,
      defaultCellValue,
      freezeColumns,
      toast,
    ]
  );

  const onSave = useCallback(
    async (releaseOption?: { snapshotName: string; snapshotNote?: string }) => {
      if (isSavingForm) {
        return;
      }
      const validatedData = validate(sheetData);
      if (!validatedData) {
        showIsSaveError(true);
        return;
      }
      try {
        await save(validatedData, releaseOption);
        showIsSaveSuccessfully(true);
        setIsDataChanged(false);
      } catch (error) {
        if (error !== errors.ConflictFound) {
          setError(error);
          showIsSaveError(true);
        }
      }
    },
    [validate, sheetData, save, isSavingForm, setIsDataChanged]
  );

  const onSaveAndTest = useCallback(() => {
    onSave();
  }, [onSave]);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (
        matchEventKey(event, {
          mac: "meta+s",
          defaults: "ctrl+s",
        })
      ) {
        event.preventDefault();
        onSave();
      } else if (
        matchEventKey(event, {
          mac: "meta+shift+s",
          defaults: "ctrl+shift+s",
        })
      ) {
        event.preventDefault();
        onSaveAndTest();
      } else if (
        matchEventKey(event, {
          mac: "meta+f",
          defaults: "ctrl+f",
        })
      ) {
        event.preventDefault();
        headerHandler.focusSearchInput();
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onSave, onSaveAndTest, headerHandler]);

  const showSaveAndBookmarkModal = useCallback(async () => {
    const r = await openBookmarkModal();
    if (r.type === BookmarkResponseType.Accepted) {
      const { title, note } = r.acceptedValue;
      onSave({
        snapshotName: title,
        snapshotNote: note,
      });
    }
  }, [openBookmarkModal, onSave]);

  const onShowSnapshotHistory = useCallback(async () => {
    if (lastPaginatedConfigSnapshots && onOpenVersionHistory) {
      const r = await onOpenVersionHistory(lastPaginatedConfigSnapshots);
      if (
        r &&
        r.type === AdvanceTokenSetupVersionHistoryModalResponseType.Restored &&
        r.acceptedValue.merchant
      ) {
        const [headers, data] = MerchantPatternMatchingMapper.fromStorage(
          r.acceptedValue.merchant
        );
        _setHeaders(headers);
        setSheetData(data);
      }
    }
  }, [onOpenVersionHistory, lastPaginatedConfigSnapshots]);

  useEffect(() => {
    fetchInitialConfigSnapshots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    return {
      data,
      setData,
      onMenuItemClick,
      isMenuOpened,
      selectedMenu,
      onToggleNavMenu,
      headers,
      ordering,
      setOrdering,
      navMenuErrorState,
      defaultCellValue,
      onSaveAndTest,
      onSave,
      jumpToErrorCell,
      setJumpToErrorCell,
      showRowNumberCell,
      query,
      setQuery,
      headerHandler,
      tags,
      onCreateTag,
      onDelete,
      onRenameTag,
      isDataChanged,
      exportCsvTemplate,
      exportCsv,
      onImportCSVFile,
      isUploadingFile,
      mergeEditorProps,
      isMergeMode,
      freezeColumns,
      extractorBreadcrumbLink,
      showSaveAndBookmarkModal,
      isSnapshotVersionEnabled,
      isSnapshotVersionButtonEnabled:
        (lastPaginatedConfigSnapshots?.configSnapshots.length ?? 0) > 0,
      bookmarkModalProps,
      onShowSnapshotHistory,
      ...props,
      isReadOnly: props.isReadOnly ?? previewConfig !== undefined,
    };
  }, [
    props,
    data,
    setData,
    onMenuItemClick,
    isMenuOpened,
    selectedMenu,
    onToggleNavMenu,
    headers,
    ordering,
    setOrdering,
    navMenuErrorState,
    defaultCellValue,
    onSaveAndTest,
    onSave,
    jumpToErrorCell,
    setJumpToErrorCell,
    showRowNumberCell,
    query,
    setQuery,
    headerHandler,
    tags,
    onCreateTag,
    onDelete,
    onRenameTag,
    isDataChanged,
    exportCsvTemplate,
    exportCsv,
    onImportCSVFile,
    isUploadingFile,
    isMergeMode,
    mergeEditorProps,
    freezeColumns,
    extractorBreadcrumbLink,
    showSaveAndBookmarkModal,
    isSnapshotVersionEnabled,
    previewConfig,
    bookmarkModalProps,
    lastPaginatedConfigSnapshots,
    onShowSnapshotHistory,
  ]);
}

type EditorProps = ReturnType<typeof useEditor>;

const Editor: React.FC<EditorProps> = props => {
  const {
    form,
    isMenuOpened,
    onMenuItemClick,
    selectedMenu,
    onToggleNavMenu,
    ordering,
    setOrdering,
    headers,
    data,
    setData,
    navMenuErrorState,
    defaultCellValue,
    onSaveAndTest,
    onSave,
    jumpToErrorCell,
    showRowNumberCell,
    query,
    setQuery,
    headerHandler,
    tags,
    onCreateTag,
    onDelete,
    onRenameTag,
    exportCsvTemplate,
    exportCsv,
    onImportCSVFile,
    freezeColumns,
    extractorBreadcrumbLink,
    isReadOnly = false,
    showSaveAndBookmarkModal,
    isSnapshotVersionEnabled,
    onShowSnapshotHistory,
    visibilitySetting = {
      toolbar: true,
      breadcrumbNavBar: true,
      footer: true,
    },
    isSnapshotVersionButtonEnabled,
  } = props;

  const tableExplanation = React.useMemo(
    () =>
      visibilitySetting.toolbar ? getTableExplanation(selectedMenu) : undefined,
    [selectedMenu, visibilitySetting.toolbar]
  );

  return form ? (
    <>
      {visibilitySetting.breadcrumbNavBar && (
        <AdvanceTokenSetupNavBar
          className={styles["nav-bar"]}
          form={form}
          onSaveAndTest={onSaveAndTest}
          onSave={onSave}
          extractorBreadcrumbLink={extractorBreadcrumbLink}
          isSnapshotVersionEnabled={isSnapshotVersionEnabled}
          isSnapshotVersionButtonEnabled={isSnapshotVersionButtonEnabled}
          onShowSnapshotHistory={onShowSnapshotHistory}
          onSaveAndBookmarkPressed={showSaveAndBookmarkModal}
        />
      )}
      <div className={styles["container"]}>
        <AdvanceTokenSetupNavMenu
          className={classNames(styles["nav-menu"], {
            [styles["nav-menu--expanded"]]: isMenuOpened,
          })}
          selectedMenuItem={selectedMenu}
          onMenuItemClick={onMenuItemClick}
          errorState={navMenuErrorState}
        />
        <div
          className={classNames(
            styles["content-container"],
            "flex-col flex-1 overflow-x-hidden"
          )}
        >
          {visibilitySetting.toolbar && (
            <Header
              {...headerHandler}
              selectedMenu={selectedMenu}
              className={styles["header"]}
              isMenuOpened={isMenuOpened}
              onToggleNavMenu={onToggleNavMenu}
              ordering={ordering}
              onSetOrdering={setOrdering}
              onImportCsvFile={onImportCSVFile}
              onExportCsvTemplateFile={exportCsvTemplate}
              onExportCsv={exportCsv}
              onSearch={setQuery}
            />
          )}
          <div className={styles["content"]}>
            <AdvanceTokenSetupTable
              headers={headers}
              data={data}
              setData={setData}
              hint={tableExplanation}
              showRowOrder={showRowNumberCell}
              defaultCellValue={defaultCellValue}
              jumpToErrorCell={jumpToErrorCell}
              ordering={ordering}
              filter={query}
              tags={tags}
              onCreateTag={onCreateTag}
              onDelete={onDelete}
              onRenameTag={onRenameTag}
              freezeColumns={freezeColumns}
              isReadOnly={isReadOnly}
            />
          </div>
          {visibilitySetting.footer && (
            <Footer className={styles["footer"]} rowCount={data.length} />
          )}
        </div>
      </div>
    </>
  ) : null;
};

type MergedProps = Props & ReturnType<typeof useEditor>;

const AdvanceTokenSetupEditorImpl = React.memo((props: MergedProps) => {
  const {
    className,
    isDataChanged,
    isUploadingFile,
    mergeEditorProps,
    isMergeMode,
    bookmarkModalProps,
  } = props;

  return (
    <div className={classNames(styles["form"], className)}>
      {isMergeMode && mergeEditorProps ? (
        <AdvanceTokenSetupMergeEditor {...mergeEditorProps} />
      ) : (
        <Editor {...props} />
      )}
      <div
        id="portal"
        style={{ position: "fixed", left: 0, top: 0, zIndex: 9999 }}
      />
      <DataNotSavedPrompt
        isDataChanged={isDataChanged}
        titleTextId="advance_token_setup.editor.form_not_saved_prompt.title"
        messageTextId="advance_token_setup.editor.form_not_saved_prompt.save_warning"
        backTextId="advance_token_setup.editor.form_not_saved_prompt.go_back"
        continueTextId="advance_token_setup.editor.form_not_saved_prompt.leave_page"
      />
      <BookmarkSnapshotModal {...bookmarkModalProps} />
      <LoadingModal
        isOpen={isUploadingFile}
        messageId="advance_token_setup.editor.uploading"
      />
    </div>
  );
});

interface Props {
  className?: string;
  form?: Form;
  isReadOnly?: boolean;
  onOpenVersionHistory?: (
    initialPaginatedConfigSnapshot: PaginatedConfigSnapshot
  ) => Promise<AdvanceTokenSetupVersionHistoryModalResponse | undefined>;
  visibilitySetting?: {
    toolbar: boolean;
    breadcrumbNavBar: boolean;
    footer: boolean;
  };
  previewConfig?: MerchantPatternMatching;
}

const AdvanceTokenSetupEditor = (props: Props) => {
  return <AdvanceTokenSetupEditorImpl {...useEditor(props)} />;
};

export default AdvanceTokenSetupEditor;
