import * as yup from "yup";

import { briefExtractorSchema } from "./extractor";
import { pageInfoWithOffsetSchema } from "./pageInfo";

export enum DocumentExportFlattenOption {
  OneFlattenedColumnPerLineItem = "one_flattened_column_per_line_item",
  OneRowPerLineItem = "one_row_per_line_item",
}

const documentExportFlattenOptionSchema = yup
  .string()
  .oneOf(Object.values(DocumentExportFlattenOption));

const workspaceConfigSchema = yup
  .object({
    name: yup.string().required(),
    documentExportFlattenOption: documentExportFlattenOptionSchema.default(
      DocumentExportFlattenOption.OneFlattenedColumnPerLineItem
    ),
  })
  .camelCase();

export type WorkspaceConfig = yup.InferType<typeof workspaceConfigSchema>;

export const workspaceSchema = yup
  .object({
    id: yup.string().required(),
    config: workspaceConfigSchema.required(),
    extractors: yup.array(briefExtractorSchema.required()).required(),
    updatedAt: yup.string().required(),
    createdAt: yup.string().required(),
  })
  .transform((value, _original) => {
    return {
      ...value,
      extractor: value?.extractors[0],
    };
  })
  .shape({
    extractor: briefExtractorSchema.notRequired(),
  })
  .camelCase();

export const paginatedWorkspaceSchema = yup
  .object({
    pageInfo: pageInfoWithOffsetSchema.required(),
    workspaces: yup.array(workspaceSchema).defined(),
  })
  .camelCase();

export const createWorkspaceRespSchema = workspaceSchema.required();

export type CreateWorkspaceResp = yup.InferType<
  typeof createWorkspaceRespSchema
>;

export const updateWorkspaceRespSchema = workspaceSchema.required();

export type UpdateWorkspaceResp = yup.InferType<
  typeof updateWorkspaceRespSchema
>;

export const getWorkspaceRespSchema = workspaceSchema.required();

export type GetWorkspaceResp = yup.InferType<typeof getWorkspaceRespSchema>;
export type Workspace = yup.InferType<typeof workspaceSchema>;
export type PaginatedWorkspace = yup.InferType<typeof paginatedWorkspaceSchema>;

export const sharePointSubscriptionConfigSchema = yup
  .object({
    folderPath: yup.string().required(),
    changeCursor: yup.string().required(),
  })
  .camelCase();

export const sharePointSubscriptionSchema = yup
  .object({
    id: yup.string().required(),
    workspaceId: yup.string().required(),
    subscriptionExpiry: yup.string().required(),
    status: yup.string().required(),
    config: sharePointSubscriptionConfigSchema.required(),
  })
  .camelCase();

export type SharePointSubscription = yup.InferType<
  typeof sharePointSubscriptionSchema
>;

export enum SharePointSubscriptionStatus {
  Ok = "ok",
  InvalidToken = "invalid_token",
}
