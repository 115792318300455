import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import produce from "immer";

import * as formAction from "../actions/form";
import * as resourceOwnerAction from "../actions/resourceOwner";
import * as teamAction from "../actions/team";
import * as templateAction from "../actions/template";
import * as userAction from "../actions/user";
import * as workspaceAction from "../actions/workspace";
import {
  ENGINE_TO_EXTERNAL_SERVICE_MAP,
  MIN_NUMBER_OF_FORM_FEATURE,
  MIN_NUMBER_OF_QUERY_FEATURE,
} from "../constants";
import { MerchantSettingsMapper } from "../modelMapper";
import { ExternalServices } from "../models";
import { BriefWorkspace } from "../types/briefWorkspace";
import { DetailedForm } from "../types/form";
import { BriefWorkspaceMapper } from "../types/mappers/workspace";
import { Template } from "../types/template";
import { deepClone } from "../utils/deepClone";
import { settingsToConfig } from "../utils/formConfig";
import { replaceImageUrl } from "../utils/replaceImageUrl";
import { FormatterValidator } from "../validators/formatterValidator";

export interface FormState {
  readonly templates?: Template[];
  readonly currentForm?: DetailedForm;
  readonly currentFormImageSize?: {
    width: number;
    height: number;
  };

  readonly isAnyAnchorChanged: boolean;
  readonly isAnyFieldOrDetectionRegionChanged: boolean;
  readonly originalForm?: DetailedForm;
  readonly isFormChanged: boolean;
  getRequiredExternalServices(): ExternalServices[];
  isRequireFullImageOCR(): boolean;
}

const defaultState: FormState = {
  isAnyAnchorChanged: false,
  isAnyFieldOrDetectionRegionChanged: false,
  isFormChanged: false,
  getRequiredExternalServices: function (this: FormState) {
    if (this.currentForm === undefined) {
      return [];
    }

    const externalServicesSet = new Set<ExternalServices>();

    if (this.isRequireFullImageOCR()) {
      externalServicesSet.add("google");
    }

    for (const detectionRegion of this.currentForm.detectionRegions) {
      const { fields } = detectionRegion.config;
      for (const field of fields) {
        const params = field.params;
        if (params && params.engine) {
          const externalService = ENGINE_TO_EXTERNAL_SERVICE_MAP[params.engine];
          if (externalService) {
            externalServicesSet.add(externalService);
          }
        }
      }
    }
    return Array.from(externalServicesSet);
  },

  isRequireFullImageOCR: function (this: FormState) {
    if (this.currentForm === undefined) {
      return false;
    }

    const hasTextToken =
      this.currentForm.tokenGroups.findIndex(group => group.texts.length > 0) >=
      0;

    return (
      hasTextToken ||
      this.currentForm.keyValues.length > 0 ||
      (this.currentForm.config.auto_extraction_items &&
        this.currentForm.config.auto_extraction_items.length > 0)
    );
  },
};

function ensureFormConfigValue(form: DetailedForm) {
  return produce(form, draft => {
    if ("detector" in form.config && form.config.detector.type === "orb") {
      draft.config.detector.number_of_source_feature = Math.max(
        MIN_NUMBER_OF_FORM_FEATURE,
        draft.config.detector.number_of_source_feature
      );

      draft.config.detector.number_of_query_feature = Math.max(
        MIN_NUMBER_OF_QUERY_FEATURE,
        draft.config.detector.number_of_query_feature
      );
    }
  });
}

export const formReducer = createReducer<FormState>(defaultState, builder => {
  builder
    .addCase(formAction.FormsInvalidated, state => {
      state.currentForm = undefined;
    })
    .addCase(templateAction.GotTemplateList, (state, action) => {
      state.templates = action.payload;
    })
    .addCase(formAction.FormCreated, (state, action) => {
      const form = {
        ...action.payload,
        image: replaceImageUrl(action.payload.image),
      };

      state.currentForm = form;
      state.originalForm = deepClone(form);
      state.isFormChanged = false;
      state.isAnyAnchorChanged = false;
      state.isAnyFieldOrDetectionRegionChanged = false;
    })
    .addCase(formAction.GotForm, (state, action) => {
      const form = ensureFormConfigValue({
        ...action.payload,
        image: replaceImageUrl(action.payload.image),
      });

      state.currentForm = form;
      state.originalForm = deepClone(form);
      state.isFormChanged = false;
      state.isAnyAnchorChanged = false;
      state.isAnyFieldOrDetectionRegionChanged = false;
      if (state.currentForm?.id !== form.id) {
        state.currentFormImageSize = undefined;
      }

      const formatterValidator = new FormatterValidator();
      formatterValidator.stripForm(form);
    })
    .addCase(formAction.DiscardForm, state => {
      state.currentForm = deepClone(state.originalForm);
      state.isFormChanged = false;
      state.isAnyAnchorChanged = false;
      state.isAnyFieldOrDetectionRegionChanged = false;
      state.currentFormImageSize = undefined;
    })
    .addCase(formAction.GotFormImageSize, (state, action) => {
      state.currentFormImageSize = { ...action.payload };
    })
    .addCase(formAction.AnchorAdded, (state, action) => {
      if (state.currentForm) {
        state.isAnyAnchorChanged = true;
        state.currentForm.anchors.push(deepClone(action.payload));
      }
    })
    .addCase(formAction.AnchorDeleted, (state, action) => {
      if (state.currentForm) {
        state.isAnyAnchorChanged = true;
        state.currentForm.anchors = state.currentForm.anchors.filter(
          x => x.id !== action.payload.anchorId
        );
      }
    })
    .addCase(formAction.AnchorUpdated, (state, action) => {
      const anchor = action.payload;
      if (state.currentForm) {
        state.isAnyAnchorChanged = true;
        state.currentForm.anchors = state.currentForm.anchors.map(x =>
          x.id === anchor.id ? deepClone(anchor) : x
        );
      }
    })
    .addCase(formAction.FieldAdded, (state, action) => {
      if (state.currentForm) {
        state.isAnyFieldOrDetectionRegionChanged = true;
        state.currentForm.fields.push(deepClone(action.payload));
      }
    })
    .addCase(formAction.FieldDeleted, (state, action) => {
      if (state.currentForm) {
        state.isAnyFieldOrDetectionRegionChanged = true;
        state.currentForm.fields = state.currentForm.fields.filter(
          x => x.id !== action.payload.fieldId
        );
      }
    })
    .addCase(formAction.FieldUpdated, (state, action) => {
      const field = action.payload;
      if (state.currentForm) {
        state.isAnyFieldOrDetectionRegionChanged = true;
        state.currentForm.fields = state.currentForm.fields.map(x =>
          x.id === field.id ? deepClone(field) : x
        );
      }
    })
    .addCase(formAction.DetectionRegionAdded, (state, action) => {
      if (state.currentForm) {
        state.isAnyFieldOrDetectionRegionChanged = true;
        state.currentForm.detectionRegions.push(deepClone(action.payload));
      }
    })
    .addCase(formAction.DetectionRegionDeleted, (state, action) => {
      if (state.currentForm) {
        state.isAnyFieldOrDetectionRegionChanged = true;
        state.currentForm.detectionRegions =
          state.currentForm.detectionRegions.filter(
            x => x.id !== action.payload.detectionRegionId
          );
      }
    })
    .addCase(formAction.DetectionRegionUpdated, (state, action) => {
      const detectionRegion = action.payload;
      if (state.currentForm) {
        state.isAnyFieldOrDetectionRegionChanged = true;
        state.currentForm.detectionRegions =
          state.currentForm.detectionRegions.map(x =>
            x.id === detectionRegion.id ? deepClone(detectionRegion) : x
          );
      }
    })
    .addCase(formAction.FormUpdated, (state, action) => {
      const {
        formId,
        settings,
        imagePayload,
        keyValues,
        tokenGroups,
        customMerchants,
        cutomMerchantsFallback,
        customModelIds,
        advancedPatternMatching,
        extractorSettings,
        updateMerchantAutoExtractionItem,
      } = action.payload;

      if (state.currentForm?.id === formId) {
        if (settings) {
          state.currentForm.name = settings.name;
          state.currentForm.config = {
            ...state.currentForm.config,
            ...settingsToConfig(settings),
          };
        }

        if (imagePayload) {
          state.currentForm.image = imagePayload.imageUrl;
          state.currentForm.imageId = imagePayload.imageId;
        }

        if (keyValues) {
          state.currentForm.keyValues = keyValues;
        }

        if (tokenGroups) {
          state.currentForm.tokenGroups = tokenGroups;
        }

        if (customMerchants) {
          state.currentForm.config.custom_merchants =
            MerchantSettingsMapper.toResp(customMerchants);
        }

        if (cutomMerchantsFallback !== undefined) {
          state.currentForm.config.custom_merchants_fallback =
            cutomMerchantsFallback;
        }

        if (customModelIds !== undefined) {
          state.currentForm.customModelIds = [...customModelIds];
        }

        if (advancedPatternMatching) {
          state.currentForm.config.advanced_pattern_matching =
            advancedPatternMatching;
        }

        if (updateMerchantAutoExtractionItem) {
          state.currentForm.config.auto_extraction_items =
            state.currentForm.config.auto_extraction_items.filter(
              item => item !== "merchant"
            );

          if (extractorSettings?.advancedPatternMatchingEnabled) {
            state.currentForm.config.auto_extraction_items =
              state.currentForm.config.auto_extraction_items.concat("merchant");
          }
        }

        if (extractorSettings) {
          state.currentForm.name = extractorSettings.extractorName;
          state.currentForm.config.post_process_script =
            extractorSettings.postProcessingScript;
          state.currentForm.config.transform_response_script =
            extractorSettings.transformResponseScript;
          state.currentForm.config.ocr_config = extractorSettings.ocrConfig;
          state.currentForm.config.processing_mode =
            extractorSettings.processingMode;
          state.currentForm.config.llm_model_in_use =
            extractorSettings.llmModelInUse;

          // Set prebuilt llm completions
          if (
            !state.currentForm.isForCustomModelTesting &&
            (extractorSettings.preverseHorizontalWhiteSpace !== undefined ||
              extractorSettings.preverseVerticalWhiteSpace !== undefined ||
              extractorSettings.splitPromptForLineItems !== undefined)
          ) {
            state.currentForm.config.llm_completions = {
              ...state.currentForm.config.llm_completions,
              ...{},
            };
            state.currentForm.config.llm_completions.prebuilt = {
              ...state.currentForm.config.llm_completions.prebuilt,
              ...{ prompt: "" },
            };
            state.currentForm.config.llm_completions.prebuilt.parameters = {
              ...state.currentForm.config.llm_completions.prebuilt.parameters,
              should_preserve_horizontal_whitespace:
                extractorSettings.preverseHorizontalWhiteSpace,
              should_preserve_vertial_whitespace:
                extractorSettings.preverseVerticalWhiteSpace,
              should_separate_prompt_by_fields:
                extractorSettings.splitPromptForLineItems,
            };
          }
        }

        state.isFormChanged = true;
      }
    })
    .addCase(formAction.FormRenamed, (state, action) => {
      const { formId, name, updatedAt } = action.payload;
      if (state.currentForm?.id === formId) {
        state.currentForm.name = name;
        state.currentForm.updatedAt = updatedAt;
      }

      if (state.originalForm?.id === formId) {
        state.originalForm.name = name;
        state.originalForm.updatedAt = updatedAt;
      }
    })
    .addCase(formAction.FormSaved, (state, action) => {
      const form = action.payload;

      if (state.currentForm?.id === form.id) {
        const currentForm = {
          ...state.currentForm,
          ...form,
          image: state.currentForm.image,
        };

        state.isFormChanged = false;
        state.isAnyAnchorChanged = false;
        state.isAnyFieldOrDetectionRegionChanged = false;
        state.currentForm = currentForm;
        state.originalForm = deepClone(currentForm);
      }
    })
    .addCase(workspaceAction.CreateWorkspaceSuccess, (state, action) => {
      if (
        state.currentForm != null &&
        state.currentForm.id === action.payload.formId
      ) {
        state.currentForm.workspaces = [
          ...state.currentForm.workspaces,
          BriefWorkspaceMapper.fromWorkspace(action.payload.workspace),
        ];
      }
    })
    .addCase(workspaceAction.DeleteWorkspaceSuccess, (state, action) => {
      if (state.currentForm != null) {
        state.currentForm.workspaces = state.currentForm.workspaces.filter(
          (w: BriefWorkspace) => w.id !== action.payload.workspaceId
        );
      }
    })
    .addMatcher(isAnyOf(userAction.UserLogin, userAction.UserLogout), _ => ({
      ...defaultState,
    }))
    .addMatcher(
      isAnyOf(
        resourceOwnerAction.SelectTeam,
        teamAction.CreateTeam,
        teamAction.TeamDeleted,
        teamAction.TeamInvitationAccepted
      ),
      state => ({
        ...defaultState,
        templates: state.templates,
      })
    );
});
