import { IBreadcrumbItem } from "@fluentui/react";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { useFormActionCreator } from "../actions/form";
import { apiClient } from "../apiClient";
import AdvanceTokenSetupEditor from "../components/AdvanceTokenSetupEditor";
import AdvanceTokenSetupVersionHistoryModal, {
  useAdvanceTokenSetupVersionHistoryModalHandle,
} from "../components/AdvanceTokenSetupVersionHistoryModal";
import { Layout, Main, Top } from "../components/Layout";
import { UserFeatureFlag } from "../constants";
import { CONFIG_SNAPSHOT_PAGE_SIZE } from "../constants/layout";
import { AdvanceTokenSetupEditorProvider } from "../contexts/advanceTokenSetupEditor";
import { useCommonCustomModelContainerState } from "../hooks/custom_model";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { RootState } from "../redux/types";
import { PaginatedConfigSnapshot } from "../types/configSnapshot";
import { DetailedForm } from "../types/form";
import HeaderContainer from "./Header";

type PathParam = {
  customModelId: string;
};

function useCustomModelAdvancedTokenSetupContainer() {
  const { customModelId } = useUnsafeParams<PathParam>();
  const containerState = useCommonCustomModelContainerState(customModelId);

  // we need underlying form for save/load advanced merchant pattern matching
  const {
    getForm,
    saveForm: _saveForm,
    updateMerchantPatternMatching,
    restoreFormConfig,
  } = useFormActionCreator();

  const [isSavingForm, setIsSavingForm] = React.useState(false);

  const { currentForm: underlyingForm } = useSelector(
    (state: RootState) => state.form
  );

  const saveForm = React.useCallback(
    async (
      shouldIgnoreConflict?: boolean,
      formConfigSnapshotOption?: {
        snapshotName: string;
        snapshotNote?: string;
      }
    ) => {
      setIsSavingForm(true);
      try {
        await _saveForm(shouldIgnoreConflict, formConfigSnapshotOption);
      } finally {
        setIsSavingForm(false);
      }
    },
    [_saveForm]
  );

  React.useEffect(() => {
    if (
      containerState.state !== "success" ||
      containerState.customModel?.formID == null
    ) {
      return;
    }
    getForm(containerState.customModel?.formID);
    // @ts-expect-error containerState only has customModel if success
  }, [containerState.customModel?.formID, containerState.state, getForm]);

  const extractorBreadcrumbLink = React.useMemo(() => {
    if (containerState.state !== "success") {
      return undefined;
    }
    const { name, id } = containerState.customModel;
    return {
      text: name,
      key: id,
      href: `/custom-model/${id}/edit`,
    } as IBreadcrumbItem;
    // @ts-expect-error containerState only has customModel if success
  }, [containerState.customModel, containerState.state]);

  return React.useMemo(
    () => ({
      customModelId,
      containerState,
      underlyingForm,
      saveForm,
      isSavingForm,
      updateMerchantPatternMatching,
      extractorBreadcrumbLink,
      restoreFormConfig,
    }),
    [
      containerState,
      customModelId,
      underlyingForm,
      saveForm,
      isSavingForm,
      updateMerchantPatternMatching,
      extractorBreadcrumbLink,
      restoreFormConfig,
    ]
  );
}

const CustomModelAdvanceTokenSetupImpl = (
  props: ReturnType<typeof useCustomModelAdvancedTokenSetupContainer>
) => {
  const { underlyingForm } = props;

  const { open: openVersionHistory, triggerProps: versionHistoryProps } =
    useAdvanceTokenSetupVersionHistoryModalHandle();

  const onOpenVersionHistory = React.useCallback(
    async (initialConfigSnapshots: PaginatedConfigSnapshot) => {
      if (!underlyingForm) {
        return;
      }

      return openVersionHistory({
        form: underlyingForm,
        initialConfigSnapshots,
      });
    },
    [openVersionHistory, underlyingForm]
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      {underlyingForm != null && (
        <Main hasTop={true}>
          <AdvanceTokenSetupEditor
            form={underlyingForm}
            onOpenVersionHistory={onOpenVersionHistory}
          />
          <AdvanceTokenSetupVersionHistoryModal {...versionHistoryProps} />
        </Main>
      )}
    </Layout>
  );
};

const CustomModelAdvanceTokenSetup = React.memo(() => {
  const props = useCustomModelAdvancedTokenSetupContainer();
  const {
    saveForm,
    updateMerchantPatternMatching,
    isSavingForm,
    underlyingForm,
    restoreFormConfig: restoreFormConfigImpl,
  } = props;

  const { handleConflict } = useConfirmModalActionCreator();
  const isVersionSnapshotsEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.FormVersionSnapshots)
  );
  const fetchConfigSnapshots = useCallback(
    (form: DetailedForm, cursor?: string) => {
      return apiClient.listConfigSnapshots(
        "form",
        form.id,
        "advanced_pattern_matching",
        CONFIG_SNAPSHOT_PAGE_SIZE,
        cursor,
        form.resourceOwnerId ?? ""
      );
    },
    []
  );

  const restoreFormConfig = React.useCallback(
    async (configSnapshotId: string) => {
      return handleConflict(
        () => restoreFormConfigImpl(false, configSnapshotId),
        () => restoreFormConfigImpl(true, configSnapshotId),
        {
          titleId: "form_editor.form_modifed_prompt.title",
          messageId: "form_editor.form_modifed_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );
    },
    [restoreFormConfigImpl, handleConflict]
  );

  const editConfigSnapshotInfo = React.useCallback(
    async (
      configSnapshotId: string,
      retrievedAt: string,
      name: string,
      note?: string
    ) => {
      return handleConflict(
        () =>
          apiClient.editConfigSnapshotInfo(
            configSnapshotId,
            retrievedAt,
            false,
            name,
            note
          ),
        () =>
          apiClient.editConfigSnapshotInfo(
            configSnapshotId,
            retrievedAt,
            true,
            name,
            note
          ),
        {
          titleId: "error.config_snapshot_modifed_prompt.title",
          messageId: "error.config_snapshot_modifed_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );
    },
    [handleConflict]
  );

  const bookmarkConfigSnapshot = React.useCallback(
    async (
      configSnapshotId: string,
      retrievedAt: string,
      name: string,
      note?: string
    ) => {
      return handleConflict(
        () =>
          apiClient.bookmarkConfigSnapshot(
            configSnapshotId,
            retrievedAt,
            false,
            name,
            note
          ),
        () =>
          apiClient.bookmarkConfigSnapshot(
            configSnapshotId,
            retrievedAt,
            true,
            name,
            note
          ),
        {
          titleId: "error.config_snapshot_modifed_prompt.title",
          messageId: "error.config_snapshot_modifed_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );
    },
    [handleConflict]
  );

  const deleteConfigSnapshot = React.useCallback(
    async (configSnapshotId: string) => {
      return apiClient.deleteConfigSnapshot(configSnapshotId);
    },
    []
  );
  const getConfigSnapshot = React.useCallback(
    async (configSnapshotId: string) => {
      return apiClient.getConfigSnapshot(configSnapshotId);
    },
    []
  );
  return (
    <AdvanceTokenSetupEditorProvider
      saveForm={saveForm}
      updateMerchantPatternMatching={updateMerchantPatternMatching}
      isSavingForm={isSavingForm}
      form={underlyingForm}
      extractorBreadcrumbLink={props.extractorBreadcrumbLink}
      isVersionSnapshotsEnabled={isVersionSnapshotsEnabled}
      fetchConfigSnapshots={fetchConfigSnapshots}
      editConfigSnapshot={editConfigSnapshotInfo}
      deleteConfigSnapshot={deleteConfigSnapshot}
      bookmarkConfigSnapshot={bookmarkConfigSnapshot}
      restoreConfigSnapshot={restoreFormConfig}
      getConfigSnapshot={getConfigSnapshot}
    >
      <CustomModelAdvanceTokenSetupImpl {...props} />
    </AdvanceTokenSetupEditorProvider>
  );
});

export default CustomModelAdvanceTokenSetup;
