import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { useMicrosoftAuthActionCreator } from "../actions/microsoftAuth";
import { useWorkspaceIntegrationActionCreator } from "../actions/workspaceIntegration";
import errors, { FOCRError } from "../errors";
import { RootState } from "../redux/types";
import {
  FilePickerSharePointFile,
  WorkspaceSharePointIntegrationConfiguration,
} from "../types/workspaceIntegration";
import { useToast } from "./toast";

export interface useSharePointIntegrationReturnValues {
  isLoggingInToSharePoint: boolean;
  isLoggedInToSharePoint: boolean;
  onLoginSharePoint: (siteUrl: string) => Promise<void>;
  onSharePointSubscriptionSave: (
    workspaceId: string,
    siteUrl: string,
    selectedFolder: FilePickerSharePointFile
  ) => Promise<void>;
  onSharePointSubscriptionDisconnect: () => void;
  onSharePointIntegrationRemove: () => void;
  integrationConfigs: WorkspaceSharePointIntegrationConfiguration;
}

export function useSharePointIntegration(): useSharePointIntegrationReturnValues {
  const { login } = useMicrosoftAuthActionCreator();
  const { isLoggingIn, tokens } = useSelector(
    (state: RootState) => state.microsoftAuth
  );
  const { createWorkspaceSharePointSubscription } =
    useWorkspaceIntegrationActionCreator();
  const toast = useToast();

  // TODO - replaced by real integration configs
  const [dummyIntegrationConfigs] =
    React.useState<WorkspaceSharePointIntegrationConfiguration>({
      optionType: "import_sharepoint",
      sharePointSubscription: false,
      siteUrlSubdomain: "",
    });

  // TODO: Move create subscription to redux action
  const onSharePointSubscriptionSave: useSharePointIntegrationReturnValues["onSharePointSubscriptionSave"] =
    React.useCallback(
      async (workspaceId, siteUrl, selectedFolder) => {
        try {
          if (tokens?.refreshToken == null) {
            throw new Error("Failed to get refresh token");
          }
          await createWorkspaceSharePointSubscription({
            workspaceId,
            refreshToken: tokens.refreshToken,
            siteUrl,
            listId: selectedFolder.sharepointIds.listId,
            listItemId: selectedFolder.sharepointIds.listItemId,
          });
        } catch (e: unknown) {
          if (e instanceof FOCRError && e !== errors.UnknownError) {
            toast.error(e.messageId);
          } else {
            toast.error(
              "error.create_workspace_sharepoint_subscription.failed"
            );
          }
        }
      },
      [createWorkspaceSharePointSubscription, toast, tokens?.refreshToken]
    );

  const integrationConfigs = React.useMemo(
    () => dummyIntegrationConfigs,
    [dummyIntegrationConfigs]
  );

  const onSharePointSubscriptionDisconnect = useCallback(() => {
    alert("not implemented");
  }, []);

  const onSharePointIntegrationRemove = useCallback(() => {
    alert("not implemented");
  }, []);

  return React.useMemo(
    () => ({
      isLoggingInToSharePoint: isLoggingIn,
      isLoggedInToSharePoint: tokens != null,
      onLoginSharePoint: login,
      onSharePointSubscriptionSave,
      onSharePointSubscriptionDisconnect,
      onSharePointIntegrationRemove,
      integrationConfigs,
    }),
    [
      isLoggingIn,
      tokens,
      login,
      onSharePointSubscriptionSave,
      onSharePointSubscriptionDisconnect,
      onSharePointIntegrationRemove,
      integrationConfigs,
    ]
  );
}
