import {
  CopyRegular,
  DocumentBulletListMultipleRegular,
  ImageCopyRegular,
  TagRegular,
} from "@fluentui/react-icons";
import { registerIcons } from "@fluentui/react/lib/Styling";
import {
  IconBoxModel,
  IconCertificate,
  IconClipboardList,
  IconCopy,
  IconCrosshair,
  IconEdit,
  IconFileAnalytics,
  IconFileCertificate,
  IconFileImport,
  IconFileInvoice,
  IconFlask,
  IconId,
  IconList,
  IconPin,
  IconPlus,
  IconPuzzle,
  IconReceipt2,
  IconTag,
  IconTrash,
} from "@tabler/icons";
import WorkspaceUpload from "jsx:../images/ic-workspace-upload.svg";
import IconFailCircle from "jsx:../images/icon-fail-circle.svg";
import IconSuccessCircle from "jsx:../images/icon-success-circle.svg";
import IconCopy2 from "jsx:../images/icons/copy.svg";
import IconCurl from "jsx:../images/icons/curl.svg";
import IconDropbox from "jsx:../images/icons/dropbox.svg";
import IconEmail from "jsx:../images/icons/email.svg";
import IconExtract from "jsx:../images/icons/extract.svg";
import IconExtractorBankStatement from "jsx:../images/icons/extractor-bank-statement.svg";
import IconExtractorBillOfLading from "jsx:../images/icons/extractor-bill-of-lading.svg";
import IconExtractorCardAddressProof from "jsx:../images/icons/extractor-card-address-proof.svg";
import IconExtractorCardBankStatement from "jsx:../images/icons/extractor-card-bank-statement.svg";
import IconExtractorCardBillOfLading from "jsx:../images/icons/extractor-card-bill-of-lading.svg";
import IconExtractorCardBusinessRegistration from "jsx:../images/icons/extractor-card-business-registraion.svg";
import IconExtractorCardCombine from "jsx:../images/icons/extractor-card-combine.svg";
import IconExtractorCardCustomModelExtractor from "jsx:../images/icons/extractor-card-custom-model-extractor.svg";
import IconExtractorCardFixed from "jsx:../images/icons/extractor-card-fixed.svg";
import IconExtractorCardId from "jsx:../images/icons/extractor-card-id.svg";
import IconExtractorCardInvoice from "jsx:../images/icons/extractor-card-invoice.svg";
import IconExtractorCardLicense from "jsx:../images/icons/extractor-card-license.svg";
import IconExtractorCardReceipt from "jsx:../images/icons/extractor-card-receipt.svg";
import IconFixedLayout from "jsx:../images/icons/fixed-layout.svg";
import IconGo from "jsx:../images/icons/go.svg";
import IconGoogleDrive from "jsx:../images/icons/google-drive.svg";
import IconJS from "jsx:../images/icons/js.svg";
import IconKey from "jsx:../images/icons/key.svg";
import IconPeopleTeam from "jsx:../images/icons/people-team.svg";
import IconPHP from "jsx:../images/icons/php.svg";
import IconProof from "jsx:../images/icons/proof.svg";
import IconPython from "jsx:../images/icons/python.svg";
import IconRegex from "jsx:../images/icons/regex.svg";
import IconSharePoint from "jsx:../images/icons/sharepoint.svg";
import IconWebhook from "jsx:../images/icons/webhook.svg";
import IconZapier from "jsx:../images/icons/zapier.svg";
import * as React from "react";

export function registerCustomIcons() {
  registerIcons({
    icons: {
      IconCrosshair: <IconCrosshair />,
      IconFileInvoice: <IconFileInvoice />,
      IconId: <IconId />,
      IconClipboardList: <IconClipboardList />,
      IconBoxModel: <IconBoxModel />,
      IconReceipt2: <IconReceipt2 />,
      IconFileCertificate: <IconFileCertificate />,
      IconPuzzle: <IconPuzzle />,
      IconPin: <IconPin />,
      IconFileImport: <IconFileImport />,
      IconList: <IconList />,
      IconCertificate: <IconCertificate />,
      IconPlus: <IconPlus />,
      IconEdit: <IconEdit />,
      IconFlask: <IconFlask />,
      IconFileAnalytics: <IconFileAnalytics />,
      IconCopy: <IconCopy />,
      IconTag: <IconTag />,
      IconExtract: <IconExtract />,
      IconFixedLayout: <IconFixedLayout />,
      IconProof: <IconProof />,
      IconCopy2: <IconCopy2 />,
      IconCurl: <IconCurl />,
      IconGo: <IconGo />,
      IconJS: <IconJS />,
      IconPython: <IconPython />,
      IconPHP: <IconPHP />,
      IconTrash: <IconTrash />,
      IconExtractorCardAddressProof: <IconExtractorCardAddressProof />,
      IconExtractorCardBusinessRegistration: (
        <IconExtractorCardBusinessRegistration />
      ),
      IconExtractorCardBillOfLading: <IconExtractorCardBillOfLading />,
      IconExtractorCardBankStatement: <IconExtractorCardBankStatement />,
      IconExtractorCardCombine: <IconExtractorCardCombine />,
      IconExtractorCardFixed: <IconExtractorCardFixed />,
      IconExtractorCardId: <IconExtractorCardId />,
      IconExtractorCardInvoice: <IconExtractorCardInvoice />,
      IconExtractorCardLicense: <IconExtractorCardLicense />,
      IconExtractorCardReceipt: <IconExtractorCardReceipt />,
      IconExtractorCardCustomModelExtractor: (
        <IconExtractorCardCustomModelExtractor />
      ),
      IconExtractorBillOfLading: <IconExtractorBillOfLading />,
      IconExtractorBankStatement: <IconExtractorBankStatement />,
      IconKey: <IconKey />,
      IconPeopleTeam: <IconPeopleTeam />,
      IconSharePoint: <IconSharePoint />,
      IconZapier: <IconZapier />,
      IconEmail: <IconEmail />,
      IconGoogleDrive: <IconGoogleDrive />,
      IconDropbox: <IconDropbox />,
      IconWebhook: <IconWebhook />,

      TagRegular: <TagRegular />,
      DocumentBulletListMultipleRegular: <DocumentBulletListMultipleRegular />,
      ImageCopyRegular: <ImageCopyRegular />,
      CopyRegular: <CopyRegular />,
      Regex: <IconRegex />,
      IconSuccessCircle: <IconSuccessCircle />,
      IconFailCircle: <IconFailCircle />,
      IconWorkspaceUpload: <WorkspaceUpload />,
    },
  });
}
