import React, { useCallback, useEffect } from "react";

import { SUPPORTED_IMAGE_MIME } from "../constants";
import { createOnDrop } from "../utils/file";
import { useToast } from "./toast";

const handleDragEvent = (ev: React.DragEvent) => {
  ev.preventDefault();
  ev.stopPropagation();
};

export function useDragAndDrop(
  onDrop: (e: React.DragEvent) => void,
  onDragOver: (e: React.DragEvent) => void = handleDragEvent,
  onDragLeave: (e: React.DragEvent) => void = handleDragEvent
) {
  useEffect(() => {
    window.addEventListener("drop", onDrop as any);

    return () => {
      window.removeEventListener("drop", onDrop as any);
    };
  }, [onDrop]);

  useEffect(() => {
    window.addEventListener("dragover", onDragOver as any);

    return () => {
      window.removeEventListener("dragover", onDragOver as any);
    };
  }, [onDragOver]);

  useEffect(() => {
    window.addEventListener("dragleave", onDragLeave as any);

    return () => {
      window.removeEventListener("dragleave", onDragLeave as any);
    };
  }, [onDragLeave]);
}

export function useDragAndDropFiles(
  onFiles: (files?: File[]) => void,
  supportedMIME: string[] | undefined = SUPPORTED_IMAGE_MIME,
  maxDepth: number = 10,
  options?: {
    onDrop?: (e: React.DragEvent) => void;
    onDragOver?: (e: React.DragEvent) => void;
    onDragLeave?: (e: React.DragEvent) => void;
    onUnsupportedFileError?: (fileType: string) => void;
  }
) {
  const { onDrop, onDragOver, onDragLeave, onUnsupportedFileError } =
    options ?? {};

  const onDropHandleFile = createOnDrop(
    onFiles,
    supportedMIME,
    maxDepth,
    onUnsupportedFileError
  );
  const _onDrop = useCallback(
    (ev: React.DragEvent) => {
      onDropHandleFile(ev);
      onDrop?.(ev);
    },
    [onDrop, onDropHandleFile]
  );
  return useDragAndDrop(_onDrop, onDragOver, onDragLeave);
}

export function useDragAndDropDefaultOptions() {
  const [isFileOver, setIsFileOver] = React.useState(false);

  const onDrop = React.useCallback((ev: React.DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsFileOver(false);
  }, []);

  const onDragOver = React.useCallback((ev: React.DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsFileOver(true);
  }, []);

  const onDragLeave = React.useCallback((ev: React.DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    // Fix state flickering on chrome
    // Ref: https://stackoverflow.com/questions/12945307/jquery-drag-and-drop-flickering-on-hover-webkit-only
    if (ev.pageX !== 0 || ev.pageY !== 0) {
      return;
    }
    setIsFileOver(false);
  }, []);

  const toast = useToast();

  const onUnsupportedFileError = React.useCallback(
    (fileType: string) => {
      toast.error("error.upload_document_type_unsupported", undefined, {
        fileType,
      });
    },
    [toast]
  );

  const options = React.useMemo(
    () => ({
      onDrop,
      onDragOver,
      onDragLeave,
      onUnsupportedFileError,
    }),
    [onDrop, onDragOver, onDragLeave, onUnsupportedFileError]
  );

  return React.useMemo(
    () => ({
      options,
      isFileOver,
    }),
    [options, isFileOver]
  );
}
