import { PrebuiltTemplatedExtractorDefinition } from "./templatedInstantModelExtractor";

export enum UserFeatureFlag {
  All = "all",
  Alpha = "alpha",
  Beta = "beta",
  CustomModelTraining = "custom_model_training",
  TableExtraction = "table_extraction",
  PostProcessScript = "post_process_script",
  TransformResponseScript = "transform_response_script",
  PII = "pii",
  LLMCompletion = "llm_completion",
  Formatter = "formatter",
  SharePointIntegration = "share_point_integration",
  WebhookExportIntegration = "webhook_export_integration",
  MerchantLogo = "merchant_logo",
  V1TestTab = "v1_test_tab",
  IDLiveness = "id_liveness",
  MoreLLMModels = "more_llm_models",
  InspectPrompt = "inspect_prompt",
  InspectOCR = "inspect_ocr",
  PaymentInfoBeta = "payment_info_beta",
  DetectManipulation = "detect_manipulation",
  DetectDocumentManipulation = "detect_document_manipulation",
  AdvancedPromptTuning = "advanced_prompt_tuning",
  FormVersionSnapshots = "form_version_snapshots",

  WebhookV1 = "webhook_v1",

  // System flag
  AuditLog = "audit_log",
}

export const AlphaFeatureFlags = [
  UserFeatureFlag.LLMCompletion,
  UserFeatureFlag.SharePointIntegration,
  UserFeatureFlag.WebhookExportIntegration,
  UserFeatureFlag.MoreLLMModels,
  UserFeatureFlag.InspectPrompt,
  UserFeatureFlag.TransformResponseScript,
  UserFeatureFlag.DetectManipulation,
  UserFeatureFlag.DetectDocumentManipulation,
  UserFeatureFlag.AdvancedPromptTuning,
];

export const BetaFeatureFlags = [
  UserFeatureFlag.CustomModelTraining,
  UserFeatureFlag.TableExtraction,
  UserFeatureFlag.PostProcessScript,
  UserFeatureFlag.PII,
  UserFeatureFlag.Formatter,
  UserFeatureFlag.MerchantLogo,
  UserFeatureFlag.IDLiveness,
  UserFeatureFlag.PaymentInfoBeta,
  UserFeatureFlag.FormVersionSnapshots,
];

export const OtherFeatureFlags = Object.values(UserFeatureFlag).filter(
  value =>
    !AlphaFeatureFlags.includes(value as UserFeatureFlag) &&
    !BetaFeatureFlags.includes(value as UserFeatureFlag) &&
    value !== UserFeatureFlag.All &&
    value !== UserFeatureFlag.Alpha &&
    value !== UserFeatureFlag.Beta
);

export const RESTRICTED_DOCUMENT_TYPES_FLAGS = new Map([
  // Example:
  // ["invoice", UserFeatureFlag.Invoice],
]) as Map<string, string>;

export const RESTRICTED_AUTO_EXTRACTION_ITEMS_FLAGS = new Map([
  [
    "general",
    new Map([
      ["table_extraction", UserFeatureFlag.TableExtraction],
      ["pii", UserFeatureFlag.PII],
      ["detect_manipulation", UserFeatureFlag.DetectManipulation],
      [
        "detect_document_manipulation",
        UserFeatureFlag.DetectDocumentManipulation,
      ],
    ]),
  ],
  [
    "address_proof",
    new Map([
      [
        "detect_document_manipulation",
        UserFeatureFlag.DetectDocumentManipulation,
      ],
    ]) as Map<string, string>,
  ],
  [
    "invoice",
    new Map([
      ["table_extraction", UserFeatureFlag.TableExtraction],
      [
        "detect_document_manipulation",
        UserFeatureFlag.DetectDocumentManipulation,
      ],
    ]),
  ],
  [
    "international_id",
    new Map([
      ["id_liveness", UserFeatureFlag.IDLiveness],
      [
        "detect_document_manipulation",
        UserFeatureFlag.DetectDocumentManipulation,
      ],
    ]),
  ],
  [
    "receipt",
    new Map([
      ["merchant_logo", UserFeatureFlag.MerchantLogo],
      ["payment_info_beta", UserFeatureFlag.PaymentInfoBeta],
      ["detect_manipulation", UserFeatureFlag.DetectManipulation],
      [
        "detect_document_manipulation",
        UserFeatureFlag.DetectDocumentManipulation,
      ],
    ]),
  ],

  [
    "bank_statement",
    new Map([
      ["detect_manipulation", UserFeatureFlag.DetectManipulation],
      [
        "detect_document_manipulation",
        UserFeatureFlag.DetectDocumentManipulation,
      ],
    ]),
  ],
  [
    "invoice_llm",
    new Map([
      [
        "detect_document_manipulation",
        UserFeatureFlag.DetectDocumentManipulation,
      ],
    ]),
  ],
  [
    "bank_statement_llm",
    new Map([
      [
        "detect_document_manipulation",
        UserFeatureFlag.DetectDocumentManipulation,
      ],
    ]),
  ],
  [
    "bill_of_lading_llm",
    new Map([
      [
        "detect_document_manipulation",
        UserFeatureFlag.DetectDocumentManipulation,
      ],
    ]),
  ],
]);

export const RESTRICTED_DETECTION_REGION_FIELD_TYPE_FLAGS = new Map([
  ["table", UserFeatureFlag.TableExtraction],
  ["llm-completion", UserFeatureFlag.LLMCompletion],
]);

export const RESTRICTED_GENERIC_AUTO_EXTRACTION_ITEMS_FLAGS = new Map([
  ["llm_completion", UserFeatureFlag.LLMCompletion],
]);

export const RESTRICTED_PREBUILT_EXTRACTOR_FLAGS = new Map<string, string>([
  // Example:
  // ["invoice_llm", UserFeatureFlag.PreviewExtractor],
]);

export const USER_FEATURE_FLAGS_UNDER_TEST = [
  UserFeatureFlag.WebhookExportIntegration,
  UserFeatureFlag.SharePointIntegration,
];

export const CREATABLE_PREBUILT_TEMPLATED_EXTRACTORS = [
  "bank_statement_llm",
  "invoice_llm",
  "receipt",
  "gov_id_passport",
  "address_proof",
  "business_registration",
  "bill_of_lading_llm",
  "air_waybill_llm",
  "purchase_order_llm",
  "quotation_llm",
  "resume_llm",
] as (keyof typeof PrebuiltTemplatedExtractorDefinition)[];
