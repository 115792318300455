import * as yup from "yup";

const matchMode = yup.string().oneOf(["all", "best"]).defined();

export const tokenGroupTextSchema = yup
  .object({
    id: yup.string().defined(),
    value: yup.string().defined(),
    label: yup.string().optional().nullable(),
    isExactMatchOnly: yup.boolean().defined(),
    isIgnoreWhiteSpace: yup.boolean().defined(),
  })
  .transform(x => {
    const { label, ...rest } = x;
    return {
      ...rest,
      label: label === null ? undefined : label,
    };
  })
  .shape({
    label: yup.string().optional(),
  })
  .camelCase();

export const tokenGroupImageSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
    url: yup.string().defined(),
    assetId: yup.string().defined(),
  })
  .camelCase();

const tokenGroupType = yup.string().oneOf(["texts", "images"]).defined();

export const tokenGroupSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
    images: yup.array(tokenGroupImageSchema).defined(),
    matchMode: matchMode.defined(),
    tokenType: tokenGroupType.optional(),
    texts: yup.array(tokenGroupTextSchema).defined(),
    created_at: yup.number().defined(),
  })
  .camelCase()
  .transform((value, _original) => {
    const { created_at, ...rest } = value;
    return {
      ...rest,
      created_at: created_at || 0,
    };
  });

export type TokenGroupText = yup.InferType<typeof tokenGroupTextSchema>;
export type TokenGroupImage = yup.InferType<typeof tokenGroupImageSchema>;
export type MatchMode = yup.InferType<typeof matchMode>;
export type TokenGroupType = yup.InferType<typeof tokenGroupType>;
export type TokenGroup = yup.InferType<typeof tokenGroupSchema>;

export type TokenGroupTextResp = Omit<
  TokenGroupText,
  "isExactMatchOnly" | "isIgnoreWhiteSpace"
> & {
  is_exact_match_only: boolean;
  is_ignore_white_space: boolean;
};

export type TokenGroupImageResp = Omit<TokenGroupImage, "assetId"> & {
  asset_id: string;
};
export type TokenGroupImageRequest = Omit<TokenGroupImageResp, "url">;

export type TokenGroupResp = Pick<TokenGroup, "id" | "name"> & {
  images: TokenGroupImageResp[];
  match_mode: MatchMode;
  token_type?: TokenGroupType;
  texts: TokenGroupTextResp[];
  created_at: number;
};

export type TokenGroupRequest = Omit<TokenGroupResp, "images"> & {
  images: TokenGroupImageRequest[];
};
