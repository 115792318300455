@import "../../styles/variables.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #edebe9;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.navigatorContainer {
  display: flex;
  gap: 8px;
}

.navigator {
  font-size: 14px;
  line-height: 20px;
  font-weight: 800;
  color: #323130;
  padding: 10px;
}

.resultAssetContainer {
  height: 100%;
  border-right: 1px solid #edebe9;
  padding: 15px 20px 20px 20px;
  display: flex;
  flex-direction: column;
}

.imageResultContainer {
  width: 500px;
  height: 100%;
  padding: 20px;
}

.viewOptionButtonGroup {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.viewOptionLabel {
  font-size: 12px;
  line-height: 20px;
}

.resultAssetJsonViewerContainer {
  width: 100%;
  margin-bottom: 10px;
  background-color: #faf9f8;
  border: 1px solid #edebe9;
  border-radius: 4px;
  padding: 20px;
  flex: 1;
}

.resultAssetViewerContainer {
  flex: 1 1 0;
  overflow-y: auto;
}

.resultAssetJson {
  font-family: Menlo, sans-serif;
  font-size: 14px;
  white-space: pre-wrap;
}

.resultAssetJsonContainer {
  flex: 1 1 0;
  overflow-y: auto;
}
.imageAssetSectionContainer {
  flex: 1;
  background-color: #faf9f8;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.imageContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.imageAssetContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-viewer {
  width: 100%;
  height: 100%;
}

.imageAssetNotFoundText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #605e5c;
  width: 45%;
  min-width: 195px;
  margin: auto;
}

.documentNotFoundInfoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.contactUsLink {
  color: green;
}

.goToExtractorBlockDescBoldText {
  color: #323130;
  font-weight: 700;
}

.extractionFailedPlaceholder {
  border-radius: 4px;
  border: 1px solid #edebe9;
  background: #faf9f8;
  padding: 10px;
  color: #323130;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.notFoundText {
  color: var(--type-colors-secondary, #605e5c);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  white-space: pre-wrap;
  :global(.ms-Link) {
    color: var(--theme-primary, #0078d4) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 150% !important;
    &:visited {
      color: var(--theme-primary, #0078d4) !important;
    }
  }
}

.retryButton {
  margin-left: 4px;
  all: unset;
  cursor: pointer;
  color: #0078d4;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:hover {
    opacity: 75%;
  }
  &:active {
    opacity: 50%;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.loadingText {
  color: var(--Primary-Color, #25d0b1);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
