import cn from "classnames";
import produce from "immer";
import * as React from "react";

import { useStateRef } from "../../hooks/common";
import { RenderDocument } from "../../types/document";
import { OverlayZoomControl } from "../OverlayZoomControl";
import styles from "./styles.module.scss";

const SCALE_STEP = 0.2;
const VERTICAL_GAP = 20;
const VIEWPORT_MARGIN = 20;

enum ZoomOps {
  ZoomIn,
  ZoomOut,
}

type ImageFrameProps = {
  src: string | RenderDocument;
  scale: number;
  imageWidth: number;
  imageHeight: number;
  frameWidth: number;
  frameHeight: number;
  setImageSize: (width: number, height: number) => void;
  boundingBoxes?: number[][];
};

function letterboxing(
  containerWidth: number,
  containerHeight: number,
  imageWidth: number,
  imageHeight: number
) {
  const widthRatio = containerWidth / imageWidth;
  const heightRatio = containerHeight / imageHeight;
  const scale = Math.min(widthRatio, heightRatio);

  const width = imageWidth * scale;
  const height = imageHeight * scale;
  const left = (containerWidth - width) / 2;
  const top = (containerHeight - height) / 2;

  return {
    width,
    height,
    left,
    top,
    scale,
  };
}

function scaleToWidth(
  containerWidth: number,
  imageWidth: number,
  imageHeight: number
) {
  const scale = containerWidth / imageWidth;

  return {
    width: containerWidth,
    height: imageHeight * scale,
    scale,
  };
}

function BottomOverlayArea(props: ReturnType<typeof useImageViewerState>) {
  return (
    <div className={styles["bottom-overlay-area"]}>
      {props.zoomControlEnabled && (
        <OverlayZoomControl
          onScaleToFitClick={props.scaleToFit}
          onZoomInClick={props.zoomIn}
          onZoomOutClick={props.zoomOut}
        />
      )}
      {props.bottomOverlay ?? null}
    </div>
  );
}

function useDragToScroll(element: HTMLDivElement | null) {
  const [counter, setCounter] = React.useState(0);

  // Force to refresh the event listener
  const reinstallEventListener = React.useCallback(() => {
    setCounter(prev => prev + 1);
  }, []);

  React.useEffect(() => {
    if (!element) {
      return;
    }

    let isDown = false;
    let startX = -1;
    let startY = -1;

    const onMouseDown = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      isDown = true;
      startY = event.pageY - element.offsetTop;
      startX = event.pageX - element.offsetLeft;
    };

    const onMouseUp = (event: MouseEvent) => {
      if (!isDown) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      isDown = false;
    };

    const onMouseMove = (event: MouseEvent) => {
      if (!isDown) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      const y = event.pageY - element.offsetTop;
      const x = event.pageX - element.offsetLeft;
      const diffY = y - startY;
      const diffX = x - startX;
      element.scrollTop = element.scrollTop - diffY;
      element.scrollLeft = element.scrollLeft - diffX;
      startY = y;
      startX = x;
    };

    const onMouseLeave = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      isDown = false;
    };

    element.addEventListener("mousemove", onMouseMove);
    element.addEventListener("mousedown", onMouseDown);
    element.addEventListener("mouseup", onMouseUp);
    element.addEventListener("mouseleave", onMouseLeave);

    return () => {
      element?.removeEventListener("mousedown", onMouseDown);
      element?.removeEventListener("mouseup", onMouseUp);
      element?.removeEventListener("mousemove", onMouseMove);
      element?.removeEventListener("mouseleave", onMouseLeave);
    };
  }, [element, counter]);

  return reinstallEventListener;
}

/// ImageFrame - Render a single image/document

function ImageFrame(props: ImageFrameProps) {
  const {
    src,
    setImageSize,
    scale,
    frameWidth,
    frameHeight,
    boundingBoxes,
    imageWidth,
    imageHeight,
  } = props;

  const containerRef = React.useRef<HTMLDivElement>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const url = React.useMemo(() => {
    if (typeof src === "string") {
      return src;
    } else {
      // RenderDocument
      return src?.url;
    }
  }, [src]);

  const rotation = React.useMemo(() => {
    if (typeof src === "string") {
      return 0;
    } else {
      // RenderDocument
      const inputRotation = src?.rotation ?? 0;
      return inputRotation % 90 === 0 ? inputRotation : 0;
    }
  }, [src]);

  const texts = React.useMemo(() => {
    if (typeof src === "string") {
      return [];
    } else {
      // CustomizableImage
      return src?.texts ?? [];
    }
  }, [src]);

  const onImageLoad = React.useCallback(
    (event: React.SyntheticEvent<HTMLImageElement>) => {
      const image = event.currentTarget;
      const switchWidthHeight = rotation === 90 || rotation === 270;

      const imageWidth = switchWidthHeight ? image.height : image.width;
      const imageHeight = switchWidthHeight ? image.width : image.height;
      setImageSize(imageWidth, imageHeight);
    },
    [rotation, setImageSize]
  );

  React.useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        if (boundingBoxes) {
          ctx.strokeStyle = "red";
          ctx.lineWidth = 2;
          boundingBoxes.forEach(box => {
            const [left, top, right, bottom] = box;
            const x = left * imageWidth;
            const y = top * imageHeight;
            const width = (right - left) * imageWidth;
            const height = (bottom - top) * imageHeight;
            ctx.strokeRect(x, y, width, height);
          });
        }
      }
    }
  }, [imageWidth, imageHeight, boundingBoxes, canvasRef]);

  const transform = `scale(${scale}) rotate(${rotation}deg)`;

  containerRef.current?.style.setProperty("width", `${frameWidth}px`);
  containerRef.current?.style.setProperty("height", `${frameHeight}px`);

  const isImageVisible = frameWidth !== 0 && frameHeight !== 0;

  return (
    <div ref={containerRef} className={styles["image-view-container"]}>
      <div className={styles["image-view-content"]}>
        <img
          className={styles["image-view-img"]}
          alt="asset image"
          src={url}
          onLoad={onImageLoad}
          style={{
            display: isImageVisible ? "block" : "none",
            transform,
          }}
        />
        {boundingBoxes && (
          <canvas
            className={cn(
              "absolute",
              "pointer-events-none",
              styles["image-view-img"]
            )}
            ref={canvasRef}
            style={{
              display: isImageVisible ? "block" : "none",
              transform,
            }}
          />
        )}
      </div>
      <div className={styles["image-view-overlay"]}>
        {frameWidth > 0 &&
          frameHeight > 0 &&
          texts.map((text, index) => {
            const [left, top, right, bottom] = text.boundingBox;
            const width = (right - left) * frameWidth;
            const height = (bottom - top) * frameHeight;
            const x = left * frameWidth;
            const y = top * frameHeight;
            return (
              <div
                key={index}
                className={styles["image-view-text-area"]}
                style={{
                  width,
                  height,
                  left: x,
                  top: y,
                }}
              ></div>
            );
          })}
      </div>
    </div>
  );
}

function SingleImageFrame(
  props: ReturnType<typeof useImageViewerState> & {
    imageFrame: ImageFrameProps;
  }
) {
  const scrollableRef = props.scrollableViewRef;
  const viewportRef = props.viewportRef;
  const { scale, componentWidth } = props;

  React.useEffect(() => {
    props.reinstallDragEventListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transform = `scale(${scale})`;

  const scrollableClasses = cn(styles["single-image-frame-scrollable"], {
    [styles["grab-mouse-cursor"]]: props.isScaling,
  });

  return (
    <div className={styles["single-image-frame"]}>
      <div ref={scrollableRef} className={scrollableClasses}>
        <div
          ref={viewportRef}
          className={styles["single-image-frame-viewport"]}
        >
          {scrollableRef.current && componentWidth > 0 && (
            <div
              className={styles["single-image-frame-content"]}
              style={{
                transform,
              }}
            >
              <ImageFrame
                {...props.imageFrame}
                boundingBoxes={props.boundingBoxes}
              />
            </div>
          )}
        </div>
      </div>
      <BottomOverlayArea {...props} />
    </div>
  );
}

function MultiImageFrame(props: ReturnType<typeof useImageViewerState>) {
  const { imageFrames, scale, componentWidth } = props;
  const containerRef = props.componentRef;
  const scrollableRef = props.scrollableViewRef;
  const viewportRef = props.viewportRef;
  const contentListRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    props.reinstallDragEventListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transform = `scale(${scale})`;

  const scrollableClasses = cn(styles["multiple-image-scrollable-view"], {
    [styles["grab-mouse-cursor"]]: props.isScaling,
  });

  return (
    <div
      className={cn(styles["multiple-image-container"], props.className)}
      ref={containerRef}
    >
      <div className={styles["multiple-image-content"]}>
        <div className={scrollableClasses} ref={scrollableRef}>
          <div className={styles["multiple-image-viewport"]} ref={viewportRef}>
            {componentWidth > 0 && (
              <div
                className={styles["multiple-image-content-list"]}
                style={{ transform }}
                ref={contentListRef}
              >
                {imageFrames.map((image, index) => {
                  return <ImageFrame key={index} {...image} />;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <BottomOverlayArea {...props} />
    </div>
  );
}

type Props = {
  className?: string;
  src: string[] | string | RenderDocument[];
  bottomOverlay?: React.ReactNode;
  zoomControlEnabled?: boolean;
  boundingBoxes?: number[][];
};

export function useImageViewerState(props: Props) {
  const { src, className, bottomOverlay, boundingBoxes } = props;
  const [scale, setScale, scaleRef] = useStateRef(1);
  const zoomControlEnabled = props.zoomControlEnabled ?? false;

  const componentRef = React.useRef<HTMLDivElement>(null);
  const scrollableViewRef = React.useRef<HTMLDivElement>(null);
  const viewportRef = React.useRef<HTMLDivElement>(null);

  // Cached component width and height
  // - Scale image according to the cached value
  // - Obtain the value before loading the image(s)
  // - It may changed by scaleToFit and image loaded event
  const [componentWidth, setComponentWidth, componentWidthRef] = useStateRef(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_componentHeight, setComponentHeight, componentHeightRef] =
    useStateRef(0);

  // The content width and height
  // SingleImageFrame - The component width and height
  // MultiImageFrame - The component width and sum of all scaled image height
  const [contentWidth, setContentWidth, contentWidthRef] = useStateRef(0);
  const [contentHeight, setContentHeight, contentHeightRef] = useStateRef(0);

  const reinstallDragEventListener = useDragToScroll(scrollableViewRef.current);

  const updateComponentSize = React.useCallback(() => {
    if (!componentRef.current) {
      return;
    }
    const newComponentWidth = componentRef.current.clientWidth;
    const newComponentHeight = componentRef.current.clientHeight;

    setComponentWidth(componentRef.current.clientWidth);
    setComponentHeight(componentRef.current.clientHeight);

    return {
      newComponentWidth,
      newComponentHeight,
    };
  }, [setComponentWidth, setComponentHeight]);

  const imageSource = React.useMemo(() => {
    if (Array.isArray(src)) {
      return src;
    }

    return [src];
  }, [src]);

  const isSingleImage = React.useRef(imageSource.length === 1);

  const setContentSize = React.useCallback(
    (width: number, height: number) => {
      setContentWidth(width);
      setContentHeight(height);
    },
    [setContentWidth, setContentHeight]
  );

  const resizeViewport = React.useCallback(
    (width: number, height: number, scale: number) => {
      if (
        !viewportRef.current ||
        width <= 0 ||
        height <= 0 ||
        !scrollableViewRef.current
      ) {
        return;
      }

      const newWidth = Math.max(
        width * scale,
        scrollableViewRef.current.clientWidth
      );
      const newHeight = Math.max(
        height * scale,
        scrollableViewRef.current.clientHeight
      );

      viewportRef.current.style.setProperty("width", `${newWidth}px`);
      viewportRef.current.style.setProperty("height", `${newHeight}px`);
    },
    []
  );
  const onSubImageLoad = React.useCallback(
    (index: number, imageWidth: number, imageHeight: number) => {
      let imageScale = 0;
      let frameWidth = 0;
      let frameHeight = 0;

      updateComponentSize();

      if (isSingleImage.current) {
        // Single image frame
        const { scale, width, height } = letterboxing(
          componentWidthRef.current - VIEWPORT_MARGIN * 2,
          componentHeightRef.current - VIEWPORT_MARGIN * 2,
          imageWidth,
          imageHeight
        );

        setContentSize(width, height);

        frameWidth = width;
        frameHeight = height;
        imageScale = scale;

        resizeViewport(width, height, scaleRef.current);
      } else {
        const { scale, width, height } = scaleToWidth(
          componentWidthRef.current - VIEWPORT_MARGIN * 2,
          imageWidth,
          imageHeight
        );

        const newHeight =
          contentHeightRef.current === 0
            ? height
            : contentHeightRef.current + height + VERTICAL_GAP;

        setContentSize(width, newHeight);
        frameWidth = width;
        frameHeight = height;
        imageScale = scale;

        resizeViewport(width, newHeight, scaleRef.current);
      }

      setImageFrames(prev =>
        produce(prev, draft => {
          draft[index].scale = imageScale;
          draft[index].imageWidth = imageWidth;
          draft[index].imageHeight = imageHeight;
          draft[index].frameWidth = frameWidth;
          draft[index].frameHeight = frameHeight;
          return draft;
        })
      );
    },
    [
      updateComponentSize,
      componentWidthRef,
      componentHeightRef,
      isSingleImage,
      resizeViewport,
      setContentSize,
      contentHeightRef,
      scaleRef,
    ]
  );

  const [imageFrames, setImageFrames] = React.useState<ImageFrameProps[]>(
    imageSource.map((item, index) => {
      const src = typeof item === "string" ? { url: item } : item;
      return {
        src,
        scale: 1,
        imageWidth: 0,
        imageHeight: 0,
        frameWidth: 0,
        frameHeight: 0,
        setImageSize: (width: number, height: number) => {
          onSubImageLoad(index, width, height);
        },
      };
    })
  );

  const scaleToFit = React.useCallback(() => {
    if (componentRef.current === null) {
      return;
    }
    setScale(1);
    const currentComponentWidth = componentRef.current.clientWidth;
    const currentComponentHeight = componentRef.current.clientHeight;
    setComponentWidth(currentComponentWidth);
    setComponentHeight(currentComponentHeight);

    if (isSingleImage.current) {
      const { scale, width, height } = letterboxing(
        currentComponentWidth - VIEWPORT_MARGIN * 2,
        currentComponentHeight - VIEWPORT_MARGIN * 2,
        imageFrames[0].imageWidth,
        imageFrames[0].imageHeight
      );

      setContentSize(width, height);
      resizeViewport(width, height, scaleRef.current);

      setImageFrames(prev =>
        produce(prev, draft => {
          draft[0].scale = scale;
          draft[0].frameWidth = width;
          draft[0].frameHeight = height;
          return draft;
        })
      );
    } else {
      const contentWidth = currentComponentWidth;

      const patches = imageFrames.map(image => {
        const { scale, width, height } = scaleToWidth(
          componentWidthRef.current - VIEWPORT_MARGIN * 2,
          image.imageWidth,
          image.imageHeight
        );
        return { scale, width, height };
      });

      const contentHeight =
        patches.reduce((acc, patch) => {
          return acc + patch.height;
        }, 0) +
        (imageFrames.length - 1) * VERTICAL_GAP;

      setContentSize(contentWidth, contentHeight);
      resizeViewport(contentWidth, contentHeight, scaleRef.current);

      setImageFrames(prev => {
        return produce(prev, draft => {
          draft.forEach((image, index) => {
            image.scale = patches[index].scale;
            image.frameWidth = patches[index].width;
            image.frameHeight = patches[index].height;
          });
        });
      });
    }
  }, [
    setScale,
    resizeViewport,
    isSingleImage,
    imageFrames,
    componentWidthRef,
    setComponentWidth,
    setComponentHeight,
    setContentSize,
    scaleRef,
  ]);

  const getCenter = React.useCallback(() => {
    if (!scrollableViewRef.current) {
      return { x: 0, y: 0 };
    }

    const element = scrollableViewRef.current;
    const x =
      (element.scrollLeft + element.clientWidth / 2) / element.scrollWidth;
    const y =
      (element.scrollTop + element.clientHeight / 2) / element.scrollHeight;
    return { x, y };
  }, []);

  const scrollToCenter = React.useCallback((x: number, y: number) => {
    if (!scrollableViewRef.current) {
      return;
    }

    const element = scrollableViewRef.current;
    const scrollLeft = element.scrollWidth * x - element.clientWidth / 2;
    const scrollTop = element.scrollHeight * y - element.clientHeight / 2;

    element.scrollLeft = scrollLeft;
    element.scrollTop = scrollTop;
  }, []);

  // A simple debouncer for zoom operation to prevent zooming too fast
  const [nextZoomOp, setNextZoomOp] = React.useState<ZoomOps | undefined>();

  const zoomIn = React.useCallback(() => {
    setNextZoomOp(ZoomOps.ZoomIn);
  }, []);

  const zoomOut = React.useCallback(() => {
    setNextZoomOp(ZoomOps.ZoomOut);
  }, []);

  const zoom = React.useCallback(
    (op: ZoomOps) => {
      let newScale =
        op === ZoomOps.ZoomIn
          ? scaleRef.current + SCALE_STEP
          : scaleRef.current - SCALE_STEP;
      if (newScale <= 1) {
        newScale = 1;
      }
      const { x, y } = getCenter();
      setScale(newScale);
      resizeViewport(contentWidth, contentHeight, newScale);
      scrollToCenter(x, y);
    },
    [
      setScale,
      scaleRef,
      getCenter,
      resizeViewport,
      contentWidth,
      contentHeight,
      scrollToCenter,
    ]
  );

  React.useEffect(() => {
    if (nextZoomOp === undefined) {
      return;
    }
    zoom(nextZoomOp);
    setNextZoomOp(undefined);
  }, [nextZoomOp, zoom]);

  React.useEffect(() => {
    if (!componentRef.current) {
      return;
    }

    setComponentWidth(componentRef.current.clientWidth);
    setComponentHeight(componentRef.current.clientHeight);

    const element = componentRef.current;

    const onWheel = (event: WheelEvent) => {
      if (event.ctrlKey) {
        event.preventDefault();
        event.stopPropagation();
        if (event.deltaY > 0) {
          zoomOut();
        } else {
          zoomIn();
        }
      }
    };

    const listenOnWheel = zoomControlEnabled;

    if (listenOnWheel) {
      element.addEventListener("wheel", onWheel);
    }

    const observer = new ResizeObserver(() => {
      resizeViewport(
        contentWidthRef.current,
        contentHeightRef.current,
        scaleRef.current
      );
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
      if (listenOnWheel) {
        element.removeEventListener("wheel", onWheel);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isScaling =
    scrollableViewRef.current !== null &&
    viewportRef.current !== null &&
    (scrollableViewRef.current.clientWidth < viewportRef.current.clientWidth ||
      scrollableViewRef.current.clientHeight <
        viewportRef.current.clientHeight);

  return React.useMemo(
    () => ({
      imageFrames,
      className,
      bottomOverlay,
      scale,
      zoomControlEnabled,
      zoomIn,
      zoomOut,
      scaleToFit,
      scrollableViewRef,
      reinstallDragEventListener,
      setContentSize,
      viewportRef,
      isScaling,
      componentRef,
      componentWidth,
      boundingBoxes,
    }),
    [
      imageFrames,
      className,
      bottomOverlay,
      scale,
      zoomControlEnabled,
      zoomIn,
      zoomOut,
      scaleToFit,
      scrollableViewRef,
      reinstallDragEventListener,
      setContentSize,
      viewportRef,
      isScaling,
      componentRef,
      componentWidth,
      boundingBoxes,
    ]
  );
}

export function ImageViewer(props: Props) {
  const { src } = props;
  const [contentKey, setContentKey] = React.useState(0);

  const url = React.useMemo(() => {
    if (Array.isArray(src)) {
      return src
        .map(item => {
          if (typeof item === "string") {
            return item;
          } else {
            return item?.url ?? "";
          }
        })
        .join("");
    }
    return src;
  }, [src]);

  React.useEffect(() => {
    setContentKey(prev => prev + 1);
  }, [url]);

  return <ImageViewerContainer {...props} key={contentKey} />;
}

export function ImageViewerContainer(props: Props) {
  const states = useImageViewerState(props);

  return <ImageViewerImpl {...states} />;
}

export function ImageViewerImpl(props: ReturnType<typeof useImageViewerState>) {
  const { imageFrames, componentRef } = props;

  if (imageFrames.length === 1) {
    return (
      <div
        ref={componentRef}
        className={cn(styles["container"], props.className)}
      >
        <SingleImageFrame {...props} imageFrame={imageFrames[0]} />
      </div>
    );
  }

  return (
    <div
      ref={componentRef}
      className={cn(styles["container"], props.className)}
    >
      <MultiImageFrame {...props} />
    </div>
  );
}
