@import "../../styles/variables.scss";

.container {
  height: 100%;
  padding: 12px 20px;

  display: flex;
  flex-direction: column;

  .content {
    margin: 40px auto;
    width: 100%;
    max-width: 480px;

    .sub-section {
      display: flex;
      flex-direction: column;

      margin-bottom: 4px;

      .field {
        margin-bottom: 24px;
      }

      .pre-trained-extractor-list {
        border: 1px solid $neutral-light;

        :global(.ms-ChoiceField-wrapper) {
          width: 100%;
        }

        :global(.ms-ChoiceField) {
          margin: 0;
          padding: 10px;

          &:nth-child(odd) {
            background-color: #f5f8ff;
          }

          &:nth-child(even) {
            background-color: white;
          }
        }

        :global(.ms-ChoiceFieldLabel) {
          padding-left: 40px;
        }
      }
    }
  }
}

.extractor-connection-description {
  display: block;
  color: var(--type-colors-primary, #323130);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.extractor-list-title {
  margin-top: 16;
  margin-bottom: 6;

  color: var(--type-colors-primary, #323130);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.switch-connection-button {
  margin-top: 10px;
  align-self: flex-end;
  height: 20px;
  padding: unset;

  :global(.ms-Button-label) {
    margin: unset;
    color: #0078d4;
    opacity: 1;
    &:hover {
      opacity: 0.7;
    }
    &:active {
      opacity: 0.5;
    }
  }
}

.step-title {
  margin-bottom: 6px;

  color: var(--grey-palette-grey-190, #201f1e);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.icon.fixed-layout {
  background-color: #ff6d7d;
}

.icon.custom-model {
  background-color: #25d0b1;
}
.icon.prebuilt {
  background-color: #6888fa;
}

.extractor-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  :global(.ms-ChoiceField-field) {
    flex: 1 1 0%;
  }
  :global(.ms-ChoiceFieldLabel) {
    word-break: break-all;
  }
}

.extractor-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 6px;
  width: 35%;
}

.extractor-icon {
  width: 20px;
  height: 20px;
  border-radius: 20px;

  margin-right: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 12px;
    height: 12px;
  }

  &.combined {
    background-color: #faae68;
    color: #ffffff;
  }
  &.fixed-layout {
    background-color: #ff6d7d;
    color: #ffffff;
  }
  &.custom-model {
    background-color: #25d0b1;
    color: #ffffff;
  }
}

.extractor-type-text {
  color: var(--type-colors-secondary, #605e5c);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}
