import {
  ActionButton,
  DefaultButton,
  Dialog,
  DialogType,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router";
import { unstable_useBlocker as useBlocker } from "react-router-dom";

import { useFormEditor } from "../../contexts/formEditor";
import { useLocale } from "../../contexts/locale";
import LoadingModal from "../LoadingModal";
import styles from "./styles.module.scss";

function useFormNotSavedPrompt() {
  const { isFormNotSaved, form, saveFormThenShowToast, discardForm } =
    useFormEditor();

  const { localized } = useLocale();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [nextPath, setNextPath] = useState<string>("");
  const [allowedPath, setAllowedPath] = useState<string>("");
  const [saveBeforeNextPath, setSaveBeforeNextPath] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const checkEditingPath = useCallback(
    (pathname: string) => {
      if (form === undefined) {
        return false;
      }

      // Editing path include editor(FormEditor) and formatter(WorkflowEditor)
      const rx = new RegExp(`^/form/${form.id}/(edit|format)`);
      return pathname.match(rx) !== null;
    },
    [form]
  );

  const { isInEditingPath, promptMessage } = useMemo(() => {
    const isInEditingPath = checkEditingPath(pathname);
    const promptMessage = localized(
      "form_editor.form_not_saved_prompt.save_warning"
    );

    return {
      isInEditingPath,
      promptMessage,
    };
  }, [checkEditingPath, pathname, localized]);

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
    setNextPath("");
  }, [setIsDialogOpen, setNextPath]);

  const closeDialogThenNavToNextPath = useCallback(() => {
    setIsDialogOpen(false);
    setAllowedPath(nextPath);
  }, [nextPath]);

  const saveFormThenNavToNextPath = useCallback(() => {
    setIsDialogOpen(false);
    setAllowedPath(nextPath);
    setSaveBeforeNextPath(true);
  }, [nextPath]);

  const onDialogDismissed = useCallback(() => {
    if (nextPath === "") {
      return;
    }

    if (!saveBeforeNextPath) {
      discardForm();
      navigate(nextPath);
      return;
    }

    setIsSaving(true);
    saveFormThenShowToast()
      .then(() => {
        navigate(nextPath);
      })
      .catch(() => {
        setAllowedPath("");
        setNextPath("");
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [
    nextPath,
    navigate,
    saveBeforeNextPath,
    saveFormThenShowToast,
    discardForm,
  ]);

  useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent): void | string => {
      if (isInEditingPath && isFormNotSaved) {
        // Most modern browsers ignore this message
        event.returnValue = promptMessage;
        return promptMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, [localized, isInEditingPath, isFormNotSaved, promptMessage]);

  const blocker = useCallback(
    (args: { nextLocation: Location }) => {
      const { nextLocation } = args;

      const nextPathname = nextLocation.pathname;

      const notAllowed =
        isInEditingPath &&
        isFormNotSaved &&
        nextPathname !== pathname &&
        nextPathname !== allowedPath;

      if (notAllowed) {
        setSaveBeforeNextPath(false);
        setIsDialogOpen(true);
        setNextPath(nextPathname);
        return true;
      }

      setAllowedPath("");
      return false;
    },
    [isInEditingPath, isFormNotSaved, allowedPath, pathname]
  );

  useBlocker(blocker);

  return {
    isDialogOpen,
    closeDialog,
    onDialogDismissed,
    closeDialogThenNavToNextPath,
    saveFormThenNavToNextPath,
    isSaving,
  };
}

const FormNotSavedPrompt = () => {
  const {
    isDialogOpen,
    closeDialog,
    closeDialogThenNavToNextPath,
    saveFormThenNavToNextPath,
    onDialogDismissed,
    isSaving,
  } = useFormNotSavedPrompt();
  const { localized } = useLocale();

  const modalProps: IModalProps = useMemo(
    () => ({
      isBlocking: true,
      onDismissed: onDialogDismissed,
      className: styles["form-non-saved-prompt-dialog"],
    }),
    [onDialogDismissed]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("form_editor.form_not_saved_prompt.title"),
      subText: localized("form_editor.form_not_saved_prompt.save_warning"),
    }),
    [localized]
  );

  return (
    <div>
      <Dialog
        minWidth={405}
        hidden={!isDialogOpen}
        onDismiss={closeDialog}
        modalProps={modalProps}
        dialogContentProps={dialogContentProps}
      >
        <div className={styles["button-actions"]}>
          <ActionButton
            onClick={closeDialog}
            className={styles["cancel-button"]}
            text={localized("common.cancel")}
          />
          <DefaultButton
            onClick={closeDialogThenNavToNextPath}
            className={styles["dont-save-button"]}
            text={localized("form_editor.form_not_saved_prompt.dont_save")}
          />
          <PrimaryButton
            onClick={saveFormThenNavToNextPath}
            text={localized("form_editor.form_not_saved_prompt.save_changes")}
          />
        </div>
      </Dialog>
      <LoadingModal
        messageId="form_editor.form_not_saved_prompt.saving"
        isOpen={isSaving}
      />
    </div>
  );
};

export default FormNotSavedPrompt;
