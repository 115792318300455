import { Text, isMac } from "@fluentui/react";
import {
  CompactSelection,
  DataEditor,
  DataEditorRef,
  EditableGridCell,
  GridCell,
  GridCellKind,
  GridColumn,
  GridKeyEventArgs,
  GridMouseEventArgs,
  GridSelection,
  Item,
  Rectangle,
} from "@glideapps/glide-data-grid";
import {
  FormattedMessage,
  FormattedMessageProps,
} from "@oursky/react-messageformat";
import classNames from "classnames";
import { cloneDeep, escapeRegExp } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Arrow, IBounds, useLayer } from "react-laag";

import { useLocale } from "../../contexts/locale";
import {
  CellData,
  CellDataType,
  Header,
  HeaderGroupKey,
  InternalCellDataType,
  RowData,
  SettingCellData,
  SettingConfig,
  TableCellData,
  TableRowData,
  TagAutoCompleteCellData,
  TagCellData,
  TagKey,
  TokenCellData,
  isCellData,
} from "../../types/advancedTokenSetup/table";
import { matchEventKey } from "../../utils/key";
import { normalizeCollationDiacritic } from "../../utils/string";
import AdvanceTokenSetupFieldReplacementSettingPanel, {
  FieldReplacementOption,
  OptionData,
} from "../AdvanceTokenSetupFieldReplacementSettingPanel";
import { customCellRenders } from "./Cells";
import { AutoCompleteCell, isAutoCompleteCell } from "./Cells/AutoCompleteCell";
import { DeleteCell, isDeleteCell } from "./Cells/DeleteCell";
import { EditCell, icons, isEditCell } from "./Cells/EditCell";
import { EditTagCell, isEditTagCell } from "./Cells/EditTagCell";
import { RowOrderCell } from "./Cells/RowOrderCell";
import { SettingCell } from "./Cells/SettingCell";
import { contains } from "./Cells/Utils/math";
import config from "./config";
import { customHeaderIcons } from "./headerIcons";
import styles from "./styles.module.scss";
import { baseTheme, getHeaderGroupTheme, getHeaderTheme } from "./theme";

export interface AdvanceTokenSetupHint {
  title: FormattedMessageProps;
  description: FormattedMessageProps;
}

interface Tooltip {
  content: FormattedMessageProps | string;
  bounds: IBounds;
}

const zeroBounds: IBounds = {
  left: 0,
  right: 0,
  width: 0,
  height: 0,
  bottom: 0,
  top: 0,
};

export enum Ordering {
  priorityAsc,
  priorityDesc,
  lastModifiedAsc,
  lastModifiedDesc,
}

export enum ImportCSVStrategy {
  replace,
  append,
}

const customSortingFuncs: Record<Ordering, (a: RowData, b: RowData) => number> =
  {
    [Ordering.priorityAsc]: (a, b) => b.order - a.order,
    [Ordering.priorityDesc]: (a, b) => a.order - b.order,
    [Ordering.lastModifiedAsc]: (a, b) => a.modifiedAt - b.modifiedAt,
    [Ordering.lastModifiedDesc]: (a, b) => b.modifiedAt - a.modifiedAt,
  };

interface Props {
  hint?: AdvanceTokenSetupHint;
  showRowOrder?: boolean;
  clickOnDrawItem?: boolean;
  headers: Header[];
  defaultCellValue: (cellType: CellDataType, item: Item) => CellData;
  jumpToErrorCell: boolean;
  data: RowData[];
  setData: React.Dispatch<React.SetStateAction<RowData[]>>;
  ordering: Ordering;
  filter?: string;
  tags: Record<TagKey, string[]>;
  onCreateTag: (tagKey: TagKey, newTag: string) => void;
  onRenameTag: (
    tagKey: TagKey,
    row: number,
    oldName: string,
    newName: string
  ) => void;
  onDelete: (item: Item) => void;
  freezeColumns?: number;
  isReadOnly?: boolean;
}

function useAdvanceTokenSetupSettingPanel() {
  const [fieldReplacementData, setFieldReplacementData] =
    useState<OptionData>();
  const [isFieldReplacementSettingOpen, setIsFieldReplacementSettingOpen] =
    useState(false);
  const [fieldReplacementOption, setFieldReplacementOption] = useState(
    FieldReplacementOption.NoReplacement
  );

  return useMemo(
    () => ({
      fieldReplacementData,
      setFieldReplacementData,
      isFieldReplacementSettingOpen,
      setIsFieldReplacementSettingOpen,
      fieldReplacementOption,
      setFieldReplacementOption,
    }),
    [
      fieldReplacementData,
      setFieldReplacementData,
      isFieldReplacementSettingOpen,
      setIsFieldReplacementSettingOpen,
      fieldReplacementOption,
      setFieldReplacementOption,
    ]
  );
}

export function useAdvanceTokenSetupTable(props: Props) {
  const {
    clickOnDrawItem = true,
    hint,
    showRowOrder = false,
    isReadOnly = false,
    headers,
    data: cellData,
    setData,
    defaultCellValue,
    jumpToErrorCell,
    ordering,
    filter,
    tags,
    onCreateTag,
    onRenameTag,
    onDelete,
    freezeColumns,
  } = props;

  const {
    fieldReplacementData,
    setFieldReplacementData,
    isFieldReplacementSettingOpen,
    setIsFieldReplacementSettingOpen,
    fieldReplacementOption,
    setFieldReplacementOption,
  } = useAdvanceTokenSetupSettingPanel();

  const onFieldReplacementSettingClose = useCallback(() => {
    setIsFieldReplacementSettingOpen(false);
  }, [setIsFieldReplacementSettingOpen]);

  const { localized } = useLocale();

  const columns: GridColumn[] = useMemo(() => {
    let cols: GridColumn[] = headers.map(header => ({
      id: header.type,
      title: header.label,
      icon: header.icon,
      width: 225,
      group: header.group,
      themeOverride: header.group ? getHeaderTheme(header.group) : undefined,
    }));
    if (showRowOrder) {
      cols.unshift({
        id: InternalCellDataType.RowOrderCellData,
        title: "",
        width: 50,
        group: HeaderGroupKey.RowOrder,
        themeOverride: getHeaderTheme(HeaderGroupKey.ReturningTags),
      });
    }
    if (!isReadOnly) {
      cols = cols.concat({
        id: InternalCellDataType.DeleteRowCellData,
        title: localized("advance_token_setup_table.header.action"),
        width: 60,
        themeOverride: getHeaderTheme(HeaderGroupKey.ReturningTags),
      });
    }
    return cols;
  }, [showRowOrder, headers, localized, isReadOnly]);

  const defaultRow = useMemo(() => {
    return {
      order: -1,
      modifiedAt: -1,
      data: columns
        .filter(
          c =>
            c.id !== InternalCellDataType.RowOrderCellData &&
            c.id !== InternalCellDataType.DeleteRowCellData
        )
        .map((c, col) =>
          defaultCellValue(c.id as CellDataType, [col, cellData.length])
        ),
    };
  }, [columns, cellData, defaultCellValue]);

  const data = useMemo((): TableRowData[] => {
    let cellDataClone = [...cellData];
    if (filter) {
      cellDataClone = cellData.filter(row => {
        const searchPattern = normalizeCollationDiacritic(
          escapeRegExp(filter)
        ).toLowerCase();

        return (
          row.data.findIndex(d => {
            if (
              d.type === CellDataType.TagCellData ||
              d.type === CellDataType.TagAutoCompleteCellData
            ) {
              const tag = normalizeCollationDiacritic(d.tag).toLowerCase();
              return tag.includes(searchPattern);
            }

            if (d.type === CellDataType.TokenCellData) {
              const pattern = normalizeCollationDiacritic(
                d.token.pattern
              ).toLowerCase();
              return pattern.includes(searchPattern);
            }
            return false;
          }) !== -1
        );
      });
    }
    let tableCellData: TableRowData[] = [
      ...cellDataClone.sort(customSortingFuncs[ordering]),
    ];
    if (!filter) {
      tableCellData = tableCellData.concat([defaultRow]);
    }
    if (showRowOrder) {
      tableCellData = tableCellData.map(row => ({
        ...row,
        data: [
          {
            type: InternalCellDataType.RowOrderCellData,
            order: row.order,
          },
          ...row.data.map(d => {
            if (d.type === CellDataType.TagAutoCompleteCellData) {
              return {
                ...d,
                options: tags[d.tagKey],
              };
            }
            return d;
          }),
        ],
      }));
    }
    if (!isReadOnly) {
      tableCellData = tableCellData.map(row => ({
        ...row,
        data: row.data.concat([
          {
            type: InternalCellDataType.DeleteRowCellData,
          },
        ]),
      }));
    }
    return tableCellData;
  }, [defaultRow, cellData, showRowOrder, ordering, filter, tags, isReadOnly]);

  const mapToDataIndex = useCallback(
    (item: Item): Item => {
      const [col, _row] = item;
      const row = data[_row].order > 0 ? data[_row].order - 1 : _row;
      if (showRowOrder) {
        return [col - 1, row];
      }
      return [col, row];
    },
    [showRowOrder, data]
  );

  const onPreDelete = useCallback(
    (item: Item) => {
      const mappedItem = mapToDataIndex(item);
      onDelete(mappedItem);
    },
    [onDelete, mapToDataIndex]
  );

  const onOrderChange = useCallback(
    (item: Item, newOrder: number) => {
      setData(prev => {
        const toRowOrder = Math.min(Math.max(newOrder, 1), prev.length);
        const toRow = prev.findIndex(p => p.order === toRowOrder);
        const [, fromRow] = mapToDataIndex(item);
        if (toRow !== fromRow) {
          const oldItem = prev.splice(fromRow, 1)[0];
          prev.splice(toRow, 0, oldItem);
          return prev.map((p, row) => {
            return {
              ...p,
              order: row + 1,
            };
          });
        }
        return prev;
      });
    },
    [setData, mapToDataIndex]
  );

  const getOrCreateRow = useCallback(
    (data: RowData[], row: number) => {
      if (!data[row]) {
        if (isReadOnly) {
          return undefined;
        }
        const initRowData = cloneDeep(defaultRow);
        data[row] = {
          ...initRowData,
          data: initRowData.data,
          order: row + 1,
        };
      }
      return data[row];
    },
    [defaultRow, isReadOnly]
  );

  const hasDuplicateTag = useCallback(
    (cell: EditTagCell) => {
      return tags[cell.data.tagKey].findIndex(t => t === cell.data.tag) !== -1;
    },
    [tags]
  );

  const setCellValue = useCallback(
    (item: Item, newValue: EditableGridCell) => {
      const [col, row] = mapToDataIndex(item);
      if (newValue.kind === GridCellKind.Custom) {
        if (isDeleteCell(newValue)) {
          return;
        }
        if (isEditTagCell(newValue)) {
          if (hasDuplicateTag(newValue)) {
            return;
          }

          // The `data` contains filtered result, the `row` can be
          // different than the original value.
          const currentRowAtData = item[1];

          if (data[currentRowAtData].order < 0) {
            if (!isReadOnly) {
              onCreateTag(newValue.data.tagKey, newValue.data.tag);
            }
          } else {
            const oldTag = (data[currentRowAtData].data[0] as TagCellData).tag;
            onRenameTag(newValue.data.tagKey, row, oldTag, newValue.data.tag);
          }
        } else {
          setData(p => {
            const currentRow = getOrCreateRow(p, row);
            if (!currentRow) {
              return p;
            }
            if (isEditCell(newValue)) {
              currentRow.data[col] = {
                ...currentRow.data[col],
                token: newValue.data.data,
              } as TokenCellData;
            }
            if (
              isAutoCompleteCell(newValue) &&
              headers[col].type === CellDataType.TagAutoCompleteCellData
            ) {
              currentRow.data[col] = {
                ...currentRow.data[col],
                tag: newValue.data.data.value,
              } as TagAutoCompleteCellData;
            }
            currentRow.modifiedAt = new Date().getTime();
            if (currentRow.order < 0) {
              if (ordering === Ordering.priorityAsc) {
                currentRow.order = 1;
                p = p.map((p, r) => {
                  if (r !== row && p.order >= currentRow.order) {
                    return {
                      ...p,
                      order: p.order + 1,
                    };
                  }
                  return p;
                });
                p.sort(customSortingFuncs[Ordering.priorityDesc]);
              } else {
                currentRow.order = p.length;
              }
            }
            return [...p];
          });
        }

        const focusRow = ordering === Ordering.lastModifiedDesc ? 0 : item[1];
        setGridSelection({
          columns: CompactSelection.empty(),
          rows: CompactSelection.empty(),
          current: {
            cell: [item[0], focusRow],
            range: {
              x: item[0],
              y: focusRow,
              width: 1,
              height: 1,
            },
            rangeStack: [],
          },
        });
      }
    },
    [
      setData,
      mapToDataIndex,
      getOrCreateRow,
      ordering,
      headers,
      data,
      onRenameTag,
      hasDuplicateTag,
      onCreateTag,
      isReadOnly,
    ]
  );

  const getSettingConfigName = useCallback(
    (config?: SettingConfig) => {
      switch (config?.type) {
        case "custom_model":
          return localized(
            "advance_token_setup_table.setting_cell.custom_model",
            {
              fieldName: config.config.field_name,
            }
          );

        case "key_value":
          return localized(
            "advance_token_setup_table.setting_cell.key_value_pair"
          );
        case "change_date_input":
          return localized(
            "advance_token_setup_table.setting_cell.change_date_input",
            {
              format: config.config.date_input_format,
            }
          );
        default:
          return localized(
            "advance_token_setup_table.setting_cell.no_replacement"
          );
      }
    },
    [localized]
  );

  const onFieldReplacementSettingDone = useCallback(
    (newData: OptionData) => {
      setIsFieldReplacementSettingOpen(false);
      const [col, row] = mapToDataIndex(newData.item);
      setData(p => {
        const currentRow = getOrCreateRow(p, row);
        if (!currentRow) {
          return p;
        }
        currentRow.data[col] = {
          ...currentRow.data[col],
          config: newData.config,
        } as SettingCellData;
        currentRow.modifiedAt = new Date().getTime();
        p[row] = currentRow;
        return [...p];
      });
    },
    [setData, setIsFieldReplacementSettingOpen, getOrCreateRow, mapToDataIndex]
  );

  const onOpenSettingPanel = useCallback(
    (item: Item, cell: SettingCell) => {
      setFieldReplacementData({
        config: cell.data.config,
        target: cell.data.target,
        item: [showRowOrder ? item[0] - 1 : item[0], item[1]],
      });
      setIsFieldReplacementSettingOpen(true);

      switch (cell.data.config?.type) {
        case "key_value":
          setFieldReplacementOption(
            FieldReplacementOption.ReplaceWithKeyValuePair
          );
          break;
        case "custom_model":
          setFieldReplacementOption(
            FieldReplacementOption.ReplaceWidthCustomModelConfig
          );
          break;
        case "change_date_input":
          setFieldReplacementOption(
            FieldReplacementOption.ChangeInputDateFormat
          );
          break;
        default:
          setFieldReplacementOption(FieldReplacementOption.NoReplacement);
      }
    },
    [
      setIsFieldReplacementSettingOpen,
      setFieldReplacementOption,
      showRowOrder,
      setFieldReplacementData,
    ]
  );

  const onCreateNewOption = useCallback(
    (newOption: string, item: Item) => {
      const cellData = data[item[1]].data[item[0]];
      if (cellData.type === CellDataType.TagAutoCompleteCellData) {
        onCreateTag(cellData.tagKey, newOption);
      }
    },
    [data, onCreateTag]
  );

  const transformToCell = useCallback(
    (data: TableCellData, item: Item) => {
      const customCellInfo = {
        kind: GridCellKind.Custom,
        copyData: "",
      };
      switch (data.type) {
        case InternalCellDataType.RowOrderCellData:
          return {
            ...customCellInfo,
            data: {
              kind: "row-order-cell",
              order: data.order,
              onOrderChange,
              item,
            },
            readonly: isReadOnly,
            allowOverlay: data.order > 0 && !isReadOnly,
          } as RowOrderCell;
        case CellDataType.TagCellData:
          return {
            ...customCellInfo,
            data: {
              kind: "edit-tag-cell",
              tag: data.tag,
              tagKey: data.tagKey,
              item,
              error: data.error,
            },
            readonly: isReadOnly,
            allowOverlay: !isReadOnly,
          } as EditTagCell;
        case CellDataType.TagAutoCompleteCellData:
          return {
            ...customCellInfo,
            data: {
              kind: "auto-complete-cell",
              data: {
                options: tags[data.tagKey],
                value: data.tag,
                onCreateNewOption,
              },
              item,
              error: data.error,
            },
            readonly: isReadOnly,
            allowOverlay: !isReadOnly,
          } as AutoCompleteCell;
        case CellDataType.TokenCellData:
          return {
            ...customCellInfo,
            data: {
              kind: "edit-cell",
              data: data.token,
              onUpdate: setCellValue,
              clickOnDrawItem,
              item,
              error: data.error,
            },
            readonly: isReadOnly,
            allowOverlay: !isReadOnly,
          } as EditCell;
        case CellDataType.SettingCellData:
          return {
            ...customCellInfo,
            data: {
              kind: "setting-cell",
              onClick: onOpenSettingPanel,
              config: data.config,
              name: getSettingConfigName(data.config),
              item,
              target: data.target,
              error: data.error,
            },
            readonly: isReadOnly,
            allowOverlay: false,
          } as SettingCell;
        case InternalCellDataType.DeleteRowCellData:
          return {
            ...customCellInfo,
            data: {
              kind: "delete-cell",
              onDelete: onPreDelete,
              item,
            },
            readonly: isReadOnly,
          } as DeleteCell;
      }
    },
    [
      setCellValue,
      clickOnDrawItem,
      onOrderChange,
      getSettingConfigName,
      onOpenSettingPanel,
      onPreDelete,
      onCreateNewOption,
      tags,
      isReadOnly,
    ]
  );

  const getData = useCallback(
    ([col, row]: Item): GridCell => {
      if (data[row]) {
        return transformToCell(data[row].data[col], [col, row]);
      }
      return {
        kind: GridCellKind.Loading,
        allowOverlay: false,
      };
    },
    [data, transformToCell]
  );

  const getHeaderGroupName = useCallback(
    (groupName: string) => {
      switch (groupName) {
        case HeaderGroupKey.MatchingTokens:
          return localized(
            "advance_token_setup_table.header_group.matching_tokens"
          );
        case HeaderGroupKey.ReturningTags:
          return localized(
            "advance_token_setup_table.header_group.returning_tags"
          );
        case HeaderGroupKey.TagGroup:
          return localized("advance_token_setup_table.header_group.tag_group");
        case HeaderGroupKey.Field:
          return localized("advance_token_setup_table.header_group.fields");
        default:
          return "";
      }
    },
    [localized]
  );

  const getHeaderGroup = useCallback(
    (groupName: string) => {
      return {
        name: getHeaderGroupName(groupName),
        overrideTheme: getHeaderGroupTheme(groupName),
      };
    },
    [getHeaderGroupName]
  );

  const [tooltip, _setTooltip] = useState<Tooltip>();
  const timer = useRef<number>();
  const setTooltip = useCallback((newTooltip: Tooltip | undefined) => {
    if (timer.current) {
      window.clearTimeout(timer.current);
      _setTooltip(undefined);
    }
    timer.current = window.setTimeout(() => {
      _setTooltip(newTooltip);
    }, config.tooltipVisibleDelayTimeMillis);
  }, []);
  const isOpenTooltip = tooltip !== undefined;
  const { renderLayer, layerProps, arrowProps } = useLayer({
    isOpen: isOpenTooltip,
    triggerOffset: 4,
    auto: true,
    container: "portal",
    trigger: {
      getBounds: () =>
        tooltip
          ? {
              ...tooltip.bounds,
              top: tooltip.bounds.top + config.tooltipTopOffset,
            }
          : zeroBounds,
    },
  });

  const tooltipCheckers: Record<
    CellDataType,
    | {
        content: FormattedMessageProps;
        getBounds: (cell: GridCell, bounds: Rectangle) => Rectangle | undefined;
        tokenTypes?: string[];
      }[]
    | undefined
  > = useMemo(() => {
    return {
      [CellDataType.RegexTokenCellData]: undefined,
      [CellDataType.TagCellData]: undefined,
      [CellDataType.TagAutoCompleteCellData]: undefined,
      [CellDataType.SettingCellData]: undefined,
      [CellDataType.TokenCellData]: [
        {
          content: {
            id: "advance_token_setup_table.validation.tooltip.exact_match",
            values: {
              highlightTextClassName: styles["tooltip-highlight-text"],
              modifierKey: isMac() ? "⌘" : "Ctrl",
            },
          },
          getBounds: (cell, bounds) => {
            if (cell.kind === GridCellKind.Custom && isEditCell(cell)) {
              return icons[0].getDrawingBox(cell, bounds);
            }
            return undefined;
          },
        },
        {
          content: {
            id: "advance_token_setup_table.validation.tooltip.forced_remove_white_space",
            values: {
              highlightTextClassName: styles["tooltip-highlight-text"],
              modifierKey: isMac() ? "⌘" : "Ctrl",
            },
          },
          getBounds: (cell, bounds) => {
            if (cell.kind === GridCellKind.Custom && isEditCell(cell)) {
              return icons[1].getDrawingBox(cell, bounds);
            }
            return undefined;
          },
          tokenTypes: [
            "email",
            "brand_name",
            "phone_number",
            "url",
            "fax_number",
          ],
        },
        {
          content: {
            id: "advance_token_setup_table.validation.tooltip.remove_white_space",
            values: {
              highlightTextClassName: styles["tooltip-highlight-text"],
              modifierKey: isMac() ? "⌘" : "Ctrl",
            },
          },
          getBounds: (cell, bounds) => {
            if (cell.kind === GridCellKind.Custom && isEditCell(cell)) {
              return icons[1].getDrawingBox(cell, bounds);
            }
            return undefined;
          },
        },
        {
          content: {
            id: "advance_token_setup_table.validation.tooltip.regex",
            values: {
              highlightTextClassName: styles["tooltip-highlight-text"],
              modifierKey: isMac() ? "⌘" : "Ctrl",
            },
          },
          getBounds: (cell, bounds) => {
            if (cell.kind === GridCellKind.Custom && isEditCell(cell)) {
              return icons[2].getDrawingBox(cell, bounds);
            }
            return undefined;
          },
        },
      ],
    };
  }, []);

  const onItemHovered = useCallback(
    (args: GridMouseEventArgs) => {
      if (args.kind === "cell") {
        const [col, row] = args.location;
        const cellData = data[row].data[col];
        if (isCellData(cellData)) {
          const { localEventX, localEventY } = args;
          if (
            cellData.error &&
            contains([10, 10, 12, 20], localEventX, localEventY)
          ) {
            setTooltip({
              content: cellData.error,
              bounds: {
                left: args.bounds.x + 10,
                top: args.bounds.y,
                right: args.bounds.x + 12,
                bottom: args.bounds.y,
                width: 12,
                height: 20,
              },
            });
          } else {
            const tooltipChecker = tooltipCheckers[cellData.type];
            if (
              !tooltipChecker ||
              tooltipChecker.findIndex(checker => {
                const iconBounds = checker.getBounds(getData([col, row]), {
                  ...args.bounds,
                  x: args.bounds.x + (baseTheme.cellHorizontalPadding ?? 0),
                  width:
                    args.bounds.width -
                    2 * (baseTheme.cellHorizontalPadding ?? 0),
                });

                const tokenCellData = cellData as TokenCellData;

                if (
                  checker.tokenTypes &&
                  !checker.tokenTypes.includes(tokenCellData?.token?.type ?? "")
                ) {
                  // #2645
                  return false;
                }

                if (
                  iconBounds &&
                  contains(
                    [
                      iconBounds.x,
                      iconBounds.y,
                      iconBounds.width,
                      iconBounds.height,
                    ],
                    localEventX + args.bounds.x,
                    localEventY + args.bounds.y
                  )
                ) {
                  setTooltip({
                    content: checker.content,
                    bounds: {
                      left: iconBounds.x,
                      top: args.bounds.y,
                      right:
                        args.bounds.x +
                        iconBounds.x +
                        iconBounds.width -
                        args.bounds.x,
                      bottom: args.bounds.y,
                      width: iconBounds.width,
                      height: iconBounds.height,
                    },
                  });
                  return true;
                }
                return false;
              }) === -1
            ) {
              setTooltip(undefined);
            }
          }
        }
      }
    },
    [data, tooltipCheckers, setTooltip, getData]
  );

  const [gridSelection, setGridSelection] = useState<GridSelection>({
    rows: CompactSelection.empty(),
    columns: CompactSelection.empty(),
  });

  useEffect(() => {
    if (gridSelection.current) {
      const [col, row] = gridSelection.current.cell;
      if (row >= data.length || col > (data[0]?.data.length ?? 0)) {
        setGridSelection({
          rows: CompactSelection.empty(),
          columns: CompactSelection.empty(),
        });
      }
    }
  }, [data, gridSelection]);

  const onCellKeyDown = useCallback(
    (event: GridKeyEventArgs, item: Item, cell: GridCell): boolean => {
      if (!event.rawEvent) {
        return false;
      }
      if (
        matchEventKey(event.rawEvent, {
          mac: "meta+3",
          defaults: "ctrl+3",
        })
      ) {
        event.preventDefault();
        if (
          cell.kind === GridCellKind.Custom &&
          isEditCell(cell) &&
          cell.data.data.isRegex !== undefined
        ) {
          setCellValue(item, {
            ...cell,
            data: {
              ...cell.data,
              data: {
                ...cell.data.data,
                isRegex: !cell.data.data.isRegex,
              },
            },
          });
          return true;
        }
      } else if (
        matchEventKey(event.rawEvent, {
          mac: "meta+2",
          defaults: "ctrl+2",
        })
      ) {
        event.preventDefault();
        if (
          cell.kind === GridCellKind.Custom &&
          isEditCell(cell) &&
          cell.data.data.isClearSpace !== undefined
        ) {
          setCellValue(item, {
            ...cell,
            data: {
              ...cell.data,
              data: {
                ...cell.data.data,
                isClearSpace: !cell.data.data.isClearSpace,
              },
            },
          });
          return true;
        }
      } else if (
        matchEventKey(event.rawEvent, {
          mac: "meta+1",
          defaults: "ctrl+1",
        })
      ) {
        event.preventDefault();
        if (
          cell.kind === GridCellKind.Custom &&
          isEditCell(cell) &&
          cell.data.data.isExactMatch !== undefined
        ) {
          event.preventDefault();
          setCellValue(item, {
            ...cell,
            data: {
              ...cell.data,
              data: {
                ...cell.data.data,
                isExactMatch: !cell.data.data.isExactMatch,
              },
            },
          });
          return true;
        }
      }
      return false;
    },
    [setCellValue]
  );

  const onKeyDown = useCallback(
    (event: GridKeyEventArgs) => {
      const { bounds } = event;
      const currentSelection = gridSelection?.current;
      if (bounds && currentSelection) {
        const [col, row] = [currentSelection.range.x, currentSelection.range.y];
        for (let i = 0; i < currentSelection.range.width; i++) {
          for (let j = 0; j < currentSelection.range.height; j++) {
            const item: Item = [col + i, row + j];
            onCellKeyDown(event, item, getData(item));
          }
        }
      }
    },
    [gridSelection, getData, onCellKeyDown]
  );

  const dataEditorRef = useRef<DataEditorRef>(null);
  useEffect(() => {
    if (jumpToErrorCell) {
      let row: number | undefined;
      let col: number | undefined;
      const errorRow = data.find((d, i) => {
        row = i;
        col = d.data.findIndex(dd => {
          if (
            dd.type !== InternalCellDataType.RowOrderCellData &&
            dd.type !== InternalCellDataType.DeleteRowCellData
          ) {
            return dd.error !== undefined;
          }
          return undefined;
        });
        return col !== -1;
      });
      if (errorRow !== undefined && row !== undefined && col !== undefined) {
        dataEditorRef.current?.scrollTo(col, row, "both", 0, 0, {
          vAlign: "start",
          hAlign: "start",
        });
      }
    }
  }, [data, jumpToErrorCell]);

  const isEmptyViewVisible = filter && data.length === 0;

  return React.useMemo(() => {
    return {
      hint,
      isEmptyViewVisible,
      getHeaderGroup,
      columns,
      getData,
      data,
      setCellValue,
      onItemHovered,
      onKeyDown,
      setGridSelection,
      gridSelection,
      dataEditorRef,
      isOpenTooltip,
      renderLayer,
      layerProps,
      tooltip,
      arrowProps,
      isFieldReplacementSettingOpen,
      onFieldReplacementSettingDone,
      onFieldReplacementSettingClose,
      fieldReplacementOption,
      setFieldReplacementOption,
      fieldReplacementData,
      freezeColumns,
      isReadOnly,
    };
  }, [
    isEmptyViewVisible,
    hint,
    dataEditorRef,
    getHeaderGroup,
    columns,
    getData,
    data,
    setCellValue,
    onItemHovered,
    onKeyDown,
    setGridSelection,
    gridSelection,
    isOpenTooltip,
    renderLayer,
    layerProps,
    tooltip,
    arrowProps,
    isFieldReplacementSettingOpen,
    onFieldReplacementSettingDone,
    onFieldReplacementSettingClose,
    fieldReplacementOption,
    setFieldReplacementOption,
    fieldReplacementData,
    freezeColumns,
    isReadOnly,
  ]);
}

export function AdvanceTokenSetupTable(props: Props) {
  const states = useAdvanceTokenSetupTable(props);

  return <AdvanceTokenSetupTableImpl {...states} />;
}

export function AdvanceTokenSetupTableImpl(
  props: ReturnType<typeof useAdvanceTokenSetupTable>
) {
  const {
    tooltip,
    hint,
    isEmptyViewVisible,
    dataEditorRef,
    getHeaderGroup,
    columns,
    getData,
    data,
    setCellValue,
    onItemHovered,
    onKeyDown,
    setGridSelection,
    gridSelection,
    isOpenTooltip,
    renderLayer,
    layerProps,
    arrowProps,
    isFieldReplacementSettingOpen,
    onFieldReplacementSettingDone,
    onFieldReplacementSettingClose,
    fieldReplacementOption,
    setFieldReplacementOption,
    fieldReplacementData,
    freezeColumns,
    isReadOnly,
  } = props;

  return (
    <div
      className={classNames(
        styles["container"],
        "flex flex-1 flex-col overflow-hidden"
      )}
    >
      {hint && (
        <div className={styles["hint"]}>
          <div className={styles["title"]}>
            <FormattedMessage {...hint.title} />
          </div>
          <p className={styles["description"]}>
            <FormattedMessage
              {...hint.description}
              values={{
                boldTextClassName: styles["description--bold"],
                highlightTextClassName: styles["description--highlight"],
              }}
            />
          </p>
        </div>
      )}
      <DataEditor
        ref={dataEditorRef}
        className={classNames(styles["table"], {
          [styles["table--no-result"]]: isEmptyViewVisible,
        })}
        theme={baseTheme}
        getGroupDetails={getHeaderGroup}
        headerIcons={customHeaderIcons}
        columns={columns}
        getCellContent={getData}
        rows={data.length}
        customRenderers={customCellRenders}
        onCellEdited={setCellValue}
        onMouseMove={onItemHovered}
        onKeyDown={onKeyDown}
        onGridSelectionChange={setGridSelection}
        gridSelection={gridSelection}
        freezeColumns={freezeColumns}
      />
      {isEmptyViewVisible && (
        <div className={styles["empty-view"]}>
          <Text className={styles["title"]}>
            <FormattedMessage id="advance_token_setup_table.empty_result" />
          </Text>
        </div>
      )}
      {isOpenTooltip &&
        renderLayer(
          <div
            {...layerProps}
            className={styles["tooltip"]}
            style={{
              ...layerProps.style,
            }}
          >
            {tooltip === undefined ? null : typeof tooltip?.content ===
              "string" ? (
              tooltip?.content
            ) : (
              <FormattedMessage {...tooltip.content} />
            )}
            <Arrow
              {...arrowProps}
              style={{
                ...arrowProps.style,
              }}
            />
          </div>
        )}
      <AdvanceTokenSetupFieldReplacementSettingPanel
        key={isFieldReplacementSettingOpen + ""}
        isOpen={isFieldReplacementSettingOpen}
        onDone={onFieldReplacementSettingDone}
        onClose={onFieldReplacementSettingClose}
        selectedOption={fieldReplacementOption}
        onSelectOption={setFieldReplacementOption}
        data={fieldReplacementData}
        isReadOnly={isReadOnly}
      />
    </div>
  );
}

export default AdvanceTokenSetupTable;
