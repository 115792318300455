import * as React from "react";
import { useNavigate } from "react-router";

import { useWorkspaceActionCreator } from "../actions/workspace";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { WorkspaceTab } from "../components/WorkspaceNavBar";
import {
  OnSaveWorkspaceInput,
  WorkspaceSettingSection,
} from "../components/WorkspaceSetting";
import { WorkspaceSettingLineItemsTutorial } from "../components/WorkspaceSettingLineItemsTutorial";
import { WorkspaceSettingMultipleDocumentsTutorial } from "../components/WorkspaceSettingMultipleDocumentsTutorial";
import { useUnsafeParams } from "../hooks/params";
import { useToast } from "../hooks/toast";
import { useCommonWorkspaceContainerState } from "../hooks/workspace";
import { PathParam } from "../models";
import { ResourceType, isPrebuiltExtractors } from "../types/extractor";
import { Workspace } from "../types/workspace";
import HeaderContainer from "./Header";
import { WorkspaceNavBarLayoutContainer as WorkspaceNavBarLayout } from "./WorkspaceNavBarLayout";

enum WorkspaceSettingRightPanel {
  MultipleDocumentsTutorial,
  LineItemsTutorial,
}

function useWorkspaceSettingContainer() {
  const { workspaceId } = useUnsafeParams<PathParam>();
  const { workspace } = useCommonWorkspaceContainerState(workspaceId);
  const { updateWorkspace } = useWorkspaceActionCreator();
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoading, setIsLoading] = React.useState(false);
  const [showRightPanel, setShowRightPanel] = React.useState(false);
  const [activeRightPanel, setLastRightPanel] =
    React.useState<WorkspaceSettingRightPanel>();

  const onConnectedExtractorClick = React.useCallback(
    (workspace: Workspace, isToSettingPage?: boolean) => {
      const { extractor } = workspace;
      if (extractor == null) {
        return;
      }
      const isPrebuilt = isPrebuiltExtractors(extractor.extractorType);
      switch (extractor.resourceType) {
        case ResourceType.Form:
          navigate(
            `/form/${extractor.id}/${
              isToSettingPage ? "setting" : isPrebuilt ? "test" : "edit"
            }`
          );
          break;
        case ResourceType.FormGroup:
          navigate(
            `/form-group/${extractor.id}/${
              isToSettingPage ? "setting" : isPrebuilt ? "test" : "edit"
            }`
          );
          break;
        case ResourceType.CustomModel:
          navigate(
            `/custom-model/${extractor.id}/${
              isToSettingPage ? "setting" : "setup"
            }`
          );
          break;
      }
    },
    [navigate]
  );

  const onReadMoreOnMultipleDocumentsClick = React.useCallback(() => {
    setLastRightPanel(WorkspaceSettingRightPanel.MultipleDocumentsTutorial);
    setShowRightPanel(true);
  }, []);

  const onReadMoreOnLineItemsClick = React.useCallback(() => {
    setLastRightPanel(WorkspaceSettingRightPanel.LineItemsTutorial);
    setShowRightPanel(true);
  }, []);

  const onRightPanelCloseClick = React.useCallback(() => {
    setShowRightPanel(false);
  }, []);

  const onSaveWorkspace = React.useCallback(
    async (input: OnSaveWorkspaceInput) => {
      setIsLoading(true);
      try {
        await updateWorkspace({ ...input, workspaceId });
        toast.success("workspace.setting_is_saved");
        return true;
      } catch {
        toast.error("error.workspace.fail_to_update_workspace");
      } finally {
        setIsLoading(false);
      }
      return false;
    },
    [toast, updateWorkspace, workspaceId]
  );

  return React.useMemo(
    () => ({
      workspaceId,
      workspace,
      showRightPanel,
      activeRightPanel,
      onConnectedExtractorClick,
      onReadMoreOnMultipleDocumentsClick,
      onReadMoreOnLineItemsClick,
      onRightPanelCloseClick,
      onSaveWorkspace,
      isLoading: isLoading || workspace.state === "loading",
    }),
    [
      activeRightPanel,
      isLoading,
      onConnectedExtractorClick,
      onReadMoreOnLineItemsClick,
      onReadMoreOnMultipleDocumentsClick,
      onRightPanelCloseClick,
      onSaveWorkspace,
      showRightPanel,
      workspace,
      workspaceId,
    ]
  );
}

function WorkspaceSettingContainer() {
  const props = useWorkspaceSettingContainer();
  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        {props.workspace.state === "error" && (
          <ErrorPlaceholder messageId="common.fail_to_fetch_workspace" />
        )}
        {props.workspace.state === "success" && (
          <WorkspaceNavBarLayout
            selectedTab={WorkspaceTab.Settings}
            showRightPanel={props.showRightPanel}
            rightPanel={
              props.activeRightPanel ===
              WorkspaceSettingRightPanel.MultipleDocumentsTutorial ? (
                <WorkspaceSettingMultipleDocumentsTutorial
                  onCloseClick={props.onRightPanelCloseClick}
                />
              ) : props.activeRightPanel ===
                WorkspaceSettingRightPanel.LineItemsTutorial ? (
                <WorkspaceSettingLineItemsTutorial
                  onCloseClick={props.onRightPanelCloseClick}
                />
              ) : null
            }
          >
            <WorkspaceSettingSection
              {...props}
              workspace={props.workspace.data}
            />
          </WorkspaceNavBarLayout>
        )}
        <LoadingModal isOpen={props.isLoading} />
      </Main>
    </Layout>
  );
}

export default React.memo(WorkspaceSettingContainer);
