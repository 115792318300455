import React from "react";

import { IconButton } from "../IconButton";
import styles from "./styles.module.scss";

type Props = {
  onScaleToFitClick: () => void;
  onZoomInClick: () => void;
  onZoomOutClick: () => void;
};

export function OverlayZoomControl(props: Props) {
  const { onScaleToFitClick, onZoomInClick, onZoomOutClick } = props;

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <IconButton iconName="ZoomOut" onClick={onZoomOutClick} />
        <IconButton iconName="ZoomIn" onClick={onZoomInClick} />
      </div>

      <div className={styles["content"]}>
        <IconButton iconName="ZoomToFit" onClick={onScaleToFitClick} />
      </div>
    </div>
  );
}
