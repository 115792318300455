import {
  CreatePrebuiltExtractorResp,
  ExtractorFieldSchemaTable,
  PaginatedExtractor,
  PaginatedExtractorOption,
  PrebuiltExtractor,
  createPrebuiltExtractorRespSchema,
  extractorFieldSchemaTableSchema,
  paginatedExtractorOptionSchema,
  paginatedExtractorSchema,
} from "../../types/extractor";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface ExtractorApiClient {
  createPrebuiltExtractor: (
    resourceOwnerId: string,
    name: string,
    type: PrebuiltExtractor
  ) => Promise<CreatePrebuiltExtractorResp>;
  listExtractor: (
    size: number,
    offset: number,
    resourceOwnerId: string,
    types?: string[],
    searchString?: string
  ) => Promise<PaginatedExtractor>;
  listExtractorAsOption: (
    size: number,
    offset: number,
    resourceOwnerId: string
  ) => Promise<PaginatedExtractorOption>;
  requestPreviewExtractor: (
    type: PrebuiltExtractor,
    resourceOwnerId: string | undefined
  ) => Promise<void>;
  getExtractorFieldSchema: (
    extractorId: string
  ) => Promise<ExtractorFieldSchemaTable>;
}

export function withExtractorApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async createPrebuiltExtractor(
      resourceOwnerId: string,
      name: string,
      type: PrebuiltExtractor
    ): Promise<CreatePrebuiltExtractorResp> {
      return this.lambda(
        "extractor:create-prebuilt",
        {
          type,
          name,
          resource_owner_id: resourceOwnerId,
        },
        createPrebuiltExtractorRespSchema,
        null
      );
    }

    async listExtractor(
      size: number,
      offset: number,
      resourceOwnerId: string,
      types?: string[],
      searchString?: string
    ): Promise<PaginatedExtractor> {
      const args = {
        page_args: {
          size,
          offset,
        },
        resource_owner_id: resourceOwnerId,
        search_string: searchString,
      };
      return this.lambda(
        "extractor:list",
        this.injectOptionalFields(args, { types }),
        paginatedExtractorSchema
      );
    }

    async listExtractorAsOption(
      size: number,
      offset: number,
      resourceOwnerId: string
    ): Promise<PaginatedExtractorOption> {
      return this.lambda(
        "extractor:list-as-option",
        {
          page_args: {
            size,
            offset,
          },
          resource_owner_id: resourceOwnerId,
        },
        paginatedExtractorOptionSchema
      );
    }

    async requestPreviewExtractor(
      type: PrebuiltExtractor,
      resourceOwnerId: string | undefined
    ): Promise<void> {
      return this.lambda("extractor:request-preview", {
        type,
        resource_owner_id: resourceOwnerId,
      });
    }

    async getExtractorFieldSchema(
      extractorId: string
    ): Promise<ExtractorFieldSchemaTable> {
      return this.lambda<ExtractorFieldSchemaTable>(
        "extractor:get-schema",
        {
          extractor_id: extractorId,
        },
        extractorFieldSchemaTableSchema
      );
    }
  };
}
