import { IconButton, SearchBox } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React, {
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";

import { SUPPORTED_EXTRACT_MIME } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { FilePrimaryButton } from "../FileButton";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface WorkspaceDocumentTopBarProps {
  className?: string;
  fileNameSearchText: string;
  onFileNameSearch: (searchText: string) => void;
  isOutOfQuota: boolean;
  onSelectFiles: (files: File[]) => void;
  showDeleteButtons: boolean;
  selectedCount: number;
  onDeleteAll: () => void;
  onDeleteSelected: () => void;
  onClickExport: () => void;
  isPreparingExport: boolean;
  hasExtractingDocuments: boolean;
  hasDocuments: boolean;
}

const WorkspaceDocumentTopBar = (
  props: WorkspaceDocumentTopBarProps
): ReactElement => {
  const {
    className,
    isOutOfQuota,
    onSelectFiles,
    fileNameSearchText,
    onFileNameSearch,
    showDeleteButtons,
    selectedCount,
    onDeleteAll,
    onDeleteSelected,
    onClickExport,
    isPreparingExport,
    hasExtractingDocuments,
    hasDocuments,
  } = props;

  const { localized } = useLocale();

  const [searchValue, setSearchValue] = useState(fileNameSearchText);

  useEffect(() => {
    setSearchValue(fileNameSearchText);
  }, [fileNameSearchText]);

  const _onSearchValueChange = (
    ev?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string
  ) => {
    ev?.preventDefault();
    ev?.stopPropagation();
    setSearchValue(newValue ?? "");
  };

  const onFiles = useCallback(
    (files?: File[]) => onSelectFiles(files ?? []),
    [onSelectFiles]
  );

  const onKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        onFileNameSearch(searchValue);
      }
    },
    [onFileNameSearch, searchValue]
  );

  const onClear = useCallback(() => {
    setSearchValue("");
    onFileNameSearch("");
  }, [onFileNameSearch]);

  const _onClickExport = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    onClickExport();
  };

  return (
    <div className={cn(styles.topBar, className)}>
      <SearchBox
        className={styles.searchBox}
        placeholder={localized("workspace.document.top_bar.search_box.label")}
        value={searchValue}
        onChange={_onSearchValueChange}
        onKeyUp={onKeyUp}
        onClear={onClear}
      />
      <div className={styles.rightGroup}>
        {isOutOfQuota ? (
          <p className={styles.outOfQuota}>
            <FormattedMessage id="workspace.document.top_bar.out_of_quota" />
          </p>
        ) : null}
        <FilePrimaryButton
          textId="workspace.document.top_bar.upload_button.label"
          onFiles={onFiles}
          accept={SUPPORTED_EXTRACT_MIME.join(",")}
          isMultiple={true}
          disabled={isOutOfQuota}
        />
        <DefaultButton
          loading={isPreparingExport}
          textId={
            selectedCount <= 0
              ? "workspace.document.top_bar.export_button.label.all"
              : "workspace.document.top_bar.export_button.label"
          }
          textValues={{ count: selectedCount }}
          onClick={_onClickExport}
          disabled={
            isPreparingExport || hasExtractingDocuments || !hasDocuments
          }
        />
        <IconButton
          styles={{
            root: {
              color: "#201F1E",
              border: "1px solid #8A8886",
            },
            rootHovered: {
              color: "#201F1E",
            },
            rootPressed: {
              color: "#201F1E",
            },
            rootExpanded: {
              color: "#201F1E",
            },
          }}
          menuIconProps={{ iconName: "MoreVertical" }}
          disabled={!showDeleteButtons}
          menuProps={{
            items: !showDeleteButtons
              ? []
              : [
                  ...(selectedCount > 0
                    ? [
                        {
                          key: "deleteSelected",
                          name: localized(
                            "workspace.document.top_bar.delete_selected",
                            {
                              count: selectedCount,
                            }
                          ),
                          onClick: onDeleteSelected,
                        },
                      ]
                    : []),
                  {
                    key: "deleteAll",
                    name: localized("workspace.document.top_bar.delete_all"),
                    onClick: onDeleteAll,
                  },
                ],
          }}
        />
      </div>
    </div>
  );
};

export default WorkspaceDocumentTopBar;
