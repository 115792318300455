import * as React from "react";
import { useSelector } from "react-redux";

import { useFormGroupActionCreator } from "../actions/formGroup";
import {
  CreateWorkspaceModal,
  useCreateWorkspaceModalHandle,
} from "../components/CreateWorkspaceModal";
import DataNotSavedPrompt from "../components/DataNotSavedPrompt";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import {
  ExtractorSettingSection,
  useExtractorSettingHandle,
} from "../components/ExtractorSetting";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import ScriptEditorModal, {
  useScriptEditorHandle,
} from "../components/ScriptEditorModal";
import HeaderContainer from "../containers/Header";
import errors, { FOCRError } from "../errors";
import { useCommonFormGroupContainerState } from "../hooks/form_group";
import { useGtm } from "../hooks/gtm";
import { useUnsafeParams } from "../hooks/params";
import { useToast } from "../hooks/toast";
import { PathParam } from "../models";
import { RootState } from "../redux/types";
import { ExtractorSettings } from "../types/extractorSettings";
import { BriefExtractorMapper } from "../types/mappers/extractor";
import { ProcessingMode } from "../types/processingMode";
import {
  FormGroupNavBarLayout,
  FormGroupNavTabKey,
} from "./FormGroupNavBarLayout";

function useFormGroupSettingContainer() {
  const { formGroupId } = useUnsafeParams<PathParam>();
  const containerState = useCommonFormGroupContainerState(formGroupId);

  const {
    props: createWorkspaceModalProps,
    isCreating: isCreatingWorkspace,
    onCreateExtractorWorkspaceClick,
  } = useCreateWorkspaceModalHandle(
    containerState.state === "success"
      ? BriefExtractorMapper.fromDetailFormGroup(containerState.formGroup)
      : null
  );

  const { updateFormGroupExtractorSettings } = useFormGroupActionCreator();

  const toast = useToast();

  const { pushClickedSaveExtractorEvent } = useGtm();

  const onSaveExtractorSettings = React.useCallback(
    async (extractorSettings: ExtractorSettings) => {
      try {
        pushClickedSaveExtractorEvent(formGroupId);
        await updateFormGroupExtractorSettings(extractorSettings);
        toast.success("form_editor.setting_is_saved");
      } catch (e: unknown) {
        if (e instanceof FOCRError) {
          toast.error(e.messageId, undefined, e.detail);
        } else {
          console.error(e);
          toast.error(
            errors.UnknownError.messageId,
            undefined,
            errors.UnknownError.detail
          );
        }
      }
    },
    [
      formGroupId,
      toast,
      updateFormGroupExtractorSettings,
      pushClickedSaveExtractorEvent,
    ]
  );

  const useExtractorSettingHandleReturns = useExtractorSettingHandle({
    formGroup:
      containerState.state === "success" ? containerState.formGroup : undefined,
    onSave: onSaveExtractorSettings,
  });

  const { onOpenScriptModal, props: scriptModalProps } = useScriptEditorHandle({
    scriptType: "post_process_script",
    formGroup:
      containerState.state === "success"
        ? {
            ...containerState.formGroup,
            config: {
              ...containerState.formGroup.config,
              post_process_script:
                useExtractorSettingHandleReturns.extractorSettings
                  .postProcessingScript ??
                containerState.formGroup.config.post_process_script,
            },
          }
        : undefined,
    onSaveScript: useExtractorSettingHandleReturns.onChangePostProcessingScript,
  });

  const {
    onOpenScriptModal: onOpenTransformResponseScriptModal,
    props: transformResponseScriptModalProps,
  } = useScriptEditorHandle({
    scriptType: "transform_response_script",
    formGroup:
      containerState.state === "success"
        ? {
            ...containerState.formGroup,
            config: {
              ...containerState.formGroup.config,
              transform_response_script:
                useExtractorSettingHandleReturns.extractorSettings
                  .transformResponseScript ??
                containerState.formGroup.config.transform_response_script,
            },
          }
        : undefined,
    onSaveScript:
      useExtractorSettingHandleReturns.onChangeTransformResponseScript,
  });

  const { resourceOwnerId } = useSelector(
    (state: RootState) => state.resourceOwner
  );
  const { exportFormGroup } = useFormGroupActionCreator();

  const [isExporting, setIsExporting] = React.useState(false);
  const onExport = React.useCallback(async () => {
    if (containerState.state !== "success") {
      return;
    }
    if (resourceOwnerId == null) {
      return;
    }
    setIsExporting(true);
    try {
      await exportFormGroup(containerState.formGroup.id, resourceOwnerId);
    } catch {
      toast.error("error.fail_to_export_form");
    }
    setIsExporting(false);
  }, [
    containerState.state,
    //@ts-expect-error container state can be not success
    containerState.formGroup?.id,
    resourceOwnerId,
    exportFormGroup,
    toast,
  ]);

  return React.useMemo(
    () => ({
      formGroupId,
      containerState,
      highlightWorkspaceSetups:
        containerState.state === "success" &&
        containerState.formGroup.workspaces.length === 0,
      createWorkspaceModalProps,
      onCreateExtractorWorkspaceClick,
      isCreatingWorkspace,
      useExtractorSettingHandleReturns,
      onOpenScriptModal,
      onOpenTransformResponseScriptModal,
      scriptModalProps,
      transformResponseScriptModalProps,
      isExporting,
      onExport,
    }),
    [
      formGroupId,
      containerState,
      createWorkspaceModalProps,
      onCreateExtractorWorkspaceClick,
      isCreatingWorkspace,
      useExtractorSettingHandleReturns,
      onOpenScriptModal,
      onOpenTransformResponseScriptModal,
      scriptModalProps,
      transformResponseScriptModalProps,
      isExporting,
      onExport,
    ]
  );
}

export default function FormGroupSettingContainer() {
  const props = useFormGroupSettingContainer();
  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={props.containerState.state === "loading"} />
      <Main hasTop={true}>
        {props.containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form" />
        ) : props.containerState.state === "success" ? (
          <>
            <FormGroupNavBarLayout selectedTab={FormGroupNavTabKey.Settings}>
              <ExtractorSettingSection
                extractor={BriefExtractorMapper.fromDetailFormGroup(
                  props.containerState.formGroup
                )}
                workspaces={props.containerState.formGroup.workspaces}
                showWorkspaceSetups={true}
                onCreateExtractorWorkspaceClick={
                  props.onCreateExtractorWorkspaceClick
                }
                useHandleReturns={props.useExtractorSettingHandleReturns}
                onClickEditPostProcessingScript={props.onOpenScriptModal}
                onClickEditTransformResponseScript={
                  props.onOpenTransformResponseScriptModal
                }
                isExportingForm={props.isExporting}
                onClickExportForm={props.onExport}
                allowedProcessingModes={[
                  ...(props.containerState.formGroup.type ===
                  "document_detection"
                    ? []
                    : [ProcessingMode.PerPage]),
                  ProcessingMode.MultipleDocumentsPerPage,
                ]}
              />
            </FormGroupNavBarLayout>
            <CreateWorkspaceModal {...props.createWorkspaceModalProps} />
            <LoadingModal
              messageId="common.uploading"
              isOpen={props.isCreatingWorkspace}
            />
            {props.scriptModalProps != null ? (
              <ScriptEditorModal {...props.scriptModalProps} />
            ) : null}

            {props.transformResponseScriptModalProps != null ? (
              <ScriptEditorModal {...props.transformResponseScriptModalProps} />
            ) : null}
            <DataNotSavedPrompt
              isDataChanged={props.useExtractorSettingHandleReturns.isFormDirty}
              titleTextId="extractor_setting.form_not_saved_prompt.title"
              messageTextId="extractor_setting.form_not_saved_prompt.save_warning"
              backTextId="extractor_setting.form_not_saved_prompt.go_back"
              continueTextId="extractor_setting.form_not_saved_prompt.leave_page"
            />
          </>
        ) : null}
      </Main>
    </Layout>
  );
}
