import { Checkbox } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { UserFeatureFlag } from "../../constants";
import {
  AlphaFeatureFlags,
  BetaFeatureFlags,
  OtherFeatureFlags,
} from "../../constants/userFeature";
import { useLocale } from "../../contexts/locale";
import messages from "../../locale-data/en.json";
import { UserFeatureGroup } from "../../types/userFeature";
import styles from "./styles.module.scss";

interface SectionProps {
  title: string;
  groupFeature?: UserFeatureFlag;
  listedFeatures: UserFeatureFlag[];
  enabledFeatures: UserFeatureFlag[];
  disabled: boolean;
  onFeatureCheckboxClicked?: (
    features: UserFeatureFlag,
    checked: boolean
  ) => void;
}

function Section(props: SectionProps) {
  const { localized } = useLocale();

  return (
    <div className={styles["section"]}>
      <div className={styles["section-header"]}>
        <FormattedMessage id={props.title} />
      </div>
      {props.listedFeatures.map(feature => {
        return (
          <div key={feature}>
            <Checkbox
              className={styles["checkbox"]}
              checked={
                props.enabledFeatures.includes(feature) ||
                props.enabledFeatures.includes(UserFeatureFlag.All) ||
                (props.groupFeature !== undefined &&
                  props.enabledFeatures.includes(props.groupFeature))
              }
              disabled={props.disabled}
              label={
                `team.detail.features.${feature}` in messages
                  ? localized(`team.detail.features.${feature}`)
                  : localized(`auto_extraction_item.${feature}`)
              }
              onChange={(
                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                checked?: boolean
              ) => {
                ev?.stopPropagation();
                ev?.preventDefault();
                props.onFeatureCheckboxClicked?.(feature, checked ?? false);
              }}
            />
            {feature === UserFeatureFlag.All && (
              <div className={styles["separator"]}></div>
            )}
          </div>
        );
      })}
    </div>
  );
}

interface Props {
  disableAllFeatures?: boolean;
  userFeatureGroup: UserFeatureGroup;
  enabledFeatures: UserFeatureFlag[];
  onFeatureCheckboxClicked?: (
    features: UserFeatureFlag,
    checked: boolean
  ) => void;
}

function _UserFeatureFlagsChecboxList(props: Props) {
  const { enabledFeatures, userFeatureGroup, onFeatureCheckboxClicked } = props;

  return (
    <div className={styles["feature-flags-box"]}>
      <Section
        title="team.detail.features_group_title.alpha"
        listedFeatures={AlphaFeatureFlags}
        enabledFeatures={enabledFeatures}
        groupFeature={UserFeatureFlag.Alpha}
        onFeatureCheckboxClicked={onFeatureCheckboxClicked}
        disabled={
          userFeatureGroup === UserFeatureGroup.Alpha ||
          userFeatureGroup === UserFeatureGroup.All
        }
      />
      <Section
        title="team.detail.features_group_title.beta"
        listedFeatures={BetaFeatureFlags}
        enabledFeatures={enabledFeatures}
        groupFeature={UserFeatureFlag.Beta}
        onFeatureCheckboxClicked={onFeatureCheckboxClicked}
        disabled={
          userFeatureGroup === UserFeatureGroup.Beta ||
          userFeatureGroup === UserFeatureGroup.Alpha ||
          userFeatureGroup === UserFeatureGroup.All
        }
      />
      <Section
        title="team.detail.features_group_title.other"
        listedFeatures={OtherFeatureFlags}
        enabledFeatures={enabledFeatures}
        onFeatureCheckboxClicked={onFeatureCheckboxClicked}
        disabled={userFeatureGroup === UserFeatureGroup.All}
      />
    </div>
  );
}

export const UserFeatureFlagsChecboxList = React.memo(
  _UserFeatureFlagsChecboxList
);
export default UserFeatureFlagsChecboxList;
