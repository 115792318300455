import produce from "immer";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuid } from "uuid";

import { useFormActionCreator } from "../actions/form";
import { useToast } from "../hooks/toast";
import { ModalState } from "../models";
import {
  MatchMode,
  TokenGroup,
  TokenGroupImage,
  TokenGroupText,
  TokenGroupType,
} from "../types/tokenGroup";

function useTokenGroupCallbacks(
  tokenGroups: TokenGroup[],
  updateFormTokenGroups: (tokenGroups: TokenGroup[]) => void
) {
  const [expandedTokenGroupIndex, setExpandedTokenGroupIndex] = useState<
    number | undefined
  >();

  const [isTokenGroupModalOpened, setIsTokenGroupModalOpened] =
    useState<ModalState>(ModalState.Closed);

  const [edittingTokenGroup, setEdittingTokenGroup] = useState<
    TokenGroup | undefined
  >();

  const validateTokenGroupName = useCallback(
    (name: string) => {
      const isNameEditted =
        !!edittingTokenGroup && edittingTokenGroup.name !== name;
      const isNameUnique =
        tokenGroups.find(tokenGroup => tokenGroup.name === name) === undefined;

      if (!name.trim()) {
        return "error.token_group_modal.empty_name";
      } else if (isNameEditted && !isNameUnique) {
        return "error.token_group_modal.duplicated_name";
      }
      return undefined;
    },
    [tokenGroups, edittingTokenGroup]
  );

  const onCreateTokenGroup = useCallback(() => {
    setIsTokenGroupModalOpened(ModalState.OpenedToCreate);
  }, []);
  const onEditTokenGroup = useCallback((tokenGroup: TokenGroup) => {
    setIsTokenGroupModalOpened(ModalState.OpenedToEdit);
    setEdittingTokenGroup(tokenGroup);
  }, []);

  const onDeleteTokenGroup = useCallback(
    (i: number) => (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();

      updateFormTokenGroups(
        produce(tokenGroups, draftTokenGroups => {
          draftTokenGroups.splice(i, 1);
        })
      );
    },
    [tokenGroups, updateFormTokenGroups]
  );

  const closeTokenGroupModal = useCallback(() => {
    setIsTokenGroupModalOpened(ModalState.Closed);
    setEdittingTokenGroup(undefined);
  }, []);

  const onTokenGroupModalSubmit = useCallback(
    (
      name: string,
      matchMode: MatchMode,
      tokenType: TokenGroupType | undefined
    ) => {
      closeTokenGroupModal();

      if (edittingTokenGroup) {
        const indexIfExists = tokenGroups.findIndex(
          ({ id }) => id === edittingTokenGroup.id
        );
        if (indexIfExists > -1) {
          updateFormTokenGroups(
            produce(tokenGroups, draftTokenGroups => {
              const { tokenType: _, ...restTokenGroup } =
                tokenGroups[indexIfExists];

              const newTokenGroup: TokenGroup = {
                ...restTokenGroup,
                name,
                matchMode,
              };

              if (tokenType) {
                newTokenGroup.tokenType = tokenType;
              }

              draftTokenGroups[indexIfExists] = newTokenGroup;
            })
          );
        }
      } else {
        updateFormTokenGroups(
          produce(tokenGroups, draftTokenGroups => {
            const newTokenGroup: TokenGroup = {
              id: uuid(),
              name,
              images: [],
              texts: [],
              matchMode,
              created_at: 0,
            };
            if (tokenType) {
              newTokenGroup.tokenType = tokenType;
            }
            draftTokenGroups.push(newTokenGroup);
          })
        );
        setExpandedTokenGroupIndex(tokenGroups.length);
      }
    },
    [
      closeTokenGroupModal,
      edittingTokenGroup,
      tokenGroups,
      updateFormTokenGroups,
    ]
  );

  const onToggleCollapse = useCallback(
    (groupIndex: number) => () => {
      if (groupIndex !== undefined) {
        setExpandedTokenGroupIndex(
          groupIndex === expandedTokenGroupIndex ? undefined : groupIndex
        );
      }
    },
    [expandedTokenGroupIndex, setExpandedTokenGroupIndex]
  );

  const onEditGroupClick = useCallback(
    (groupIndex: number) => () => {
      if (groupIndex !== undefined) {
        onEditTokenGroup(tokenGroups[groupIndex]);
      }
    },
    [onEditTokenGroup, tokenGroups]
  );

  return useMemo(
    () => ({
      isTokenGroupModalOpened,
      edittingTokenGroup,
      expandedTokenGroupIndex,
      validateTokenGroupName,
      setExpandedTokenGroupIndex,
      closeTokenGroupModal,
      onCreateTokenGroup,
      onDeleteTokenGroup,
      onEditTokenGroup,
      onTokenGroupModalSubmit,
      onToggleCollapse,
      onEditGroupClick,
    }),
    [
      isTokenGroupModalOpened,
      edittingTokenGroup,
      expandedTokenGroupIndex,
      validateTokenGroupName,
      setExpandedTokenGroupIndex,
      closeTokenGroupModal,
      onCreateTokenGroup,
      onDeleteTokenGroup,
      onEditTokenGroup,
      onTokenGroupModalSubmit,
      onToggleCollapse,
      onEditGroupClick,
    ]
  );
}

function useTextToken(
  tokenGroups: TokenGroup[],
  updateFormTokenGroups: (tokenGroups: TokenGroup[]) => void,
  expandedTokenGroupIndex?: number
) {
  const [isTextTokenModalOpened, setIsTextTokenModalOpened] =
    useState<ModalState>(ModalState.Closed);

  const [edittingTextToken, setEdittingTextToken] = useState<
    TokenGroupText | undefined
  >();

  const validateTextTokenValue = useCallback(
    (value: string) => {
      if (expandedTokenGroupIndex === undefined) {
        return undefined;
      }
      const isValueEditted =
        !!edittingTextToken && edittingTextToken.value !== value;
      const isValueUnique =
        tokenGroups[expandedTokenGroupIndex].texts.find(
          textToken => textToken.value === value
        ) === undefined;

      if (!value.trim()) {
        return "error.text_token_modal.empty_value";
      } else if (isValueEditted && !isValueUnique) {
        return "error.text_token_modal.duplicated_value";
      }
      return undefined;
    },
    [tokenGroups, expandedTokenGroupIndex, edittingTextToken]
  );

  const closeTextTokenModal = useCallback(() => {
    setIsTextTokenModalOpened(ModalState.Closed);
    setEdittingTextToken(undefined);
  }, []);

  const onCreateTextToken = useCallback(() => {
    setIsTextTokenModalOpened(ModalState.OpenedToCreate);
  }, []);
  const onEditTextToken = useCallback(
    (textToken: TokenGroupText) => () => {
      setIsTextTokenModalOpened(ModalState.OpenedToEdit);
      setEdittingTextToken(textToken);
    },
    []
  );

  const onDeleteTextToken = useCallback(
    (tokenGroupIndex: number, tokenIndex: number) => {
      updateFormTokenGroups(
        produce(tokenGroups, draftTokenGroups => {
          draftTokenGroups[tokenGroupIndex].texts.splice(tokenIndex, 1);
        })
      );
    },
    [tokenGroups, updateFormTokenGroups]
  );

  const onTextTokenModalSubmit = useCallback(
    (
      value: string,
      isExactMatchOnly: boolean,
      isIgnoreWhiteSpace: boolean,
      label: string
    ) => {
      closeTextTokenModal();

      if (expandedTokenGroupIndex === undefined) {
        return;
      }

      updateFormTokenGroups(
        produce(tokenGroups, draftTokenGroups => {
          const targetTokenGroup = draftTokenGroups[expandedTokenGroupIndex];

          if (edittingTextToken) {
            const textTokenIndexToEdit = targetTokenGroup.texts.findIndex(
              text => text.id === edittingTextToken.id
            );
            if (textTokenIndexToEdit > -1) {
              targetTokenGroup.texts[textTokenIndexToEdit].value = value;
              targetTokenGroup.texts[textTokenIndexToEdit].isExactMatchOnly =
                isExactMatchOnly;
              targetTokenGroup.texts[textTokenIndexToEdit].isIgnoreWhiteSpace =
                isIgnoreWhiteSpace;
              targetTokenGroup.texts[textTokenIndexToEdit].label = label;
            }
          } else {
            targetTokenGroup.texts.push({
              id: uuid(),
              value,
              isExactMatchOnly: isExactMatchOnly,
              isIgnoreWhiteSpace: isIgnoreWhiteSpace,
              label: label,
            });
          }
        })
      );
    },
    [
      closeTextTokenModal,
      edittingTextToken,
      expandedTokenGroupIndex,
      tokenGroups,
      updateFormTokenGroups,
    ]
  );

  return useMemo(
    () => ({
      isTextTokenModalOpened,
      edittingTextToken,
      validateTextTokenValue,
      closeTextTokenModal,
      onCreateTextToken,
      onEditTextToken,
      onDeleteTextToken,
      onTextTokenModalSubmit,
    }),
    [
      isTextTokenModalOpened,
      edittingTextToken,
      validateTextTokenValue,
      closeTextTokenModal,
      onCreateTextToken,
      onEditTextToken,
      onDeleteTextToken,
      onTextTokenModalSubmit,
    ]
  );
}

function useImageToken(
  tokenGroups: TokenGroup[],
  updateFormTokenGroups: (tokenGroups: TokenGroup[]) => void,
  expandedTokenGroupIndex?: number
) {
  const { createImageToken } = useFormActionCreator();
  const [isImageTokenModalOpened, setIsImageTokenModalOpened] =
    useState<ModalState>(ModalState.Closed);

  const [edittingImageToken, setEdittingImageToken] = useState<
    TokenGroupImage | undefined
  >();

  const [uploadingImageTokenCount, setUploadingImageTokenCount] = useState(0);
  const uploadingImageTokenCountRef = useRef(0);

  const toast = useToast();

  const closeImageTokenModal = useCallback(() => {
    setIsImageTokenModalOpened(ModalState.Closed);
  }, []);

  const onCreateImageToken = useCallback(() => {
    setEdittingImageToken(undefined);
    setIsImageTokenModalOpened(ModalState.OpenedToCreate);
  }, []);

  const onEditImageToken = useCallback(
    (imageToken: TokenGroupImage) => () => {
      setIsImageTokenModalOpened(ModalState.OpenedToEdit);
      setEdittingImageToken(imageToken);
    },
    []
  );

  const onDeleteImageToken = useCallback(
    (tokenGroupIndex: number, tokenIndex: number) => {
      updateFormTokenGroups(
        produce(tokenGroups, draftTokenGroups => {
          draftTokenGroups[tokenGroupIndex].images.splice(tokenIndex, 1);
        })
      );
    },
    [tokenGroups, updateFormTokenGroups]
  );

  const checkIfImageTokenNameUnique = useCallback(
    (name: string) => {
      if (expandedTokenGroupIndex === undefined) {
        return false;
      }

      const isNameNotEditted =
        !!edittingImageToken && edittingImageToken.name === name;

      const isNameUnique =
        tokenGroups[expandedTokenGroupIndex].images.find(
          image => image.name === name
        ) === undefined;

      return isNameNotEditted || isNameUnique;
    },
    [expandedTokenGroupIndex, tokenGroups, edittingImageToken]
  );

  const onImageTokenModalSubmit = useCallback(
    (name: string, file?: File) => {
      closeImageTokenModal();
      if (expandedTokenGroupIndex === undefined) {
        return;
      }
      if (edittingImageToken) {
        const imageTokenIndexToEdit = tokenGroups[
          expandedTokenGroupIndex
        ].images.findIndex(image => image.id === edittingImageToken.id);

        if (imageTokenIndexToEdit === -1) {
          return;
        }

        if (file) {
          uploadingImageTokenCountRef.current++;
          setUploadingImageTokenCount(uploadingImageTokenCountRef.current);
          createImageToken(file)
            .then(({ url, assetId }) => {
              if (!url) {
                return;
              }
              updateFormTokenGroups(
                produce(tokenGroups, draftTokenGroups => {
                  draftTokenGroups[expandedTokenGroupIndex].images[
                    imageTokenIndexToEdit
                  ] = {
                    id: draftTokenGroups[expandedTokenGroupIndex].images[
                      imageTokenIndexToEdit
                    ].id,
                    name,
                    assetId,
                    url,
                  };
                })
              );
            })
            .catch(() => {
              toast.error("error.cannot_load_image");
            })
            .finally(() => {
              // tslint:disable-line:no-floating-promises
              uploadingImageTokenCountRef.current--;
              setUploadingImageTokenCount(uploadingImageTokenCountRef.current);
            });
        } else {
          updateFormTokenGroups(
            produce(tokenGroups, draftTokenGroups => {
              draftTokenGroups[expandedTokenGroupIndex].images[
                imageTokenIndexToEdit
              ].name = name;
            })
          );
        }
      } else {
        if (!file) {
          return;
        }

        uploadingImageTokenCountRef.current++;
        setUploadingImageTokenCount(uploadingImageTokenCountRef.current);
        createImageToken(file)
          .then(({ url, assetId }) => {
            if (!url) {
              return;
            }
            updateFormTokenGroups(
              produce(tokenGroups, draftTokenGroups => {
                draftTokenGroups[expandedTokenGroupIndex].images.push({
                  id: uuid(),
                  name,
                  assetId,
                  url,
                });
              })
            );
          })
          .catch(() => {
            toast.error("error.cannot_load_image");
          })
          .finally(() => {
            // tslint:disable-line:no-floating-promises
            uploadingImageTokenCountRef.current--;
            setUploadingImageTokenCount(uploadingImageTokenCountRef.current);
          });
      }
    },
    [
      toast,
      closeImageTokenModal,
      edittingImageToken,
      expandedTokenGroupIndex,
      tokenGroups,
      updateFormTokenGroups,
      createImageToken,
    ]
  );

  return useMemo(
    () => ({
      isImageTokenModalOpened,
      edittingImageToken,
      closeImageTokenModal,
      onCreateImageToken,
      onEditImageToken,
      onDeleteImageToken,
      onImageTokenModalSubmit,
      checkIfImageTokenNameUnique,
      uploadingImageTokenCount,
    }),
    [
      isImageTokenModalOpened,
      edittingImageToken,
      closeImageTokenModal,
      onCreateImageToken,
      onEditImageToken,
      onDeleteImageToken,
      onImageTokenModalSubmit,
      checkIfImageTokenNameUnique,
      uploadingImageTokenCount,
    ]
  );
}

function useMakeContext(
  tokenGroups: TokenGroup[],
  updateFormTokenGroups: (tokenGroups: TokenGroup[]) => void
) {
  const tokenGroupCallbacks = useTokenGroupCallbacks(
    tokenGroups,
    updateFormTokenGroups
  );

  const { expandedTokenGroupIndex } = tokenGroupCallbacks;

  const textTokenCallbacks = useTextToken(
    tokenGroups,
    updateFormTokenGroups,
    expandedTokenGroupIndex
  );
  const imageTokenCallbacks = useImageToken(
    tokenGroups,
    updateFormTokenGroups,
    expandedTokenGroupIndex
  );

  return useMemo(
    () => ({
      ...tokenGroupCallbacks,
      ...textTokenCallbacks,
      ...imageTokenCallbacks,
    }),
    [tokenGroupCallbacks, textTokenCallbacks, imageTokenCallbacks]
  );
}

type TokenGroupTabPaneContextValue = ReturnType<typeof useMakeContext>;
const TokenGroupTabPaneContext = createContext<TokenGroupTabPaneContextValue>(
  null as any
);

interface Props {
  tokenGroups: TokenGroup[];
  children: React.ReactNode;
  updateFormTokenGroups: (tokenGroups: TokenGroup[]) => void;
}

export const TokenGroupTabPaneProvider = (props: Props) => {
  const value = useMakeContext(props.tokenGroups, props.updateFormTokenGroups);
  return <TokenGroupTabPaneContext.Provider {...props} value={value} />;
};

export function useTokenGroupTabPane() {
  return useContext(TokenGroupTabPaneContext);
}
