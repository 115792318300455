import { ApiClientConstructor, _BaseApiClient } from "../base";
import { AuditLogApiClient, withAuditLogApi } from "./auditLog";
import { AuthApiClient, withAuthApi } from "./auth";
import {
  ConfigSnapshotApiClient,
  withConfigSnapshotApi,
} from "./configSnapshot";
import { CustomModelApiClient, withCustomModelApi } from "./customModel";
import {
  CustomModelImageApiClient,
  withCustomModelImageApi,
} from "./customModelImage";
import { ExtractionApiClient, withExtractionApi } from "./extraction";
import { ExtractorApiClient, withExtractorApi } from "./extractor";
import { FormApiClient, withFormApi } from "./form";
import { FormGroupApiClient, withFormGroupApi } from "./formGroup";
import { InvitationApiClient, withInvitationApi } from "./invitation";
import { PlanAPIClient, withPlanApi } from "./plan";
import { ReceiptGroupApiClient, withReceiptGroupApi } from "./receiptGroup";
import { RequestLogApiClient, withRequestLogApi } from "./requestLog";
import { ResourceOwnerApiClient, withResourceOwnerApi } from "./resourceOwner";
import { SurveyApiClient, withSurveyApi } from "./survey";
import { TeamApiClient, withTeamApi } from "./team";
import { TemplateApiClient, withTemplateApi } from "./template";
import { UserApiClient, withUserApi } from "./user";
import { WebhookApiClient, withWebhookApi } from "./webhook";
import { WorkerTokenApiClient, withWorkerTokenApi } from "./workerToken";
import { WorkspaceApiClient, withWorkspaceApi } from "./workspace";
import {
  WorkspaceSharePointSubscriptionApiClient,
  withWorkspaceSharePointSubscriptionApi,
} from "./workspaceSharePointSubscription";

export function applyMixins(baseClass: ApiClientConstructor<_BaseApiClient>) {
  let apiClient = withAuditLogApi(
    withConfigSnapshotApi(
      withCustomModelImageApi(
        withCustomModelApi(
          withReceiptGroupApi(
            withRequestLogApi(
              withTemplateApi(
                withAuthApi(
                  withUserApi(
                    withResourceOwnerApi(
                      withTeamApi(
                        withFormGroupApi(
                          withPlanApi(
                            withFormApi(
                              withWebhookApi(
                                withInvitationApi(
                                  withExtractorApi(
                                    withWorkerTokenApi(
                                      withWorkspaceApi(
                                        withWorkspaceSharePointSubscriptionApi(
                                          withSurveyApi(
                                            withExtractionApi(baseClass)
                                          )
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  );

  const chains = [withSurveyApi];
  chains.forEach(client => {
    apiClient = client(apiClient);
  });
  return apiClient;
}

export type ApiClientMixin = UserApiClient &
  ResourceOwnerApiClient &
  AuthApiClient &
  TemplateApiClient &
  RequestLogApiClient &
  CustomModelApiClient &
  CustomModelImageApiClient &
  PlanAPIClient &
  FormGroupApiClient &
  FormApiClient &
  ReceiptGroupApiClient &
  TeamApiClient &
  WebhookApiClient &
  InvitationApiClient &
  AuditLogApiClient &
  ExtractorApiClient &
  WorkerTokenApiClient &
  WorkspaceApiClient &
  ExtractionApiClient &
  WorkspaceSharePointSubscriptionApiClient &
  SurveyApiClient &
  ConfigSnapshotApiClient;
